import { useIonAlert } from "@ionic/react";
import { useContext, useState } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import authServices from "../../../services/apis/authServices";
import courierServices from "../../../services/apis/courierServices";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseImageInput from "../../@Base/BaseImageInput/BaseImageInput";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import SettingCard from "../SettingCard/SettingCard";
import SettingLabelAndInfo from "../SettingLabelAndInfo/SettingLabelAndInfo";
import "./CourierAccountEditor.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const CourierAccountEditor: React.FC<ContainerProps> = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const authCtx = useContext(AuthContext);
  const courierDataCtx = useContext(CourierDataContext);
  const [present] = useIonAlert();

  //Image Input Data
  const [imageToUpdate, setImageToUpdate] = useState(new File([], ""));

  //Base Infos Inputs Data
  const [courierFirstname, setCourierFirstname] = useState(
    courierDataCtx?.courierInfos?.firstname || ""
  );
  const [courierLastname, setCourierLastname] = useState(
    courierDataCtx?.courierInfos?.lastname || ""
  );
  const [courierPhone, setCourierPhone] = useState(
    courierDataCtx?.courierInfos?.phone || ""
  );

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  const updateCourierProfilePictureHandler = () => {
    if (imageToUpdate.size === 0) {
      return;
    }
    setIsBusy(true);
    courierServices
      .updateCourierProfilePicture(authCtx.userId, imageToUpdate)
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateRestaurantBaseInfosHandler = () => {
    if (!courierFirstname && !courierLastname && !courierPhone) {
      return;
    }
    setIsBusy(true);
    courierServices
      .updateCourierBaseInfos(
        authCtx.userId,
        courierFirstname,
        courierLastname,
        courierPhone
      )
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updatePasswordHandler = () => {
    if (!currentPassword || !newPassword || !confirmationPassword) {
      return;
    }
    if (newPassword !== confirmationPassword) {
      present({
        header: i18n.t("AddProductProductPage.Error"),
        message: i18n.t("AppSettingsAccount.WrongPassword"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => setIsBusy(false),
      });
    }
    setIsBusy(true);
    authServices
      .updatePassword(authCtx.userEmail, currentPassword, newPassword)
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  return (
    <div className="courier-account-editor">
      <SettingCard
        title={i18n.t("AppSettingsAccount.changePasswordHeader")}
        onSave={() => updatePasswordHandler()}
        gridId="password-update"
        isBusy={isBusy}
      >
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.currentPassword")}
          controller={{
            value: currentPassword,
            onChange: (event: any) => {
              setCurrentPassword(event.target.value);
            },
          }}
        />
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.newPassword")}
          controller={{
            value: newPassword,
            onChange: (event: any) => {
              setNewPassword(event.target.value);
            },
          }}
        />
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.confirmation")}
          controller={{
            value: confirmationPassword,
            onChange: (event: any) => {
              setConfirmationPassword(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsAccount.ProfilePicture")}
        onSave={() => updateCourierProfilePictureHandler()}
        gridId="profile-picture"
        isBusy={isBusy}
      >
        <div className="courier-account-editor__logo-input">
          <div
            className="courier-account-editor__logo-input__logo-display"
            style={{
              backgroundImage: imageToUpdate.size
                ? `url('${URL.createObjectURL(imageToUpdate)}')`
                : courierDataCtx?.courierInfos?.profileThumbnailUrl
                ? `url('${courierDataCtx?.courierInfos?.profileThumbnailUrl}')`
                : undefined,
            }}
          ></div>
          <BaseImageInput
            onImageSelected={(file: File) => {
              setImageToUpdate(file);
            }}
            value={imageToUpdate}
            presetValue=""
          />
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsAccount.MyAccount")}
        gridId="user-email"
      >
        <SettingLabelAndInfo
          label={i18n.t("AppSettingsAccount.emailAddress")}
          info={courierDataCtx?.courierInfos?.email || "-"}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.basicInformation")}
        onSave={() => updateRestaurantBaseInfosHandler()}
        gridId="courier-base-infos"
        isBusy={isBusy}
      >
        <BaseTextInput
          label={i18n.t("NewDeliveryClient.firstName")}
          controller={{
            value: courierFirstname,
            onChange: (event: any) => {
              setCourierFirstname(event.target.value);
            },
          }}
        />
        <BaseTextInput
          label={i18n.t("NewDeliveryClient.lastName")}
          controller={{
            value: courierLastname,
            onChange: (event: any) => {
              setCourierLastname(event.target.value);
            },
          }}
        />
        <BaseTextInput
          label={i18n.t("NewDeliveryClient.phoneNumber")}
          controller={{
            value: courierPhone,
            onChange: (event: any) => {
              setCourierPhone(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard gridId="logout-btn">
        <BaseButton expand tight color="danger" onClick={authCtx.signOut}>
          {i18n.t("AppSettingsAccount.logoutButton")}
        </BaseButton>
      </SettingCard>
    </div>
  );
};

export default CourierAccountEditor;
