import config from "../../configs/google-cloud-service.config";
import { Loader } from "@googlemaps/js-api-loader";
import Order from "../../models/Order";

const gcServiceConfig = config();
const GOOGLE_MAPS_API_KEY = gcServiceConfig.googleMapsApiKey;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

export default class GoogleCloudService {
  async getPlacesSuggestions(
    input: string,
    deviceLatLng: { lat: number; lng: number }
  ) {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const autocomplete = new google.maps.places.AutocompleteService();
          var location = new google.maps.LatLng(deviceLatLng);
          autocomplete.getPlacePredictions(
            {
              input,
              location,
              radius: 25000,
            },
            (predictions, status) => {
              const predictionsList: google.maps.places.AutocompletePrediction[] =
                [];
              predictions?.forEach((prediction) => {
                predictionsList.push(prediction);
              });
              res(predictions);
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async getPlaceGeocoding(
    placeId: string
  ): Promise<google.maps.GeocoderResponse> {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const geocoder = new google.maps.Geocoder();
          geocoder
            .geocode({ placeId: placeId })
            .then((results) => {
              res(results);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async getOptimizedDirection(
    currentLatLng: { lat: number; lng: number },
    orders: Order[]
  ): Promise<Order[]> {
    return new Promise((res, rej) => {
      const waypoints: {
        location: { placeId: string | undefined };
        stopover: boolean;
      }[] = [];

      orders.map((order) => {
        waypoints.push({
          location: { placeId: order.customer?.addressPlaceId },
          stopover: true,
        });
      });

      loader
        .load()
        .then((google) => {
          const directionsService = new google.maps.DirectionsService();
          directionsService
            .route({
              origin: {
                lat: currentLatLng.lat,
                lng: currentLatLng.lng,
              },
              destination: {
                lat: currentLatLng.lat,
                lng: currentLatLng.lng,
              },
              optimizeWaypoints: true,
              waypoints: waypoints,
              travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((result: google.maps.DirectionsResult) => {
              const optimizedOrders: Order[] = [];
              [...orders].map((order, index) => {
                order.optimizedPosition =
                  result.routes[0].waypoint_order[index];
                optimizedOrders.push(order);
              });
              res(optimizedOrders);
            })
            .catch((err) => {
              console.log(err);
              rej(err);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  async calculateRealDistance(
    placeIdOrigin: string,
    placeIdDestination: string
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      loader
        .load()
        .then((google) => {
          const directionsService = new google.maps.DirectionsService();
          directionsService.route(
            {
              origin: { placeId: placeIdOrigin },
              destination: { placeId: placeIdDestination },
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (
                status === "OK" &&
                response &&
                response.routes &&
                response.routes.length > 0
              ) {
                const route = response.routes[0];
                resolve(
                  route.legs && route.legs.length > 0 && route.legs[0].distance
                    ? route.legs[0].distance.value / 1000
                    : 0
                ); // Return distance in kilometers
              } else {
                reject(`Error fetching directions: ${status}`);
              }
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
}
