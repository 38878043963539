import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import AuthContext from "../../../contexts/auth.ctx";
import orderServices from "../../../services/apis/orderServices";
import SettingCard from "../SettingCard/SettingCard";
import "./CourierEarnings.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import { CourierEarnings as CourierEarningsType } from "../../../types";
import { IonImg } from "@ionic/react";
import ProfilePicturePlaceholder from "../../../assets/images/profile-picture-placeholder.png";
import { customToFixed } from "../../../utils/customTofixed";
interface ContainerProps {}

const CourierEarnings: React.FC<ContainerProps> = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [courierEarnings, setcourierEarnings] = useState<CourierEarningsType[]>(
    []
  );
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (startDate) {
      orderServices
        .getCourierEarnings(authCtx.restaurantId, startDate, endDate)
        .then((courierEarningsData) => {
          setcourierEarnings(courierEarningsData);
        });
    }
  }, [startDate, endDate]);

  return (
    <div className="courier-earnings">
      <SettingCard gridId="date-input">
        <span style={{ marginRight: "8px", fontWeight: "bold" }}>
          {i18n.t("AppSettingsOrderHistory.revenueFromDate")}
        </span>

        <DatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          isClearable={true}
        />
      </SettingCard>
      <div style={{ marginTop: "0.75rem" }}>
        {courierEarnings?.map((courierEarning) => {
          return (
            <div
              style={{
                gridArea: "courier",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 115,
                width: "100%",
                backgroundColor: "var(--ion-color-secondary)",
                boxShadow: "var(--base-card-shadow)",
                borderTopLeftRadius: 16,
                borderBottomRightRadius: 16,
                justifyContent: "space-between",
                padding: "8px 16px",
                color: "var(--ion-color-primary)",
                margin: "0.5rem 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: 84,
                  width: 84,
                  minHeight: 84,
                  minWidth: 84,
                  backgroundColor: "var(--ion-color-light-tint)",
                  borderRadius: "100%",
                  border: "1px solid var(--ion-color-secondary)",
                  overflow: "hidden",
                }}
              >
                <IonImg
                  src={
                    courierEarning.courier.profileThumbnailUrl
                      ? courierEarning.courier.profileThumbnailUrl
                      : ProfilePicturePlaceholder
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  flexGrow: 1,
                  marginLeft: "0.75rem",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                  }}
                >
                  <span>{courierEarning.courier.firstname || "-"}</span>{" "}
                  <span>{courierEarning.courier.lastname || "-"}</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    color: "#fff",
                    fontStyle: "italic",
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0.25rem 0",
                    }}
                  >
                    <div>
                      {i18n.t("AppSettingsRestaurantInformation.Delivery")}
                    </div>
                    <div>
                      {customToFixed(courierEarning.totalDelivery / 100) || "-"}{" "}
                      {constants.CURRENCY}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0.25rem 0",
                    }}
                  >
                    <div>{i18n.t("AppSettingsRestaurantInformation.Tips")}</div>
                    <div>
                      {customToFixed(courierEarning.totalTip / 100) || "-"}{" "}
                      {constants.CURRENCY}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: "bold",
                      margin: "0.25rem 0",
                    }}
                  >
                    <div>{i18n.t("AppSettingsSalesSummary.total")}</div>
                    <div>
                      {customToFixed(courierEarning.totalOfDay / 100) || "-"}{" "}
                      {constants.CURRENCY}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourierEarnings;
