export type OptionType = "ONE" | "MULTI";
export type OptionItem = {
  id: string;
  name: string;
  price: number;
  color: string;
  unavailable?: boolean;
};

export default class {
  public id;
  public name;
  public type;
  public optionItems;
  public isMandatory;
  public color;

  constructor(
    id: string,
    name: string,
    type: OptionType,
    optionItems: OptionItem[],
    isMandatory?: boolean,
    color?: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.color = color || "dark";
    this.isMandatory = isMandatory;
    this.optionItems = optionItems;
  }
}
