import { useIonAlert } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { GCS } from "../../../services";
import restaurantServices from "../../../services/apis/restaurantServices";
import BaseImageInput from "../../@Base/BaseImageInput/BaseImageInput";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import AddressFetcher from "../../@Shared/AddressFetcher/AddressFetcher";
import SettingCard from "../SettingCard/SettingCard";
import SettingLabelAndInfo from "../SettingLabelAndInfo/SettingLabelAndInfo";
import "./RestaurantInfosEditor.css";
import useWindowDimensions from "../../WindowSize/useWindowDimensions";
import WorkingDaysModel from "../../../models/WorkingDays";
import buildEnvConfig from "../../../build.env.json";
//Working hour component
import WorkingHours from "../WorkingHoursCalendar";
import "./working_hours.scss";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const RestaurantInfosEditor: React.FC<ContainerProps> = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [present] = useIonAlert();

  //Window dimensions to display working hour calendar or not
  const { height, width } = useWindowDimensions();

  // Address Input Data
  const [selectedAddress, setSelectedSelection] = useState(
    {} as google.maps.places.AutocompletePrediction
  );

  //WorkingDays Input Data
  const [workingDays, setWorkingDays] = useState({} as WorkingDaysModel);

  //Image Input Data
  const [imageToUpdate, setImageToUpdate] = useState(new File([], ""));

  //Base Infos Inputs Data
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantPhone, setRestaurantPhone] = useState("");

  //Pixel ID
  const [pixelId, setPixelId] = useState(
    restaurantDataCtx.restaurantInfos.pixelId || ""
  );

  const [reviewLink, setReviewLink] = useState(
    restaurantDataCtx.restaurantInfos.reviewLink || ""
  );
  const updateRestaurantAddressHandler = () => {
    setIsBusy(true);
    if (selectedAddress.place_id) {
      setIsBusy(false);
      GCS.getPlaceGeocoding(selectedAddress.place_id).then((data) => {
        let postalCode = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "postal_code";
        });
        let city = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "locality";
        });
        const restaurantAddressPayload = {
          address_place_id: selectedAddress.place_id,
          address_first_line: selectedAddress.structured_formatting.main_text,
          address_zipcode: postalCode?.short_name,
          address_city: city?.short_name,
          address_latitude: data.results[0].geometry.location.lat(),
          address_longitude: data.results[0].geometry.location.lng(),
        };
        restaurantServices
          .updateRestaurantAddress(
            authCtx.restaurantId,
            restaurantAddressPayload
          )
          .then((success) => {
            present({
              header: success.header,
              message: success.detail,
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => setIsBusy(false),
            });
          })
          .catch((error) => {
            present({
              header: error.header,
              message: error.detail,
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => setIsBusy(false),
            });
          });
      });
    }
    return;
  };

  const updateRestaurantLogoHandler = () => {
    if (imageToUpdate.size === 0) {
      return;
    }

    setIsBusy(true);
    restaurantServices
      .updateRestaurantLogo(authCtx.restaurantId, imageToUpdate)
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateRestaurantWorkingDaysHandler = () => {
    if (Object.keys(workingDays).length !== 7) {
      present({
        header: i18n.t("AddProductProductPage.Error"),
        message: i18n.t("AppSettingsRestaurantInformation.ValidHours"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => setIsBusy(false),
      });
      return;
    }

    let isWorkingDaysEmpty = true;
    let workingDaysValues = Object.values(workingDays);

    workingDaysValues.forEach((workingDayValue, index) => {
      if (
        workingDayValue.length > 0 &&
        workingDayValue[0].start !== workingDayValue[0].end
      ) {
        isWorkingDaysEmpty = false;
      }
    });

    if (isWorkingDaysEmpty) {
      present({
        header: i18n.t("AddProductProductPage.Error"),
        message: i18n.t("AppSettingsRestaurantInformation.SelectHours"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => setIsBusy(false),
      });
      return;
    }

    setIsBusy(true);
    restaurantServices
      .updateRestaurantWorkingDays(authCtx.restaurantId, workingDays)
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateRestaurantBaseInfosHandler = () => {
    if (!restaurantName && !restaurantPhone) {
      return;
    }
    setIsBusy(true);
    restaurantServices
      .updateRestaurantBaseInfos(
        authCtx.restaurantId,
        restaurantName,
        restaurantPhone
      )
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateRestaurantReviewLink = () => {
    if (!reviewLink) {
      return;
    }
    setIsBusy(true);
    restaurantServices
      .updateRestaurantReviewLink(authCtx.restaurantId, reviewLink)
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };
  const updateRestaurantPixelId = () => {
    if (!pixelId) {
      return;
    }
    setIsBusy(true);
    restaurantServices
      .updateRestaurantPixelId(authCtx.restaurantId, pixelId)
      .then((success) =>
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        })
      )
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };
  const getCurrentAddress = () => {
    if (
      restaurantDataCtx.restaurantInfos.addressFirstLine &&
      restaurantDataCtx.restaurantInfos.addressZipcode &&
      restaurantDataCtx.restaurantInfos.addressCity
    ) {
      return `${restaurantDataCtx.restaurantInfos.addressFirstLine} ${restaurantDataCtx.restaurantInfos.addressZipcode} ${restaurantDataCtx.restaurantInfos.addressCity}`;
    }
    return "-";
  };

  useEffect(() => {
    if (restaurantDataCtx.restaurantInfos.name) {
      setRestaurantName(restaurantDataCtx.restaurantInfos.name);
    }
    if (restaurantDataCtx.restaurantInfos.phone) {
      setRestaurantPhone(restaurantDataCtx.restaurantInfos.phone);
    }
  }, []);

  //Working hours variable
  const setSelectedWorkinghours = (workingDays: WorkingDaysModel) => {
    setWorkingDays(workingDays);
  };

  let days = [
    {
      key: "mon",
      name: i18n.t("AppSettingsRestaurantInformation.days.monday"),
    },
    {
      key: "tue",
      name: i18n.t("AppSettingsRestaurantInformation.days.tuesday"),
    },
    {
      key: "wed",
      name: i18n.t("AppSettingsRestaurantInformation.days.wednesday"),
    },
    {
      key: "thu",
      name: i18n.t("AppSettingsRestaurantInformation.days.thursday"),
    },
    {
      key: "fri",
      name: i18n.t("AppSettingsRestaurantInformation.days.friday"),
    },
    {
      key: "sat",
      name: i18n.t("AppSettingsRestaurantInformation.days.saturday"),
    },
    {
      key: "sun",
      name: i18n.t("AppSettingsRestaurantInformation.days.sunday"),
    },
  ];

  let workingDaysData = restaurantDataCtx.workingDays;
  let fieldName = "location[working_hours]";

  return (
    <div className="restaurant-infos-editor">
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.restaurantAddress")}
        onSave={() => updateRestaurantAddressHandler()}
        gridId="restaurant-address"
        isBusy={isBusy}
      >
        <SettingLabelAndInfo
          label={i18n.t("AppSettingsRestaurantInformation.currentAddress")}
          info={getCurrentAddress()}
        />
        <AddressFetcher
          onSelect={(suggestion: any) => setSelectedSelection(suggestion)}
          value={selectedAddress}
          extended
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.facebookPixelHeader")}
        onSave={() => updateRestaurantPixelId()}
        gridId="restaurant-pixel-id"
        isBusy={isBusy}
      >
        <BaseTextInput
          label={i18n.t(
            "AppSettingsRestaurantInformation.facebookPixelDescription"
          )}
          controller={{
            value: pixelId,
            onChange: (event: any) => {
              setPixelId(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.reviewLink")}
        onSave={() => updateRestaurantReviewLink()}
        gridId="restaurant-review-link"
        isBusy={isBusy}
      >
        <BaseTextInput
          label={i18n.t("AppSettingsRestaurantInformation.link")}
          controller={{
            value: reviewLink,
            onChange: (event: any) => {
              setReviewLink(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.restaurantLogo")}
        onSave={() => updateRestaurantLogoHandler()}
        gridId="restaurant-logo"
        isBusy={isBusy}
      >
        <div className="restaurant-infos-editor__logo-input">
          <div
            className="restaurant-infos-editor__logo-input__logo-display"
            style={{
              backgroundImage: imageToUpdate.size
                ? `url('${URL.createObjectURL(imageToUpdate)}')`
                : restaurantDataCtx?.restaurantInfos?.logoThumbnailUrl
                ? `url('${restaurantDataCtx?.restaurantInfos?.logoThumbnailUrl}')`
                : undefined,
            }}
          ></div>
          <BaseImageInput
            onImageSelected={(file: File) => {
              setImageToUpdate(file);
            }}
            value={imageToUpdate}
            presetValue=""
          />
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.basicInformation")}
        onSave={() => updateRestaurantBaseInfosHandler()}
        gridId="restaurant-base-infos"
        isBusy={isBusy}
      >
        <BaseTextInput
          label={i18n.t("AppSettingsRestaurantInformation.restaurantName")}
          controller={{
            value: restaurantName,
            onChange: (event: any) => {
              setRestaurantName(event.target.value);
            },
          }}
        />
        <BaseTextInput
          label={i18n.t("AppSettingsRestaurantInformation.phoneNumber")}
          controller={{
            value: restaurantPhone,
            onChange: (event: any) => {
              setRestaurantPhone(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsRestaurantInformation.openingHours")}
        onSave={() => updateRestaurantWorkingDaysHandler()}
        gridId="restaurant-business-hours"
        isBusy={isBusy}
      >
        {width >= 700 ? (
          <WorkingHours
            days={days}
            fieldName={fieldName}
            data={workingDaysData}
            setSelectedWorkinghours={setSelectedWorkinghours}
          />
        ) : (
          <p>
            {i18n.t(
              "AppSettingsRestaurantInformation.ComputerAccessOpeningHours"
            )}
          </p>
        )}
      </SettingCard>
    </div>
  );
};

export default RestaurantInfosEditor;
