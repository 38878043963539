import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import RestaurantInfos from "../../models/RestaurantInfos";
import WorkingDays from "../../models/WorkingDays";
import { PromoCode, SuccessAlert } from "../../types";
import {
  $firebaseAuth,
  $firebaseFunctions,
  $firestore,
} from "../app/firebase-service";
import storageServices from "./storageServices";
import success from "../../messages/success";
import errors from "../../messages/error";
import { DeliveryFeesByCity } from "../../models/RestaurantInfos";
import { min } from "date-fns";
const restaurantServices = {
  listenToRestaurantInfos(restaurantId: string, listenerCallback: Function) {
    const restaurantInfosListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .onSnapshot((doc) => {
        listenerCallback(
          new RestaurantInfos(
            doc.id,
            doc.data()?.name,
            doc.data()?.phone,
            doc.data()?.email,
            doc.data()?.logo_url,
            doc.data()?.logo_thumbnail_url,
            doc.data()?.address_first_line,
            doc.data()?.address_second_line,
            doc.data()?.address_zipcode,
            doc.data()?.address_city,
            doc.data()?.address_latitude,
            doc.data()?.address_longitude,
            doc.data()?.address_place_id,
            doc.data()?.current_delivery_time || 30,
            doc.data()?.available_cities_on_delivery,
            doc.data()?.delivery_fees,
            doc.data()?.free_delivery_fees_trigger,
            doc.data()?.url_slug,
            doc.data()?.subscription_plan,
            doc.data()?.current_click_and_collect_time || 20,
            doc.data()?.production_mode,
            doc.data()?.sms_sold,
            doc.data()?.pixel_id,
            doc.data()?.delivery_closed,
            doc.data()?.click_and_collect_closed,
            doc.data()?.door_payment_enabled,
            doc.data()?.current_delivery_time_by_km || 10,
            doc.data()?.delivery_fees_by_km,
            doc.data()?.max_delivery_range,
            doc.data()?.minimum_order_amount,
            doc.data()?.stripe_account_active,
            doc.data()?.stripe_account_id,
            doc.data()?.stripe_infos_required,
            doc.data()?.review_link
          )
        );
      });
    return restaurantInfosListener;
  },
  listenToRestaurantWorkingDays(
    restaurantId: string,
    listenerCallback: Function
  ) {
    const restaurantWorkingDaysListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .onSnapshot((doc) => {
        listenerCallback(
          new WorkingDays(
            doc.data()?.workingDays?.mon,
            doc.data()?.workingDays?.tue,
            doc.data()?.workingDays?.wed,
            doc.data()?.workingDays?.thu,
            doc.data()?.workingDays?.fri,
            doc.data()?.workingDays?.sat,
            doc.data()?.workingDays?.sun
          )
        );
      });
    return restaurantWorkingDaysListener;
  },
  updateRestaurantAddress(
    restaurantId: string,
    restaurantAddressPayload: any
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set(restaurantAddressPayload, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateRestaurantLogo(
    restaurantId: string,
    logoFile: File
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      if (logoFile?.size != 0) {
        const restaurantLogoImageDirPath = `restaurants/${restaurantId}/logos`;
        storageServices
          .uploadImageAndThumbnail(restaurantLogoImageDirPath, logoFile)
          .then((imagePaths) => {
            let updatePayload = {
              logo_url: imagePaths.imageDownloadUrl,
              logo_path: imagePaths.imagePath,
              logo_thumbnail_url: imagePaths.thumbnailDownloadUrl,
              logo_thumbnail_path: imagePaths.thumbnailPath,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            };
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .set(updatePayload, { merge: true })
              .then(() => {
                res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
              });
          })
          .catch((e) => {
            console.log(e);
            rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
          });
      }
    });
  },
  updateRestaurantBaseInfos(
    restaurantId: string,
    restaurantName: string,
    restaurantPhone: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ name: restaurantName, phone: restaurantPhone }, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },

  updateRestaurantPixelId(
    restaurantId: string,
    pixelId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ pixel_id: pixelId }, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateRestaurantReviewLink(
    restaurantId: string,
    reviewLink: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ review_link: reviewLink }, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateRestaurantWorkingDays(
    restaurantId: string,
    workingDays: Object
    // workingDaysFormatted: Array<any>,
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set(
          {
            workingDays: workingDays,
            //  workingDaysFormatted: workingDaysFormatted,
          },
          { merge: true }
        )
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateRestaurantAvailableCitiesOnDelivery(
    restaurantId: string,
    availableCities: DeliveryFeesByCity[]
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ available_cities_on_delivery: availableCities }, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateDeliveryFees(
    restaurantId: string,
    deliveryFees: number,
    freeDeliveryFeesTrigger: number,
    deliveryFeesByKm: number,
    minimumOrderAmount: number
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set(
          {
            delivery_fees: deliveryFees * 100,
            free_delivery_fees_trigger: freeDeliveryFeesTrigger * 100,
            delivery_fees_by_km: deliveryFeesByKm * 100,
            minimum_order_amount: minimumOrderAmount * 100,
          },
          { merge: true }
        )
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateUrlSlug(
    restaurantId: string,
    currentUrlSlug: string,
    urlSlug: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const registerUrlSlug =
        $firebaseFunctions.httpsCallable("registerUrlSlug");
      registerUrlSlug({ restaurantId, currentUrlSlug, urlSlug })
        .then((result) => {
          return res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          return rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  //NOTE DEPRECATED METHOD
  updateRestaurantInfos(
    restaurantId: string,
    userEmail: string,
    authPassword: string,
    restaurantName: string,
    restaurantPhone: string,
    restaurantLogoImageFile: File,
    restaurantAddressPayload: any
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const credential = firebase.auth.EmailAuthProvider.credential(
        userEmail,
        authPassword
      );
      $firebaseAuth.currentUser
        ?.reauthenticateWithCredential(credential)
        .then(() => {
          if (restaurantLogoImageFile?.size != 0) {
            const restaurantLogoImageDirPath = `restaurants/${restaurantId}/logos`;
            storageServices
              .uploadImageAndThumbnail(
                restaurantLogoImageDirPath,
                restaurantLogoImageFile
              )
              .then((imagePaths) => {
                let updatePayload = {
                  name: restaurantName,
                  phone: restaurantPhone,
                  logo_url: imagePaths.imageDownloadUrl,
                  logo_path: imagePaths.imagePath,
                  logo_thumbnail_url: imagePaths.thumbnailDownloadUrl,
                  logo_thumbnail_path: imagePaths.thumbnailPath,
                  updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                };
                if (restaurantAddressPayload.address_place_id) {
                  updatePayload = {
                    ...updatePayload,
                    ...restaurantAddressPayload,
                  };
                }
                $firestore
                  .collection("restaurants")
                  .doc(restaurantId)
                  .set(updatePayload, { merge: true })
                  .then(() => {
                    res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
                  })
                  .catch((e) => {
                    console.log(e);
                    rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
                  });
              })
              .catch((e) => {
                console.log(e);
                rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
              });
          } else {
            let updatePayload = {
              name: restaurantName,
              phone: restaurantPhone,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            };
            if (restaurantAddressPayload.address_place_id) {
              updatePayload = {
                ...updatePayload,
                ...restaurantAddressPayload,
              };
            }
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .set(updatePayload, { merge: true })
              .then(() => {
                res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          rej(errors.WRONG_USER_PASSWORD_ERROR_ALERT);
        });
    });
  },
  //NOTE END OF DEPRECATED METHOD
  setRestaurantDeliveryClosed(
    restaurantId: string,
    deliveryClosed: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ delivery_closed: deliveryClosed }, { merge: true })
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  setEnableDoorPayment(
    restaurantId: string,
    doorPaymentEnabled: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ door_payment_enabled: doorPaymentEnabled }, { merge: true })
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  //NOTE END OF DEPRECATED METHOD
  setRestaurantClickAndCollectClosed(
    restaurantId: string,
    clickAndCollectClosed: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set(
          { click_and_collect_closed: clickAndCollectClosed },
          { merge: true }
        )
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  //NOTE END OF DEPRECATED METHOD
  setRestaurantProductionMode(
    restaurantId: string,
    productionMode: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ production_mode: productionMode }, { merge: true })
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  updateDeliveryTime(
    restaurantId: string,
    deliveryTime: number
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ current_delivery_time: deliveryTime }, { merge: true })
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  updateDeliveryTimeByKm(
    restaurantId: string,
    deliveryTimeByKm: number
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ current_delivery_time_by_km: deliveryTimeByKm }, { merge: true })
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  updateMaxDeliveryRange(
    restaurantId: string,
    maxDeliveryRange: number
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set({ max_delivery_range: maxDeliveryRange }, { merge: true })
        .then(() => {
          res(success.RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.RESTAURANT_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  updateClickAndCollectTime(
    restaurantId: string,
    clickAndCollectTime: number
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .set(
          { current_click_and_collect_time: clickAndCollectTime },
          { merge: true }
        )
        .then(() => res(true))
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  addCoupon(restaurantId: string, promoCode: PromoCode): Promise<SuccessAlert> {
    // Sequentially resolve all of the updates
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("promo_codes")
        .where("code", "==", promoCode.code)
        .get()
        .then((data) => {
          if (data.empty) {
            return $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("promo_codes")
              .add({
                ...promoCode,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
              });
          } else {
            rej(errors.COUPON_CREATE_ALREADY_EXISTS_ERROR_ALERT);
          }
        })
        .then(() => {
          res(success.COUPON_ADDED_SUCCESS_ALERT);
        })
        .catch((err) => {
          console.log(err);
          rej(errors.COUPON_CREATE_ERROR_ALERT);
        });
    });
  },
  deleteCoupon(
    restaurantId: string,
    promoCodeId: string
  ): Promise<SuccessAlert> {
    // Sequentially resolve all of the updates
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("promo_codes")
        .doc(promoCodeId)
        .delete()
        .then(() => {
          res(success.COUPON_ADDED_SUCCESS_ALERT);
        })
        .catch(() => {
          res(errors.COUPON_CREATE_ERROR_ALERT);
        });
    });
  },
  listenToCoupon(restaurantId: string, listenerCallback: Function) {
    const couponsListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("promo_codes")
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let promoCodes: (PromoCode & { id: string })[] = [];
        querySnasphot.forEach((doc) => {
          promoCodes.push({ ...doc.data(), id: doc.id } as PromoCode & {
            id: string;
          });
        });
        listenerCallback(promoCodes);
      });
    return couponsListener;
  },
};

export default restaurantServices;
