import { IonLabel, IonToggle } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import EditProductContext from "../../../contexts/edit-product.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import ProductsGridItem from "../ProductsGridItem/ProductsGridItem";
import "./ProductsInfosEditor.css";
import ReactCrop, {
  type Crop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useIonModal } from "@ionic/react";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const nameMaxLength = "25";
const descriptionMaxLength = "40";
const longDescMaxLength = "120";
const imgAspectRatio = 2;
const ImageCroper = ({
  onCropConfirmed,
  src,
  onDismiss,
  mimeType,
}: {
  onCropConfirmed: Function;
  src: string;
  onDismiss: Function;
  mimeType: string;
}) => {
  const [crop, setCrop] = useState<Crop>();
  const imageRef = useRef<HTMLImageElement>(null);
  const [imgWidthRatio, setImgWidthRatio] = useState(0);
  const [imgHeightRatio, setImgHeightRatio] = useState(0);
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setTimeout(() => {
      const { naturalWidth: imgWidth, naturalHeight: imgHeight } =
        e.target as any;
      const containerWidth =
        imageRef.current?.getBoundingClientRect().width || 350;
      const containerHeight =
        imageRef.current?.getBoundingClientRect().height || 350;

      setImgWidthRatio(imgWidth / containerWidth);
      setImgHeightRatio(imgHeight / containerHeight);
      const initalCrop = centerCrop(
        makeAspectCrop(
          {
            width: containerWidth,
            unit: "px",
          },
          imgAspectRatio,
          containerWidth,
          containerHeight
        ),
        containerWidth,
        containerHeight
      );
      setCrop(initalCrop);
    }, 100);
  };

  const getCroppedImg = async (
    image: any,
    pixelCrop: Crop
  ): Promise<Blob | undefined> => {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width * imgWidthRatio;
    canvas.height = pixelCrop.height * imgHeightRatio;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;
    ctx.drawImage(
      image,
      pixelCrop.x * imgWidthRatio,
      pixelCrop.y * imgHeightRatio,
      pixelCrop.width * imgWidthRatio,
      pixelCrop.height * imgHeightRatio,
      0,
      0,
      pixelCrop.width * imgWidthRatio,
      pixelCrop.height * imgHeightRatio
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        resolve(blob);
      }, mimeType);
    });
  };

  const onCropComplete = async () => {
    if (!imageRef.current || !crop) return;
    if (imageRef && crop.width && crop.height) {
      const croppedImageBlob = await getCroppedImg(imageRef.current, crop);
      if (!croppedImageBlob) return;
      const cropedImgFile = new File([croppedImageBlob], "filename.png", {
        type: mimeType,
        lastModified: Date.now(),
      });
      onCropConfirmed(cropedImgFile);
      resetImgCroper();
      onDismiss();
    }
  };

  const resetImgCroper = () => {
    setImgHeightRatio(0);
    setImgWidthRatio(0);
    setCrop(undefined);
    if (imageRef.current) {
      imageRef.current.src = "";
    }

    onDismiss();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        padding: "1rem",
        borderRadius: "1rem",
      }}
    >
      <BaseModalHeader
        onDismiss={() => {
          resetImgCroper();
          onDismiss();
        }}
      >
        {i18n.t("AppSettingsRestaurantInformation.EditImage")}
      </BaseModalHeader>
      <div
        style={{
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactCrop
          crop={crop}
          onChange={(c) => {
            setCrop(c);
          }}
          aspect={imgAspectRatio}
          ruleOfThirds
          style={{
            maxHeight: 350,
            maxWidth: 350,
            // height: 350,
            // width: 350,
          }}
        >
          <img
            ref={imageRef}
            onLoad={onImageLoad}
            src={src}
            // style={{ height: 350, width: 350 }}
          />
        </ReactCrop>
      </div>
      <div style={{ margin: "1rem" }}>
        <BaseButton expand tight onClick={onCropComplete}>
          {i18n.t("AppSettingsRestaurantInformation.Apply")}
        </BaseButton>
      </div>
    </div>
  );
};

const ProductsInfosEditor: React.FC<ContainerProps> = (props) => {
  const editProductCtx = useContext(EditProductContext);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [inputFileUrl, setInputFileUrl] = useState("");
  const [fileInputSrc, setfileInputSrc] = useState("");
  const [fileInputMimeType, setfileInputMimeType] = useState("");
  const startPictureSelection = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click();
    }
  };

  const pictureSelectionHandler = (files: FileList | null) => {
    if (files?.length) {
      const url = URL.createObjectURL(files[0]);
      setfileInputMimeType(files[0].type);

      setfileInputSrc(url);
      present();
      if (hiddenInputRef.current) {
        hiddenInputRef.current.value = "";
      }
    }
  };

  const confirmSelectionHandler = (file: File | null) => {
    if (file) {
      editProductCtx.productImageFileInputHandler(file);
    }
  };

  useEffect(() => {
    if (
      editProductCtx.currentToUpdatePictureUrl &&
      !editProductCtx.productImageFile.size
    ) {
      setInputFileUrl(editProductCtx.currentToUpdatePictureUrl);
    }
    if (editProductCtx.productImageFile.size) {
      setInputFileUrl(URL.createObjectURL(editProductCtx.productImageFile));
    }
  }, [editProductCtx.productImageFile]);

  const [present, dismiss] = useIonModal(ImageCroper, {
    onCropConfirmed: confirmSelectionHandler,
    src: fileInputSrc,
    mimetype: fileInputMimeType,
    onDismiss: () => {
      dismiss();
    },
  });

  return (
    <div className="products-infos-editor" data-testid="products-infos-editor">
      <div className="products-infos-editor__preview-and-picture">
        <ProductsGridItem
          pictureUrl={inputFileUrl}
          hasOptions={editProductCtx.productHasOptions}
          name={editProductCtx.productName || "-"}
          description={editProductCtx.productDescription || ""}
          price={+editProductCtx.productPrice * 100}
          basePrice={+editProductCtx.productPrice * 100}
          editMode
          onClick={startPictureSelection}
          longDesc={editProductCtx.productLongDesc || ""}
        />
        <input
          id="products-infos-editor__preview-and-picture__hidden-input"
          ref={hiddenInputRef}
          type="file"
          className="products-infos-editor__preview-and-picture__hidden-input"
          onChange={(event) => {
            pictureSelectionHandler(event.target.files);
          }}
        />
      </div>
      <div className="products-infos-editor__infos-editor">
        <BaseTextInput
          label={i18n.t("AddProductProductPage.productName")}
          type="text"
          controller={{
            value: editProductCtx.productName,
            maxLength: nameMaxLength,
            onChange: (event: any) => {
              editProductCtx.productNameInputHandler(event.target.value);
            },
          }}
        />
        <BaseTextInput
          label={i18n.t("AddProductProductPage.productDescription")}
          type="text"
          controller={{
            value: editProductCtx.productDescription,
            maxLength: descriptionMaxLength,
            onChange: (event: any) => {
              editProductCtx.productDescriptionInputHandler(event.target.value);
            },
          }}
        />
        <BaseTextInput
          label={i18n.t("AddProductProductPage.longDescription")}
          type="text"
          controller={{
            value: editProductCtx.productLongDesc,
            maxLength: longDescMaxLength,
            onChange: (event: any) => {
              editProductCtx.productLongDescInputHandler(event.target.value);
            },
          }}
        />

        <BaseTextInput
          label={i18n.t("AddProductProductPage.price")}
          type="number"
          controller={{
            value: editProductCtx.productPrice.toString(),

            onChange: (event: any) => {
              editProductCtx.productPriceInputHandler(event.target.value);
            },
          }}
        />
        <div className="products-infos-editor__infos-editor__has-options-container">
          <IonToggle
            checked={editProductCtx.productHasOptions}
            onIonChange={(e) =>
              editProductCtx.productHasOptionsInputHandler(
                !editProductCtx.productHasOptions
              )
            }
          />
          <span>{i18n.t("AddProductProductPage.productHasOptions")}</span>
        </div>
        <div className="products-infos-editor__infos-editor__has-options-container">
          <IonToggle
            checked={!editProductCtx.productIsUnavailable}
            onIonChange={(e) =>
              editProductCtx.productIsUnavailableHandler(
                !editProductCtx.productIsUnavailable
              )
            }
          />
          <span>{i18n.t("AddProductProductPage.productAvailable")}</span>
        </div>
        {editProductCtx.editMode == "UPDATE" && (
          <BaseButton
            expand
            outline
            tight
            onClick={editProductCtx.deleteProductHandler}
          >
            {i18n.t("AppOrder.DeleteProduct")}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default ProductsInfosEditor;
