import ProductsOptions, {
  OptionItem,
  OptionType,
} from "../../../models/ProductsOptions";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./ProductsOptionsItem.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
interface ContainerProps {
  productsOptions: ProductsOptions;
  onActionClick: Function;
  onSelect: Function;
  isSelected: boolean;
  orderIndex?: number;
}

const ProductsOptionsItem: React.FC<ContainerProps> = (props) => {
  return (
    <div
      onClick={() => props.onSelect()}
      className={
        props.isSelected
          ? "products-options-item products-options-item--selected"
          : "products-options-item"
      }
      data-testid="products-options-item"
    >
      {props.orderIndex != undefined &&
      props.orderIndex >= 0 &&
      props.isSelected ? (
        <div className="products-options-item__order-index">
          {props.orderIndex + 1}
        </div>
      ) : null}

      <div className="products-options-item__header">
        <span>{props.productsOptions.name}</span>
        <span>
          {props.productsOptions.type == "ONE"
            ? i18n.t("AddProductProductPage.Unique")
            : i18n.t("AddProductProductPage.Multiple")}{" "}
          {props.productsOptions.isMandatory
            ? i18n.t("AddProductProductPage.Mandatory")
            : ""}{" "}
        </span>
      </div>
      <div className="products-options-item__items-list">
        {props.productsOptions.optionItems.map((option, index) => {
          return (
            <div
              key={`${props.productsOptions.id}_${index}`}
              className="products-options-item__items-list_item"
              style={{
                color: option.unavailable
                  ? "#ababab"
                  : `var(--ion-color-${props.productsOptions.color}-shade)`,
                textDecoration: option.unavailable ? "line-through" : "none",
              }}
            >
              <span className="products-options-item__items-list_item__name">
                <div
                  className="products-options-item__items-list_item__name__dot"
                  style={{
                    backgroundColor: `var(--ion-color-${props.productsOptions.color}-shade)`,
                  }}
                />
                {option.name}
              </span>
              <span>{`${
                option.price
                  ? "+ " + (option.price / 100).toFixed(2).toLocaleString()
                  : "-"
              } ${constants.CURRENCY}`}</span>
            </div>
          );
        })}
      </div>
      <div className="products-options-item__action">
        <BaseButton
          expand
          tight
          onClick={(
            event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
          ) => {
            event.stopPropagation();
            props.onActionClick();
          }}
        >
          {i18n.t("AppMenu.Modify")}
        </BaseButton>
      </div>
    </div>
  );
};

export default ProductsOptionsItem;
