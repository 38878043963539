import { useContext } from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import CourierItem from "../CourierItem/CourierItem";
import CourierNoStateItem from "../CourierNoStateItem/CourierNoStateItem";
import "./CouriersGrid.css";

interface ContainerProps {}

const CouriersGrid: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);

  return (
    <div className="couriers-grid" data-testid="couriers-grid">
      <div className="couriers-grid__grid">
        {![
          ...restaurantDataCtx.pendingCouriersList,
          ...restaurantDataCtx.couriersList,
        ].length ? (
          <CourierNoStateItem />
        ) : (
          [
            ...restaurantDataCtx.pendingCouriersList,
            ...restaurantDataCtx.couriersList,
          ].map((courier) => {
            if (
              courier.isPending &&
              [...restaurantDataCtx.couriersList].find(
                (acceptedCourier) => acceptedCourier.id == courier.id
              )
            ) {
              return;
            }
            return (
              <CourierItem
                courier={courier}
                key={courier.isPending ? "pending-" + courier.id : courier.id}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default CouriersGrid;
