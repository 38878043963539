import { IonImg } from "@ionic/react";
import Product from "../../../models/Product";
import { OptionItem } from "../../../models/ProductsOptions";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrdersCartItem.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  product: Product;
  index: number;
  actionName?: string;
  action?: Function;
  onClick?: Function;
}

const OrdersCartItem: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="orders-cart-item"
      data-testid="orders-cart-item"
      style={{ cursor: props.product.hasOptions ? "pointer" : "default" }}
      onClick={() => {
        return props.onClick ? props.onClick() : () => {};
      }}
    >
      <div className="orders-cart-item__base-product-infos">
        <div className="orders-cart-item__base-product-infos__product-picture">
          <IonImg src={props.product.thumbnailUrl} />
        </div>
        <div className="orders-cart-item__base-product-infos__name">
          {props.product.name}
        </div>
        <div className="orders-cart-item__base-product-infos__price">
          {(props.product.price / 100).toFixed(2) + ` ${constants.CURRENCY}`}
        </div>
      </div>
      {props.product.optionsPreset &&
        props.product.optionsPreset?.length != 0 && (
          <div className="orders-cart-item__options">
            {props.product.optionsPreset?.map((option) => {
              return (
                <div
                  className="orders-cart-item__options__item"
                  key={option.id}
                  style={{
                    color: `var(--ion-color-${option.color}-shade)`,
                  }}
                >
                  <span className="orders-cart-item__options__item__name">
                    <div
                      className="orders-cart-item__options__item__dot"
                      style={{
                        backgroundColor: `var(--ion-color-${option.color}-shade)`,
                      }}
                    />{" "}
                    {option.name}
                  </span>
                  <span>
                    {"(+ " +
                      (option.price / 100).toFixed(2) +
                      ` ${constants.CURRENCY})`}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      <div className="orders-cart-item__base-product-quantity-container">
        <div className="orders-cart-item__base-product-quantity-text">
          {i18n.t("AddProductProductPage.Quantity")}
        </div>
        <div className="orders-cart-item__base-product-quantity-amount">
          x{props.product.quantity}
        </div>
      </div>
      {props.action && (
        <div className="orders-cart-item__action-container">
          <BaseButton
            tight
            expand
            onClick={(e: any) => {
              if (!e) {
                var event = window.event;
                e.cancelBubble = true;
                if (e.stopPropagation) e.stopPropagation();
                if (props.action) {
                  props.action();
                }
              } else {
                e.cancelBubble = true;
                if (e.stopPropagation) e.stopPropagation();
                if (props.action) {
                  props.action();
                }
              }
            }}
          >
            {props.actionName}
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default OrdersCartItem;
