import buildEnv from "../build.env.json";

export const constants = {
  REGION: buildEnv.REGION == "US" ? "us-central1" : "europe-west1",
  CURRENCY: buildEnv.REGION == "US" ? "$" : "€",
  API_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://us-central1-deliours-dev.cloudfunctions.net"
        : "https://europe-west1-delizi-dev.cloudfunctions.net"
      : buildEnv.REGION == "US"
      ? "https://us-central1-deliours-master.cloudfunctions.net"
      : "https://europe-west1-delizi-master.cloudfunctions.net",
  WEBSITE_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://deliours-us-site-dev.web.app"
        : "https://delizi-site-dev.web.app"
      : buildEnv.REGION == "US"
      ? "https://deliours.com"
      : "https://iziup.fr",
  SHOP_URL:
    buildEnv.BUILD_ENV == "DEV"
      ? buildEnv.REGION == "US"
        ? "https://deliours-us-shop-dev.web.app"
        : "https://delizi-shop-dev.web.app"
      : buildEnv.REGION == "US"
      ? "https://shop.deliours.com"
      : "https://shop.iziup.fr",
};
