import { useIonModal } from "@ionic/react";
import { add } from "ionicons/icons";
import { useContext } from "react";
import CouriersGrid from "../../../components/Couriers/CouriersGrid/CouriersGrid";
import HomeLayout from "../../../components/Layouts/HomeLayout/HomeLayout";
import RestaurantQRCodeModal from "../../../components/Modals/RestaurantQRCodeModal/RestaurantQRCodeModal";
import AuthContext from "../../../contexts/auth.ctx";
import "./CouriersPage.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const CouriersPage: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);

  const [present, dismiss] = useIonModal(RestaurantQRCodeModal, {
    qrString: authCtx.restaurantId,
    onDismiss: () => dismiss(),
  });

  const openRestaurantQRCodeModal = () => {
    present();
  };

  return (
    <HomeLayout
      title={i18n.t("AppSettingsRestaurantInformation.DeliveryDriver")}
      button={{ icon: add, action: () => openRestaurantQRCodeModal() }}
    >
      <CouriersGrid />
    </HomeLayout>
  );
};

export default CouriersPage;
