import { IonImg, IonTitle, IonIcon } from "@ionic/react";
import { star } from "ionicons/icons";
import { useHistory } from "react-router";
import "./RoleButton.css";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import courierImage from "../../../assets/images/courier.svg";
import restaurantImage from "../../../assets/images/restaurant.svg";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

type ButtonRoles = "COURIER" | "RESTAURANT" | "DELIVERY" | "ON_PLACE";

interface ContainerProps {
  role: "COURIER" | "RESTAURANT" | "DELIVERY" | "ON_PLACE";
  selected: boolean;
  onClick: Function;
  disabled?: boolean;
}

export const getCorrespondingRoleImage = (role: ButtonRoles) => {
  return role == "COURIER" || role == "DELIVERY"
    ? courierImage
    : restaurantImage;
};

export const getCorrespondingRoleName = (role: ButtonRoles) => {
  switch (role) {
    case "COURIER":
      return i18n.t("AppSettingsRestaurantInformation.DeliveryDriver");
    case "RESTAURANT":
      return i18n.t("AppSettingsRestaurantInformation.Restaurant");
    case "DELIVERY":
      return i18n.t("AppSettingsRestaurantInformation.Delivery");
    case "ON_PLACE":
      return i18n.t("AppSettingsRestaurantInformation.Collect");
  }
};

export const getClassNames = (selected: boolean, role: ButtonRoles) => {
  const classes = ["role-button"];
  if (!selected) {
    classes.push("role-button--not-selected");
  }
  if (role == "DELIVERY" || role == "ON_PLACE") {
    classes.push("role-button--as-new-order-choice");
  }
  return classes.join(" ");
};

const RoleButton: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  return (
    <div className={"role-button__main-container"}>
      <div
        className={getClassNames(props.selected, props.role)}
        onClick={props.disabled ? () => null : () => props.onClick(props.role)}
        data-testid="role-button"
      >
        <div className="role-button__ion-img">
          <IonImg
            data-testid="role-button__ion-img"
            src={getCorrespondingRoleImage(props.role)}
          />
        </div>
        <span className="role-button__label">
          {getCorrespondingRoleName(props.role)}
        </span>
      </div>
      {props.disabled ? (
        <div className="role-button__disabled">
          <IonIcon icon={star} />
          <BaseButton
            expand
            type="button"
            onClick={() => {
              window.open(
                `${constants.WEBSITE_URL}/home/choose_subscription_plan`,
                "_blank"
              );
            }}
          >
            {i18n.t("AppSettingsRestaurantInformation.Premium")}
          </BaseButton>
        </div>
      ) : null}
    </div>
  );
};

export default RoleButton;
// NOTE : might not be used in signup process anymore
