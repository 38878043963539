import { useIonAlert } from "@ionic/react";
import { add, checkmark, checkmarkCircle } from "ionicons/icons";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import DeliveriesList from "../../../components/Deliveries/DeliveriesList/DeliveriesList";
import NoRestaurantPrompt from "../../../components/Deliveries/NoRestaurantPrompt/NoRestaurantPrompt";
import HomeLayout from "../../../components/Layouts/HomeLayout/HomeLayout";
import AuthContext from "../../../contexts/auth.ctx";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import infos from "../../../messages/infos";
import Order from "../../../models/Order";
import routes from "../../../routes";
import "./DeliveriesPage.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const DeliveriesPage: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const courierDataCtx = useContext(CourierDataContext);
  const history = useHistory();
  const [present] = useIonAlert();

  const actionHandler = () => {
    !courierDataCtx.inDeliveryOrdersList.length
      ? history.push(routes.NEW_DELIVERY_PAGE)
      : present({
          header: infos.DELIVERY_ENDING_INFOS_ALERT.header,
          message: infos.DELIVERY_ENDING_INFOS_ALERT.detail,
          buttons: [
            {
              text: i18n.t("AppSettingsOrderHistory.cancelled"),
              handler: (d) => {},
            },
            {
              text: i18n.t("AppSettingsRestaurantInformation.Confirm"),
              handler: (d) =>
                courierDataCtx.closeDeliveryHandler(authCtx.restaurantId),
            },
          ],
          onDidDismiss: (e) => {},
        });
  };

  return (
    <HomeLayout
      title={i18n.t("AppOrder.MyTour")}
      button={
        authCtx.restaurantId
          ? {
              icon: !courierDataCtx.inDeliveryOrdersList.length
                ? add
                : checkmarkCircle,
              action: () => actionHandler(),
            }
          : undefined
      }
    >
      {!authCtx.restaurantId ? <NoRestaurantPrompt /> : <DeliveriesList />}
    </HomeLayout>
  );
};

export default DeliveriesPage;
