import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useIonModal, getPlatforms, useIonAlert } from "@ionic/react";
import AuthContext from "../../../contexts/auth.ctx";
import orderServices from "../../../services/apis/orderServices";
import SettingCard from "../SettingCard/SettingCard";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderDetailModal from "../../Modals/OrderDetailModal/OrderDetailModal";
import OrderItem from "../../Orders/OrderItem/OrderItem";
import Order from "../../../models/Order";
import API, { PosPrint } from "../../../services";
import Product from "../../../models/Product";
import dayjs from "dayjs";
import "./RestaurantOrderHistory.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const RestaurantOrderHistory: React.FC<ContainerProps> = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [orders, setOrders] = useState<Order[]>([]);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [selectedOrderForDetails, setSelectedOrderForDetails] = useState(
    {} as Order
  );
  const authCtx = useContext(AuthContext);

  const openOrderDetailHandler = (order: Order) => {
    setSelectedOrderForDetails(order);
    present();
  };

  const [presentAlert] = useIonAlert();
  const [presentAlertConfirmation] = useIonAlert();

  const getTotalPriceDeliveryOrder = () => {
    let total = 0;
    let deliveryFees = 0;
    const deliveryOrders = orders
      .filter((order) => order.type == "DELIVERY")
      .filter((order) => order.status !== "CANCELED");
    deliveryOrders.forEach((order) => {
      total = order.total_price
        ? order.delivery_fees
          ? total + order.total_price - order.delivery_fees
          : total + order.total_price
        : total;

      deliveryFees = order.delivery_fees
        ? deliveryFees + order.delivery_fees
        : deliveryFees;
    });

    return {
      amount: (total / 100).toFixed(2).toLocaleString(),
      deliveryFees: (deliveryFees / 100).toFixed(2).toLocaleString(),
      total: (total / 100 + deliveryFees / 100).toFixed(2).toLocaleString(),
    };
  };

  const getTotalPriceOnPlaceOrder = () => {
    let total = 0;
    const deliveryOrders = orders
      .filter((order) => order.type == "ON_PLACE")
      .filter((order) => order.status !== "CANCELED");
    deliveryOrders.forEach((order) => {
      total = order.total_price ? total + order.total_price : total;
    });
    return (total / 100).toFixed(2).toLocaleString();
  };

  const onOrderPrintHandler = (order: Order) => {
    const platforms = getPlatforms();
    if (platforms.includes("hybrid")) {
      PosPrint.printOrderTicket(order, restaurantDataCtx.posMacAddress);
    } else {
      presentAlert({
        header: i18n.t("AppSettingsOrderHistory.FunctionUnavailable"),
        message: i18n.t("AppSettingsOrderHistory.FeatureOnlyMobile"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
    }
  };

  const getNextOrder = (fromOrder: Order) => {
    let nextOrder: Order | undefined;
    const ordersList = orders
      .filter((order) => order.status == fromOrder.status)
      .sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

    ordersList.map((order) => {
      if (dayjs(order.createdAt).isBefore(fromOrder.createdAt) && !nextOrder) {
        nextOrder = order;
      }
    });
    if (!nextOrder) {
      return ordersList[0];
    }
    return nextOrder;
  };

  const getPreviousOrder = (fromOrder: Order) => {
    let previousOrder: Order | undefined;
    const ordersList = orders
      .filter((order) => order.status == fromOrder.status)
      .sort((a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix());

    ordersList.map((order) => {
      if (
        dayjs(order.createdAt).isAfter(fromOrder.createdAt) &&
        !previousOrder
      ) {
        previousOrder = order;
      }
    });
    if (!previousOrder) {
      return ordersList[0];
    }
    return previousOrder;
  };

  const onOrderDeleteHandler = (order: Order) => {
    const doDelete = () => {
      orderServices
        .setOrderAsCanceled(order.id, authCtx.restaurantId)
        .then((success) => {
          presentAlertConfirmation({
            header: success.header,
            message: success.detail,
            buttons: [{ text: "Ok", handler: (d) => dismiss() }],
            onDidDismiss: (e) => {},
          });
        })
        .catch((err) => {
          console.log("error", err);
          presentAlertConfirmation({
            header: err.header,
            message: err.detail,
            buttons: [{ text: "Ok", handler: (d) => dismiss() }],
            onDidDismiss: (e) => {},
          });
        });
    };

    presentAlert({
      header: i18n.t("AppOrder.CancelOrder"),
      message: i18n.t("AppOrder.CancelOrder?"),
      buttons: [
        {
          text: i18n.t("AppSettingsOrderHistory.cancelled"),
          handler: (d) => {},
        },
        {
          text: "Ok",
          handler: (d) => {
            doDelete();
          },
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const [present, dismiss] = useIonModal(OrderDetailModal, {
    order: selectedOrderForDetails,
    isHistory: true,
    restaurantDataCtx: restaurantDataCtx,
    asRestaurantOrder: true,
    onDelete: (order: Order) => onOrderDeleteHandler(order),
    onPrint: (order: Order) => onOrderPrintHandler(order),
    onDismiss: () => {
      dismiss();
    },
    onNextOrder: (fromOrder: Order) => {
      const nextOrder = getNextOrder(fromOrder);
      if (nextOrder) {
        setSelectedOrderForDetails(nextOrder);
      }
    },
    onPreviousOrder: (fromOrder: Order) => {
      const previousOrder = getPreviousOrder(fromOrder);
      if (previousOrder) {
        setSelectedOrderForDetails(previousOrder);
      }
    },
  });

  useEffect(() => {
    if (startDate) {
      let endDateCalculated = endDate;
      if (!endDateCalculated) {
        const dateOfToday = startDate;
        dateOfToday.setHours(0, 0, 0, 0);
        endDateCalculated = new Date(dateOfToday);
        endDateCalculated.setDate(startDate.getDate() + 1);
      }
      orderServices
        .getClosedOrderFromDateRange(
          authCtx.restaurantId,
          startDate,
          endDateCalculated
        )
        .then((orders) => {
          setOrders(orders);
        });
    }
  }, [startDate, endDate, authCtx.restaurantId]);

  return (
    <div className="restaurant-order-history">
      <SettingCard gridId="date-input">
        <span style={{ marginRight: "8px", fontWeight: "bold" }}>
          {i18n.t("AppSettingsOrderHistory.revenueFromDate")}
        </span>

        <DatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          isClearable={true}
        />
      </SettingCard>
      <SettingCard
        title={`${i18n.t("AppSettingsRestaurantInformation.Collect")}`}
        subTitle={`${i18n.t(
          "AppSettingsSalesSummary.total"
        )} ${getTotalPriceOnPlaceOrder()}${constants.CURRENCY}`}
        gridId="order-on-place"
      >
        {orders.filter((order) => order.type == "ON_PLACE").length ? (
          orders
            .filter((order) => order.type == "ON_PLACE")
            .map((order) => {
              return (
                <OrderItem
                  key={order.id}
                  status={order.status}
                  productsList={order.products}
                  customerInfos={order.customer}
                  orderNumber={order.number}
                  delivered={order.status == "DELIVERED"}
                  date={new Date(order.createdAt)}
                  order={order}
                  onClick={() => openOrderDetailHandler(order)}
                />
              );
            })
        ) : (
          <div>{i18n.t("AppSettingsDeliverySettings.NoOrderCollect")}</div>
        )}
      </SettingCard>

      <SettingCard
        title={`${i18n.t("AppSettingsRestaurantInformation.Delivery")}`}
        subTitle={`${i18n.t("AppSettingsSalesSummary.total")} ${
          getTotalPriceDeliveryOrder().total
        }${constants.CURRENCY} (${getTotalPriceDeliveryOrder().amount}${
          constants.CURRENCY
        } + ${getTotalPriceDeliveryOrder().deliveryFees}${
          constants.CURRENCY
        } ${i18n.t("AppSettingsDeliverySettings.deliveryFees")}`}
        gridId="order-delivery"
      >
        {orders.filter((order) => order.type == "DELIVERY").length ? (
          orders
            .filter((order) => order.type == "DELIVERY")
            .map((order) => {
              return (
                <OrderItem
                  key={order.id}
                  status={order.status}
                  productsList={order.products}
                  customerInfos={order.customer}
                  orderNumber={order.number}
                  delivered={order.status == "DELIVERED"}
                  date={new Date(order.createdAt)}
                  order={order}
                  onClick={() => openOrderDetailHandler(order)}
                />
              );
            })
        ) : (
          <div>{i18n.t("NewDeliveryClient.NoDeliveryOrders")}</div>
        )}
      </SettingCard>
    </div>
  );
};

export default RestaurantOrderHistory;
