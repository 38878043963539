import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import { image } from "ionicons/icons";
import React, { useRef, useState } from "react";
import i18n from "../../../translations/i18n";

import "./BaseImageInput.css";

interface ContainerProps {
  presetValue: string;
  value: File;
  onImageSelected: Function;
}

const BaseImageInput: React.FC<ContainerProps> = (props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="base-image-input" data-testid="base-image-input">
      <input
        className="base-image-input__hidden-input"
        type="file"
        ref={hiddenInputRef}
        onChange={(event) => {
          if (event?.target?.files?.length) {
            props.onImageSelected(event.target.files[0]);
          } else {
            return;
          }
        }}
      />
      {/* <div
        className="base-image-input__visualizer"
        style={{ backgroundImage: `url(${getCurrentDisplayImageUrl()})` }}
      ></div> */}
      <div
        className={classNames({
          "base-image-input__input-container": true,
          "base-image-input__input-container--drag-over": isDragOver,
        })}
        onDrop={(event) => {
          event.preventDefault();
          props.onImageSelected(event.dataTransfer.files[0]);
          setIsDragOver(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
      >
        <div className="image-input-icon" style={{ fontSize: "2rem" }}>
          <IonIcon icon={image} color="primary" />
        </div>
        <span className="base-image-input__input-container__text">
          <span
            className="base-image-input__input-container__text__action"
            onClick={() => hiddenInputRef.current?.click()}
          >
            {i18n.t("AppSettingsRestaurantInformation.uploadImageText")}
          </span>{" "}
        </span>
      </div>
    </div>
  );
};

export default BaseImageInput;
