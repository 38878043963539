export default class {
  public mon;
  public tue;
  public wed;
  public thu;
  public fri;
  public sat;
  public sun;


  constructor(
    mon: Array<any>,
    tue: Array<any>,
    wed: Array<any>,
    thu: Array<any>,
    fri: Array<any>,
    sat: Array<any>,
    sun: Array<any>,

  ) {
    this.mon = mon;
    this.tue = tue;
    this.wed = wed;
    this.thu = thu;
    this.fri = fri;
    this.sat = sat;
    this.sun = sun;
    
  }
}
