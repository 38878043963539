import React, { useEffect, useState } from "react";
import resetPasswordFormSchema from "../forms/reset-password.json";
import { ErrorAlert, FunctionalSuccessAlert, InputSchema } from "../types";
import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";

import authServices from "../services/apis/authServices";

const formSchema = resetPasswordFormSchema as InputSchema[];

const ResetPasswordContext = React.createContext({
  isBusy: false,
  formSchema,
  formSubmitHandler: (email: string) => {},
});

export const ResetPasswordContextProvider: React.FC = (props) => {
  const [isBusy, setIsBusy] = useState(false);

  // Utility methods
  const [present] = useIonAlert();
  const history = useHistory();

  const formSubmitHandler = (email: string) => {
    if (!isBusy) {
      setIsBusy(true);
      authServices
        .sendPasswordResetEmail(email)
        .then((success: FunctionalSuccessAlert) => {
          setIsBusy(false);
          present({
            header: success.header,
            message: success.detail(email),
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              history.goBack();
            },
          });
        })
        .catch((error: ErrorAlert) => {
          setIsBusy(false);
          present({
            header: error.header,
            message: error.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              history.goBack();
            },
          });
        });
    }

    return true;
  };

  return (
    <ResetPasswordContext.Provider
      value={{
        isBusy,
        formSchema,
        formSubmitHandler,
      }}
    >
      {props.children}
    </ResetPasswordContext.Provider>
  );
};

export default ResetPasswordContext;
