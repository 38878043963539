import { AuthContextProvider } from "../auth.ctx";
import { RestaurantDataContextProvider } from "../restaurant-data.ctx";
import { ProductsPageContextProvider } from "../products-page.ctx";
import { OrdersPageContextProvider } from "../orders-page.ctx";
import { EditProductContextProvider } from "../edit-product.ctx";
import { EditCategoryContextProvider } from "../edit-category.ctx";
import { NewOrderContextProvider } from "../new-order.ctx";
import { CustomerResearchContextProvider } from "../customer-research.ctx";
import { NewCustomerContextProvider } from "../new-customer.ctx";
import { CourierDataContextProvider } from "../courier-data.ctx";
import { EditProductOptionsContextProvider } from "../edit-options.ctx";

const RootProvider: React.FC = (props) => {
  return (
    <AuthContextProvider>
      <RestaurantDataContextProvider>
        <CourierDataContextProvider>
          <OrdersPageContextProvider>
            <ProductsPageContextProvider>
              <EditProductContextProvider>
                <EditCategoryContextProvider>
                  <NewOrderContextProvider>
                    <CustomerResearchContextProvider>
                      <NewCustomerContextProvider>
                        <EditProductOptionsContextProvider>
                          {props.children}
                        </EditProductOptionsContextProvider>
                      </NewCustomerContextProvider>
                    </CustomerResearchContextProvider>
                  </NewOrderContextProvider>
                </EditCategoryContextProvider>
              </EditProductContextProvider>
            </ProductsPageContextProvider>
          </OrdersPageContextProvider>
        </CourierDataContextProvider>
      </RestaurantDataContextProvider>
    </AuthContextProvider>
  );
};

export default RootProvider;
