import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import BaseButton from "../../../components/@Base/BaseButton/BaseButton";
import ContextualLayout from "../../../components/Layouts/ContextualLayout/ContextualLayout";
import ProductsCategoriesEditor from "../../../components/Products/ProductsCategoriesEditor/ProductsCategoriesEditor";
import ProductsInfosEditor from "../../../components/Products/ProductsInfosEditor/ProductsInfosEditor";
import ProductsOptionsEditor from "../../../components/Products/ProductsOptionsEditor/ProductsOptionsEditor";
import AuthContext from "../../../contexts/auth.ctx";
import EditProductContext from "../../../contexts/edit-product.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import routes from "../../../routes";
import "./ProductEditionPage.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const ProductEditionPage: React.FC<ContainerProps> = (props) => {
  enum EditionSteps { // Moove in component
    "CATEGORIES_EDITION",
    "PRODUCTS_EDITION",
    "OPTIONS_EDITION",
  }

  const editProductCtx = useContext(EditProductContext);
  const history = useHistory();

  const [currentEditionStep, setCurrentEditionStep] = useState(
    EditionSteps.CATEGORIES_EDITION
  );

  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);

  // 🔥 Hotfix for refresh of product edition page which doesn't hydrate restaurant data
  useEffect(() => {
    // Effect is mitigate only if no rtListeners have been set, it should prevent too much call
    if (authCtx.restaurantId && !restaurantDataCtx.rtListenersList.length) {
      restaurantDataCtx.initRestaurantData(authCtx.restaurantId);
    }
  }, [authCtx.restaurantId]);

  const goToNextStep = () => {
    if (currentEditionStep == EditionSteps.CATEGORIES_EDITION) {
      setCurrentEditionStep(EditionSteps.PRODUCTS_EDITION);
    } else if (currentEditionStep == EditionSteps.PRODUCTS_EDITION) {
      if (!editProductCtx.productHasOptions) {
        editProductCtx.formSubmitHandler();
      } else {
        setCurrentEditionStep(EditionSteps.OPTIONS_EDITION);
      }
    } else if (currentEditionStep == EditionSteps.OPTIONS_EDITION) {
      editProductCtx.formSubmitHandler();
    }
  };

  const goToPreviousStep = () => {
    if (currentEditionStep == EditionSteps.PRODUCTS_EDITION) {
      setCurrentEditionStep(EditionSteps.CATEGORIES_EDITION);
    } else if (currentEditionStep == EditionSteps.OPTIONS_EDITION) {
      setCurrentEditionStep(EditionSteps.PRODUCTS_EDITION);
    }
  };

  const isNextButtonDisabled = () => {
    if (
      currentEditionStep == EditionSteps.CATEGORIES_EDITION &&
      editProductCtx.productCategoryId
    ) {
      return false;
    } else if (
      currentEditionStep == EditionSteps.PRODUCTS_EDITION &&
      editProductCtx.productName &&
      editProductCtx.productPrice
    ) {
      return false;
    } else if (
      currentEditionStep == EditionSteps.OPTIONS_EDITION &&
      editProductCtx.productOptionsIds.length
    ) {
      return false;
    }
    return true;
  };
  const isPreviousButtonDisabled = () => {
    if (
      currentEditionStep == EditionSteps.PRODUCTS_EDITION ||
      currentEditionStep == EditionSteps.OPTIONS_EDITION
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (editProductCtx.redirectToProductsTrigger) {
      history.replace(routes.PRODUCTS_PAGE);
    }
  }, [editProductCtx.redirectToProductsTrigger]);

  return (
    <ContextualLayout
      title={
        editProductCtx.editMode == "UPDATE"
          ? i18n.t("AppMenu.ModifyProduct")
          : i18n.t("AppMenu.createProduct")
      }
      backPath={routes.PRODUCTS_PAGE}
    >
      {currentEditionStep == EditionSteps.CATEGORIES_EDITION ? (
        <ProductsCategoriesEditor key="categories_editor" />
      ) : currentEditionStep == EditionSteps.PRODUCTS_EDITION ? (
        <ProductsInfosEditor key="infos_editor" />
      ) : currentEditionStep == EditionSteps.OPTIONS_EDITION ? (
        <ProductsOptionsEditor key="products_options_editor" />
      ) : null}
      <div className="add-product-page__controls">
        <div className="add-product-page__controls__button-container">
          <BaseButton
            expand
            tight
            outline
            disabled={isPreviousButtonDisabled()}
            onClick={goToPreviousStep}
          >
            {i18n.t("AppMenu.previous")}
          </BaseButton>
        </div>
        <div className="add-product-page__controls__button-container">
          <BaseButton
            expand
            tight
            disabled={isNextButtonDisabled()}
            onClick={goToNextStep}
            isLoading={editProductCtx.isBusy}
          >
            {(currentEditionStep == EditionSteps.PRODUCTS_EDITION &&
              !editProductCtx.productHasOptions) ||
            currentEditionStep == EditionSteps.OPTIONS_EDITION
              ? i18n.t("AppMenu.save")
              : i18n.t("AppMenu.next")}
          </BaseButton>
        </div>
      </div>
    </ContextualLayout>
  );
};

export default ProductEditionPage;
