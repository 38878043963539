import React, { useContext, useState } from "react";
import { OrdersStatus } from "../types";
import AuthContext from "./auth.ctx";
import PosPrint from "../services/app/pos-print";
import Order from "../models/Order";
import RestaurantDataContext from "./restaurant-data.ctx";
import orderServices from "../services/apis/orderServices";

const OrdersPageContext = React.createContext({
  isBusy: false,
  currentOrdersSegment: "RECORDED",
  segmentSelectionHandler: (segment: OrdersStatus) => {},
  setOrderAsReady: (orderId: string) => {},
  setOrderAsClosed: (orderId: string) => {},
  setOrderAsPaid: (order: Order) => {},
  setOrderAsToStart: (orderId: string) => {},
  setOrderToProduction: (orderId: string) => {},
});

export const OrdersPageContextProvider: React.FC = (props) => {
  // Auth context needed to get restaurant ID
  const authCtx = useContext(AuthContext);
  // Restaurant context needed to get POS data
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [isBusy, setIsBusy] = useState(false);

  // UI order state selector
  const [currentOrdersSegment, setCurrentOrdersSegment] = useState("RECORDED");
  const segmentSelectionHandler = (segment: OrdersStatus) => {
    setCurrentOrdersSegment(segment);
    return true;
  };

  // Orders state modifiers

  const setOrderToProduction = (orderId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      orderServices
        .setOrderToProduction(orderId, authCtx.restaurantId)
        .then(() => {
          setIsBusy(false);
        })
        .catch((e) => {
          setIsBusy(false);
          console.log(e);
        });
    }
  };

  const setOrderAsToStart = (orderId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      orderServices
        .setOrderAsToStart(orderId, authCtx.restaurantId)
        .then(() => {
          setIsBusy(false);
        })
        .catch((e) => {
          setIsBusy(false);
          console.log(e);
        });
    }
  };

  const setOrderAsReady = (orderId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      orderServices
        .setOrderAsReady(orderId, authCtx.restaurantId)
        .then(() => {
          setIsBusy(false);
        })
        .catch((e) => {
          setIsBusy(false);
          console.log(e);
        });
    }
  };
  const setOrderAsPaid = (order: Order) => {
    if (!isBusy) {
      setIsBusy(true);
      orderServices
        .setOrderAsPaid(order.id, authCtx.restaurantId)
        .then(() => {
          setIsBusy(false);
          PosPrint.printOrderTicket(order, restaurantDataCtx.posMacAddress);
        })
        .catch((e) => {
          setIsBusy(false);
          console.log(e);
        });
    }
  };
  const setOrderAsClosed = (orderId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      orderServices
        .setOrderAsClosed(orderId, authCtx.restaurantId)
        .then(() => {
          setIsBusy(false);
        })
        .catch((e) => {
          setIsBusy(false);
          console.log(e);
        });
    }
  };

  return (
    <OrdersPageContext.Provider
      value={{
        isBusy,
        currentOrdersSegment,
        segmentSelectionHandler,
        setOrderAsReady,
        setOrderAsClosed,
        setOrderAsPaid,
        setOrderAsToStart,
        setOrderToProduction,
      }}
    >
      {props.children}
    </OrdersPageContext.Provider>
  );
};

export default OrdersPageContext;
