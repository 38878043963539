export default class {
  public id;
  public email;
  public firstname;
  public lastname;
  public phone;
  public profilePictureUrl;
  public profileThumbnailUrl;
  // NOTE those infos are not currently used but might be in the future
  public affiliateRestaurantName;
  public affiliateRestaurantLogoThumbnailUrl;

  constructor(
    id: string,
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    profilePictureUrl: string,
    profileThumbnailUrl: string,
    affiliateRestaurantName?: string,
    affiliateRestaurantLogoThumbnailUrl?: string
  ) {
    this.id = id;
    this.email = email;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.profilePictureUrl = profilePictureUrl;
    this.profileThumbnailUrl = profileThumbnailUrl;
    this.affiliateRestaurantName = affiliateRestaurantName;
    this.affiliateRestaurantLogoThumbnailUrl =
      affiliateRestaurantLogoThumbnailUrl;
  }
}
