import { IonContent, IonPage } from "@ionic/react";
import BasePageHeader from "../../@Base/BasePageHeader/BasePageHeader";
import "./ContextualLayout.css";

interface ContainerProps {
  title: string;
  backPath: string;
  wideEnabled?: boolean;
}

const ContextualLayout: React.FC<ContainerProps> = (props) => {
  const getMainContentClasses = () => {
    const base = ["contextual-layout__main__content"];
    if (props.wideEnabled) {
      base.push("contextual-layout__main__content--wide-enabled");
    }
    return base.join(" ");
  };
  return (
    <IonPage className="contextual-layout" data-testid="contextual-layout">
      <BasePageHeader title={props.title} defaultBackPath={props.backPath} />
      <IonContent
        forceOverscroll={false}
        data-testid="contextual-layout__ion-content"
      >
        <main
          className="contextual-layout__main"
          data-testid="contextual-layout__main"
        >
          <section className={getMainContentClasses()}>
            {props.children}
          </section>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default ContextualLayout;
