import CustomerInfos from "../../models/CustomerInfos";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { v4 as uuidv4 } from "uuid";
import {
  CustomerCreationPayload,
  SuccessAlert,
  CustomerUpdatePayload,
} from "../../types";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const customerServices = {
  searchCustomerByPhone(
    customerPhone: string,
    restaurantId: string
  ): Promise<CustomerInfos[]> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("customers")
        .where("phone", "==", customerPhone)
        .get()
        .then((querySnapshot) => {
          const customers: CustomerInfos[] = [];
          querySnapshot.forEach((doc) => {
            customers.push(
              new CustomerInfos(
                doc.id,
                doc.data().phone,
                doc.data().firstname,
                doc.data().lastname,
                doc.data().address_first_line,
                doc.data().address_second_line,
                doc.data().address_zipcode,
                doc.data().address_city,
                doc.data().address_latitude,
                doc.data().address_longitude,
                doc.data().address_place_id,
                doc.data().distance_to_restaurant || 0
              )
            );
          });
          res(customers);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CUSTOMER_SEARCH_ERROR_ALERT);
        });
    });
  },
  createNewCustomer(
    restaurantId: string,
    customerPayload: CustomerCreationPayload
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const generatedId = uuidv4();
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("customers")
        .doc(generatedId)
        .set({
          ...customerPayload,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          res(success.CUSTOMER_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CUSTOMER_CREATION_ERROR_ALERT);
        });
    });
  },
  updateCustomer(
    restaurantId: string,
    customerId: string,
    customerPayload: CustomerUpdatePayload
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("customers")
        .doc(customerId)
        .set(
          {
            ...customerPayload,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.CUSTOMER_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CUSTOMER_CREATION_ERROR_ALERT);
        });
    });
  },
};
export default customerServices;
