import { IonButton, IonSpinner } from "@ionic/react";
import classNames from "classnames";
import "./BaseButton.css";

// REVIEW test props functions
interface ContainerProps {
  type?: "button" | "submit" | "reset" | undefined;
  outline?: boolean;
  big?: boolean;
  routerLink?: string;
  expand?: boolean;
  onClick?: Function;
  disabled?: boolean;
  isLoading?: boolean;
  tight?: boolean;
  margins?: string;
  color?: string;
}

export const getExpandDefinitionFromProps = (
  expandProps: boolean | undefined
) => {
  return expandProps ? "block" : undefined;
};

export const getFillDefintionFromProps = (
  outlineProps: boolean | undefined
) => {
  return outlineProps ? "outline" : "solid";
};

export const getClassNameFromProps = (
  bigProps: boolean | undefined,
  tightProps: boolean | undefined
) => {
  const classes = ["base-button"];
  if (bigProps) {
    classes.push("base-button--big");
  }
  if (tightProps) {
    classes.push("base-button--tight");
  }

  return classes.join(" ");
};

const BaseButton: React.FC<ContainerProps> = (props) => {
  return (
    <IonButton
      routerLink={props.routerLink}
      mode="ios"
      strong
      expand={getExpandDefinitionFromProps(props.expand)}
      fill={getFillDefintionFromProps(props.outline)}
      className={getClassNameFromProps(props.big, props.tight)}
      onClick={(event) => (props.onClick ? props.onClick(event) : null)}
      data-testid="base-button"
      type={props.type}
      disabled={props.disabled || props.isLoading}
      size={props.tight ? "small" : "default"}
      style={{ margin: props.margins }}
      color={props.color ? props.color : undefined}
    >
      {/* {props.isLoading ? (
        <IonSpinner data-testid="base-button__loading-spinner" />
      ) : (
        <span style={{ color: props.color ? "white" : undefined }}>
          {props.children}
        </span>
      )} */}
      <span style={{ color: props.color ? "white" : undefined }}>
        <span
          className={classNames({
            "base-button__text--invisible": props.isLoading,
          })}
        >
          {props.children}
        </span>
        {props.isLoading && (
          <span className="base-button__loading-spinner">
            <IonSpinner data-testid="base-button__loading-spinner" />
          </span>
        )}
      </span>
    </IonButton>
  );
};

export default BaseButton;
