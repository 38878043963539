import { IonImg } from "@ionic/react";
import classNames from "classnames";
import Product from "../../../models/Product";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./OrderSummaryItem.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  product: Product;
  isFullScreen?: boolean;
}
// TODO add color mgmt
const OrderSummaryItem: React.FC<ContainerProps> = (props) => {
  return (
    <div className="order-summary-item" data-testid="order-summary-item">
      <div className="order-summary-item__base-product-infos">
        <div className="order-summary-item__base-product-infos__product-picture">
          <IonImg src={props.product.thumbnailUrl} />
        </div>
        <div
          className={
            props.isFullScreen
              ? "order-summary-item__base-product-infos__name--fullscreen"
              : "order-summary-item__base-product-infos__name"
          }
        >
          {props.product.name}
        </div>
        <div
          className={
            props.isFullScreen
              ? "order-summary-item__base-product-infos__price--fullscreen"
              : "order-summary-item__base-product-infos__price"
          }
        >
          {(props.product.price / 100).toFixed(2) + ` ${constants.CURRENCY}`}
        </div>
      </div>
      {props.product.optionsPreset &&
        props.product.optionsPreset?.length != 0 && (
          <div className="order-summary-item__options">
            {props.product.optionsPreset?.map((option) => {
              return (
                <div
                  className={
                    props.isFullScreen
                      ? "order-summary-item__options__item--fullscreen"
                      : "order-summary-item__options__item"
                  }
                  key={option.id}
                  style={{
                    color: `var(--ion-color-${option.color}-shade)`,
                  }}
                >
                  <span
                    className={classNames({
                      "order-summary-item__options__item__name": true,
                      "order-summary-item__options__item__name--minus":
                        option.name.toLowerCase().split(" ")[0] == "sans",
                      "order-summary-item__options__item__name--sup":
                        option.name.toLowerCase().split(" ")[0] == "sup",
                      "order-summary-item__options__item__name--kid":
                        option.name.toLowerCase().split(" ")[0] == "m.e",
                    })}
                  >
                    <div
                      className="order-summary-item__options__item__dot"
                      style={{
                        backgroundColor: `var(--ion-color-${option.color}-shade)`,
                      }}
                    />{" "}
                    {option.name}
                  </span>
                  <span>
                    {"(+ " +
                      (option.price / 100).toFixed(2) +
                      ` ${constants.CURRENCY})`}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      <div className="order-summary-item__base-product-quantity-container">
        <div
          className={
            props.isFullScreen
              ? "order-summary-item__base-product-quantity-text--fullscreen"
              : "order-summary-item__base-product-quantity-text"
          }
        >
          {i18n.t("AddProductProductPage.Quantity")}
        </div>
        <div
          className={
            props.isFullScreen
              ? "order-summary-item__base-product-quantity-amount--fullscreen"
              : "order-summary-item__base-product-quantity-amount"
          }
        >
          x{props.product.quantity}
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryItem;
