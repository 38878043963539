import { IonIcon } from "@ionic/react";
import { fastFood } from "ionicons/icons";
import { useContext } from "react";
import OrdersPageContext from "../../../contexts/orders-page.ctx";
import { OrdersStatus } from "../../../types";
import "./OrdersNoStates.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  type?: OrdersStatus;
}

const OrdersNoStates: React.FC<ContainerProps> = (props) => {
  const ordersCtx = useContext(OrdersPageContext);

  const textValueBase = props.type
    ? props.type
    : ordersCtx.currentOrdersSegment;

  const getClasses = () => {
    const base = ["orders-no-states"];
    if (!props.type) {
      base.push("orders-no-states--segment-contraints");
    }
    return base.join(" ");
  };

  return (
    <div className={getClasses()} data-testid="orders-no-states">
      <div className="orders-no-states__icon-container">
        <IonIcon
          icon={fastFood}
          className="orders-no-states__icon-container__icon"
        />
      </div>
      <span className="orders-no-states__text">
        {textValueBase == "RECORDED"
          ? i18n.t("AppOrder.noOrdersRegistered")
          : textValueBase == "READY"
          ? i18n.t("AppOrder.noOrdersReady")
          : i18n.t("AppOrder.noOrdersOnTour")}
      </span>
    </div>
  );
};

export default OrdersNoStates;
