import i18n from "../translations/i18n";

export default {
  REQUIRED: i18n.t("messages.FieldRequired"),
  EMAIL: i18n.t("messages.Email"),
  MIN_LENGTH: (nb: number) =>
    `${i18n.t("messages.MinLength")} ${nb} ${i18n.t("messages.Characters")}`,
  MAX_LENGTH: (nb: number) =>
    `${i18n.t("messages.MaxLength")} ${nb} ${i18n.t("messages.Characters")}`,
  PATTERN: (pattern: number) => `${i18n.t("messages.Pattern")} ${pattern}`,
  NOT_THE_SAME: i18n.t("messages.NotTheSame"),
  SIGNUP_ERROR_ALERT: {
    header: i18n.t("messages.SignupErrorHeader"),
    detail: i18n.t("messages.SignupErrorDetail"),
  },
  USER_ALREADY_EXISTS_ERROR_ALERT: {
    header: i18n.t("messages.UserExistsErrorHeader"),
    detail: i18n.t("messages.UserExistsErrorDetail"),
  },
  SIGNIN_ERROR_ALERT: {
    header: i18n.t("messages.SigninErrorHeader"),
    detail: i18n.t("messages.SigninErrorDetail"),
  },
  WRONG_USER_PASSWORD_ERROR_ALERT: {
    header: i18n.t("messages.WrongPassword"),
    detail: i18n.t("messages.CheckLogin"),
  },
  USER_NOT_FOUND_ERROR_ALERT: {
    header: i18n.t("messages.NoUser"),
    detail: i18n.t("messages.CheckLogin"),
  },
  RESET_PASSWORD_ERROR_ALERT: {
    header: i18n.t("messages.NoRecover"),
    detail: i18n.t("messages.UnknownError"),
  },
  CATEGORY_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CategoryCreationErrorDetail"),
  },
  CATEGORY_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CategoryUpdateErrorDetail"),
  },
  CATEGORY_DELETE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CategoryDeleteErrorDetail"),
  },
  PRODUCTS_OPTIONS_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductsOptionsCreationErrorDetail"),
  },
  PRODUCTS_OPTIONS_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductsOptionsUpdateErrorDetail"),
  },
  PRODUCTS_OPTIONS_DELETE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductsOptionsDeleteErrorDetail"),
  },
  PRODUCT_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductCreationErrorDetail"),
  },
  PRODUCT_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductUpdateErrorDetail"),
  },
  PRODUCT_DELETE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.ProductDeleteErrorDetail"),
  },
  CUSTOMER_SEARCH_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CustomerSearchErrorDetail"),
  },
  CUSTOMER_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CustomerCreationErrorDetail"),
  },
  ORDER_CREATION_RETRIEVE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.OrderCreationRetrieveErrorDetail"),
  },
  ORDER_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.OrderCreationErrorDetail"),
  },
  ORDER_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.OrderUpdateErrorDetail"),
  },
  ORDER_CREATE_NOT_AUTHORIZED: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.OrderCreateNotAuthorized"),
  },
  PENDING_COURIER_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.PendingCourierCreationErrorAlert"),
  },
  PENDING_COURIER_DELETION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.PendingCourierDeletionErrorDetail"),
  },
  COURIER_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CourierCreationErrorDetail"),
  },
  COURIER_DELETION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CourierDeletionErrorDetail"),
  },
  DELIVERY_CREATION_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.DeliveryCreationErrorDetail"),
  },
  DELIVERY_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.OrderUpdateErrorDetail"),
  },
  DELIVERY_CLOSING_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.DeliveryClosingErrorTour"),
  },
  WRONG_CREDENTIALS_PASSWORD_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.WrongCredentialsPasswordUpdateErrorDetail"),
  },
  PASSWORD_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.PasswordUpdateErrorDetail"),
  },
  RESTAURANT_INFOS_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.RestaurantInfosUpdateErrorDetail"),
  },
  USER_INFOS_UPDATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.UserInfosUpdateErrorDetail"),
  },
  COUPON_CREATE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CouponCreateErrorDetail"),
  },
  COUPON_CREATE_ALREADY_EXISTS_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CouponCreateAlreadyExistErrorDetail"),
  },
  COUPON_DELETE_ERROR_ALERT: {
    header: i18n.t("messages.AnErrorOccurred"),
    detail: i18n.t("messages.CouponDeleteErrorDetail"),
  },
};
