import { useContext } from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderItem from "../OrderItem/OrderItem";
import OrdersNoStates from "../OrdersNoStates/OrdersNoStates";
import "./OrdersFullLayout.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  onOrderClick: Function;
  productionLayout?: boolean;
}

const OrdersFullLayout: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const orderToShowLength = props.productionLayout
    ? [...restaurantDataCtx.ordersList].filter((order) => {
        return order.status === "NOT_IN_PRODUCTION";
      })
    : [...restaurantDataCtx.ordersList].filter(
        (order) => order.status == "RECORDED"
      ).length;

  const plannedOrderToShowLength = props.productionLayout
    ? [...restaurantDataCtx.plannedOrdersList].filter((order) => {
        return order.status === "NOT_IN_PRODUCTION";
      })
    : [...restaurantDataCtx.plannedOrdersList].filter(
        (order) => order.status == "RECORDED"
      ).length;
  return props.productionLayout ? (
    <div className="orders-full-layout" data-testid="orders-full-layout">
      <div className="orders-column">
        <header className="orders-column__header">Pas en production</header>
        <div className="orders-column__content">
          {orderToShowLength
            ? [...restaurantDataCtx.ordersList]
                .filter((order) => order.status === "NOT_IN_PRODUCTION")
                .map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      status={order.status}
                      productsList={order.products}
                      customerInfos={order.customer}
                      orderNumber={order.number}
                      delivered={order.status == "DELIVERED"}
                      date={new Date(order.createdAt)}
                      order={order}
                      onClick={() => props.onOrderClick(order)}
                    />
                  );
                })
            : null}

          {plannedOrderToShowLength
            ? [...restaurantDataCtx.plannedOrdersList]
                .filter((order) => order.status === "NOT_IN_PRODUCTION")
                .map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      status={order.status}
                      productsList={order.products}
                      customerInfos={order.customer}
                      orderNumber={order.number}
                      delivered={order.status == "DELIVERED"}
                      date={new Date(order.createdAt)}
                      order={order}
                      onClick={() => props.onOrderClick(order)}
                    />
                  );
                })
            : null}

          {!orderToShowLength && !plannedOrderToShowLength ? (
            <OrdersNoStates type="NOT_IN_PRODUCTION" />
          ) : null}

          <div className="orders-column__content__spacer" />
        </div>
      </div>
    </div>
  ) : (
    <div className="orders-full-layout" data-testid="orders-full-layout">
      <div className="orders-column">
        <header className="orders-column__header">
          {i18n.t("AppOrder.registered")}
        </header>
        <div className="orders-column__content">
          {orderToShowLength
            ? [...restaurantDataCtx.ordersList]
                .filter((order) => order.status == "RECORDED")
                .map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      status={order.status}
                      productsList={order.products}
                      customerInfos={order.customer}
                      orderNumber={order.number}
                      delivered={order.status == "DELIVERED"}
                      date={new Date(order.createdAt)}
                      order={order}
                      onClick={() => props.onOrderClick(order)}
                    />
                  );
                })
            : null}

          {plannedOrderToShowLength
            ? [...restaurantDataCtx.plannedOrdersList]
                .filter((order) => order.status == "RECORDED")
                .map((order) => {
                  return (
                    <OrderItem
                      key={order.id}
                      status={order.status}
                      productsList={order.products}
                      customerInfos={order.customer}
                      orderNumber={order.number}
                      delivered={order.status == "DELIVERED"}
                      date={new Date(order.createdAt)}
                      order={order}
                      onClick={() => props.onOrderClick(order)}
                    />
                  );
                })
            : null}

          {!orderToShowLength && !plannedOrderToShowLength ? (
            <OrdersNoStates type="RECORDED" />
          ) : null}

          <div className="orders-column__content__spacer" />
        </div>
      </div>
      <div className="orders-column">
        <header className="orders-column__header">
          {i18n.t("AppOrder.ready")}
        </header>
        <div className="orders-column__content">
          {[...restaurantDataCtx.ordersList].filter(
            (order) => order.status == "READY"
          ).length ? (
            [...restaurantDataCtx.ordersList]
              .filter((order) => order.status == "READY")
              .map((order) => {
                return (
                  <OrderItem
                    key={order.id}
                    status={order.status}
                    productsList={order.products}
                    customerInfos={order.customer}
                    orderNumber={order.number}
                    delivered={order.status == "DELIVERED"}
                    date={new Date(order.createdAt)}
                    order={order}
                    onClick={() => props.onOrderClick(order)}
                  />
                );
              })
          ) : (
            <OrdersNoStates type="READY" />
          )}
          <div className="orders-column__content__spacer" />
        </div>
      </div>
      <div className="orders-column">
        <header className="orders-column__header">
          {i18n.t("AppOrder.onTour")}
        </header>
        <div className="orders-column__content">
          {[...restaurantDataCtx.ordersList].filter(
            (order) => order.status == "OUT" || order.status == "DELIVERED"
          ).length ? (
            [...restaurantDataCtx.ordersList]
              .filter(
                (order) => order.status == "OUT" || order.status == "DELIVERED"
              )
              .map((order) => {
                return (
                  <OrderItem
                    key={order.id}
                    status={order.status}
                    productsList={order.products}
                    customerInfos={order.customer}
                    orderNumber={order.number}
                    delivered={order.status == "DELIVERED"}
                    date={new Date(order.createdAt)}
                    order={order}
                    onClick={() => props.onOrderClick(order)}
                    courierInfos={order.courier}
                  />
                );
              })
          ) : (
            <OrdersNoStates type="OUT" />
          )}
          <div className="orders-column__content__spacer" />
        </div>
      </div>
    </div>
  );
};

export default OrdersFullLayout;
