import { add } from "ionicons/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import HomeLayout from "../../../components/Layouts/HomeLayout/HomeLayout";
import OrdersFullLayout from "../../../components/Orders/OrdersFullLayout/OrdersFullLayout";
import OrdersTabsLayout from "../../../components/Orders/OrdersTabsLayout/OrdersTabsLayout";
import CustomerResearchContext from "../../../contexts/customer-research.ctx";
import NewOrderContext from "../../../contexts/new-order.ctx";
import { OrdersPageContextProvider } from "../../../contexts/orders-page.ctx";
import Order from "../../../models/Order";
import routes from "../../../routes";
import { getPlatforms, useIonAlert, useIonModal } from "@ionic/react";
import "./OrdersPage.css";
import OrderDetailModal from "../../../components/Modals/OrderDetailModal/OrderDetailModal";
import AuthContext from "../../../contexts/auth.ctx";
import API, { PosPrint } from "../../../services";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import dayjs from "dayjs";
import orderServices from "../../../services/apis/orderServices";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersPage: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [presentAlertConfirmation] = useIonAlert();
  const authCtx = useContext(AuthContext);
  const newOrderCtx = useContext(NewOrderContext);
  const customerResearchCtx = useContext(CustomerResearchContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [selectedOrderForDetails, setSelectedOrderForDetails] = useState(
    {} as Order
  );

  const onOrderPrintHandler = (order: Order) => {
    const platforms = getPlatforms();
    if (platforms.includes("hybrid")) {
      PosPrint.printOrderTicket(order, restaurantDataCtx.posMacAddress);
    } else {
      presentAlert({
        header: i18n.t("AppSettingsOrderHistory.FunctionUnavailable"),
        message: i18n.t("AppSettingsOrderHistory.FeatureOnlyMobile"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {},
      });
    }
  };

  const getNextOrder = (fromOrder: Order) => {
    let nextOrder: Order | undefined;
    const ordersList = [...restaurantDataCtx.ordersList]
      .filter((order) => order.status == fromOrder.status)
      .sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

    ordersList.map((order) => {
      if (dayjs(order.createdAt).isBefore(fromOrder.createdAt) && !nextOrder) {
        nextOrder = order;
      }
    });
    if (!nextOrder) {
      return ordersList[0];
    }
    return nextOrder;
  };

  const getPreviousOrder = (fromOrder: Order) => {
    let previousOrder: Order | undefined;
    const ordersList = [...restaurantDataCtx.ordersList]
      .filter((order) => order.status == fromOrder.status)
      .sort((a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix());

    ordersList.map((order) => {
      if (
        dayjs(order.createdAt).isAfter(fromOrder.createdAt) &&
        !previousOrder
      ) {
        previousOrder = order;
      }
    });
    if (!previousOrder) {
      return ordersList[0];
    }
    return previousOrder;
  };

  const [present, dismiss] = useIonModal(OrderDetailModal, {
    order: selectedOrderForDetails,
    restaurantDataCtx: restaurantDataCtx,
    asRestaurantOrder: true,
    onDelete: (order: Order) => onOrderDeleteHandler(order),
    onPrint: (order: Order) => onOrderPrintHandler(order),
    onDismiss: () => {
      dismiss();
    },
    onNextOrder: (fromOrder: Order) => {
      const nextOrder = getNextOrder(fromOrder);
      if (nextOrder) {
        setSelectedOrderForDetails(nextOrder);
      }
    },
    onPreviousOrder: (fromOrder: Order) => {
      const previousOrder = getPreviousOrder(fromOrder);
      if (previousOrder) {
        setSelectedOrderForDetails(previousOrder);
      }
    },
  });

  const onOrderDeleteHandler = (order: Order) => {
    const doDelete = () => {
      orderServices
        .setOrderAsCanceled(order.id, authCtx.restaurantId)
        .then((success) => {
          presentAlertConfirmation({
            header: success.header,
            message: success.detail,
            buttons: [{ text: "Ok", handler: (d) => dismiss() }],
            onDidDismiss: (e) => {},
          });
        })
        .catch((err) => {
          console.log("error", err);
          presentAlertConfirmation({
            header: err.header,
            message: err.detail,
            buttons: [{ text: "Ok", handler: (d) => dismiss() }],
            onDidDismiss: (e) => {},
          });
        });
    };

    presentAlert({
      header: i18n.t("AppOrder.CancelOrder"),
      message: i18n.t("AppOrder.CancelOrder?"),
      buttons: [
        {
          text: i18n.t("AppSettingsOrderHistory.cancelled"),
          handler: (d) => {},
        },
        {
          text: "Ok",
          handler: (d) => {
            doDelete();
          },
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const navigateToNewOrderPageHandler = () => {
    newOrderCtx.resetContext();
    customerResearchCtx.resetContext();
    history.push(routes.NEW_ORDER_PAGE);
  };

  const openOrderDetailHandler = (order: Order) => {
    setSelectedOrderForDetails(order);
    present();
  };

  return (
    <OrdersPageContextProvider>
      <HomeLayout
        title={i18n.t("AppMenu.orders")}
        button={{ icon: add, action: () => navigateToNewOrderPageHandler() }}
      >
        <OrdersTabsLayout
          onOrderClick={(order: Order) => openOrderDetailHandler(order)}
        />
        <OrdersFullLayout
          onOrderClick={(order: Order) => openOrderDetailHandler(order)}
        />
      </HomeLayout>
    </OrdersPageContextProvider>
  );
};

export default OrdersPage;
