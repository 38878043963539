import { useContext, useEffect, useState } from "react";
import EditProductOptionsContext from "../../../contexts/edit-options.ctx";
import EditProductContext from "../../../contexts/edit-product.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import ProductsOptions from "../../../models/ProductsOptions";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import ProductsOptionsEditionModal from "../../Modals/ProductsOptionsEditionModal/ProductsOptionsEditionModal";
import ProductsOptionsItem from "../ProductsOptionsItem/ProductsOptionsItem";
import "./ProductsOptionsEditor.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const ProductsOptionsEditor: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const editProductCtx = useContext(EditProductContext);
  const editProductsOptionsCtx = useContext(EditProductOptionsContext);
  const [editionModalIsOpen, setEditionModalIsOpen] = useState(false);

  useEffect(() => {
    // Might need to reset edit context and setup if update
    editProductsOptionsCtx.resetContext();
    setEditionModalIsOpen(false);
  }, [editProductsOptionsCtx.modalClosingTrigger]);

  const openEditionModalAsUpdate = (productsOptions: ProductsOptions) => {
    editProductsOptionsCtx.prefillForUpdate(productsOptions);
    setEditionModalIsOpen(true);
  };

  return (
    <>
      <ProductsOptionsEditionModal
        isOpen={editionModalIsOpen}
        onDismiss={() => {
          editProductsOptionsCtx.resetContext();
          setEditionModalIsOpen(false);
        }}
      />
      <div
        className="products-options-editor"
        data-testid="products-options-editor"
      >
        <span className="products-options-editor__title">
          {i18n.t("AddProductOptionPage.selectOptionGroups")}
        </span>
        <div className="products-options-editor__products-options">
          {restaurantDataCtx.productsOptionsList.length ? (
            restaurantDataCtx.productsOptionsList.map(
              (productsOptions: ProductsOptions, index: number) => (
                <ProductsOptionsItem
                  key={productsOptions.id}
                  productsOptions={productsOptions}
                  onActionClick={() =>
                    openEditionModalAsUpdate(productsOptions)
                  }
                  orderIndex={editProductCtx.productOptionsIds.findIndex(
                    (id) => id == productsOptions.id
                  )}
                  onSelect={() => {
                    if (
                      editProductCtx.productOptionsIds.find(
                        (id) => id == productsOptions.id
                      )
                    ) {
                      editProductCtx.removeProductOptionsId(productsOptions.id);
                    } else {
                      editProductCtx.addProductOptionsId(productsOptions.id);
                    }
                  }}
                  isSelected={
                    editProductCtx.productOptionsIds.find(
                      (id) => id == productsOptions.id
                    ) != undefined
                  }
                />
              )
            )
          ) : (
            <div
              className={"products-options-editor__products-options__no-state"}
            >
              {i18n.t("AddProductOptionPage.NoOptionGroup")}
            </div>
          )}
        </div>
        <div className="products-options-editor__controls">
          <BaseButton
            expand
            tight
            margins="0 0 1rem 0"
            onClick={() => setEditionModalIsOpen(true)}
          >
            {i18n.t("AddProductOptionPage.createOptionGroup")}
          </BaseButton>
        </div>
      </div>
    </>
  );
};

export default ProductsOptionsEditor;
