import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import BaseButton from "../../../components/@Base/BaseButton/BaseButton";
import ContextualLayout from "../../../components/Layouts/ContextualLayout/ContextualLayout";
import OrdersCustomerResearch from "../../../components/Orders/OrdersCustomerResearch/OrdersCustomerResearch";
import OrdersIsADeliverySelector from "../../../components/Orders/OrdersIsADeliverySelector/OrdersIsADeliverySelector";
import OrdersPaymentTypeSelector from "../../../components/Orders/OrdersPaymentTypeSelector/OrdersPaymentTypeSelector";
import OrdersProductsSelector from "../../../components/Orders/OrdersProductsSelector/OrdersProductsSelector";
import OrderIsAPlannedOrderSelector from "../../../components/Orders/OrderIsAPlannedOrderSelector/OrderIsAPlannedOrderSelector";
import OrderPlannedTimeSelector from "../../../components/Orders/OrderPlannedTimeSelector/OrderPlannedTimeSelector";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { happyBurgerRestaurantId } from "../../../services/apis/orderServices";
import routes from "../../../routes";
import "./NewOrderPage.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const NewOrderPage: React.FC<ContainerProps> = (props) => {
  enum NewOrderSteps {
    "IS_A_DELIVERY_SELECTION",
    "CUSTOMER_SEARCH",
    "PRODUCTS_SELECTION",
    "IS_PLANNED_ORDER",
    "ORDER_TIME_SELECTION",
    "PAYMENT_TYPE_SELECTION",
    "CREDIT_CARD_PAYMENT",
    "CASH_PAYMENT",
  }

  const newOrderCtx = useContext(NewOrderContext);
  const restaurantCtx = useContext(RestaurantDataContext);
  const history = useHistory();

  const [currentCreationStep, setCurrentCreationStep] = useState(
    NewOrderSteps.IS_A_DELIVERY_SELECTION
  );

  const handleNewOrderSubmission = () => {
    newOrderCtx.submitNewOrder();
  };

  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);

  // 🔥 Hotfix for refresh of order page which doesn't hydrate restaurant data
  useEffect(() => {
    // Effect is mitigate only if no rtListeners have been set, it should prevent too much call
    if (authCtx.restaurantId && !restaurantDataCtx.rtListenersList.length) {
      restaurantDataCtx.initRestaurantData(authCtx.restaurantId);
    }
  }, [authCtx.restaurantId]);

  const goToNextStep = () => {
    if (currentCreationStep == NewOrderSteps.IS_A_DELIVERY_SELECTION) {
      if (newOrderCtx.orderType == "ON_PLACE") {
        // Need to be replaced with on place order creation
        setCurrentCreationStep(NewOrderSteps.PRODUCTS_SELECTION);
      } else if (newOrderCtx.orderType == "DELIVERY") {
        setCurrentCreationStep(NewOrderSteps.CUSTOMER_SEARCH);
      }
    }
    if (currentCreationStep == NewOrderSteps.CUSTOMER_SEARCH) {
      setCurrentCreationStep(NewOrderSteps.PRODUCTS_SELECTION);
    }
    if (currentCreationStep == NewOrderSteps.PRODUCTS_SELECTION) {
      setCurrentCreationStep(NewOrderSteps.IS_PLANNED_ORDER);
    }
    if (currentCreationStep == NewOrderSteps.IS_PLANNED_ORDER) {
      if (newOrderCtx.orderPlanningType == "NOW") {
        // Need to be replaced with on place order creation
        setCurrentCreationStep(NewOrderSteps.PAYMENT_TYPE_SELECTION);
      } else if (newOrderCtx.orderPlanningType == "PLANNED") {
        setCurrentCreationStep(NewOrderSteps.ORDER_TIME_SELECTION);
      }
    }
    if (currentCreationStep == NewOrderSteps.ORDER_TIME_SELECTION) {
      setCurrentCreationStep(NewOrderSteps.PAYMENT_TYPE_SELECTION);
    }
    if (currentCreationStep == NewOrderSteps.PAYMENT_TYPE_SELECTION) {
      handleNewOrderSubmission();
    }
  };

  const goToPreviousStep = () => {
    if (
      currentCreationStep === NewOrderSteps.PRODUCTS_SELECTION &&
      newOrderCtx.orderType == "ON_PLACE"
    ) {
      return setCurrentCreationStep(NewOrderSteps.IS_A_DELIVERY_SELECTION);
    }
    if (
      currentCreationStep === NewOrderSteps.PAYMENT_TYPE_SELECTION &&
      newOrderCtx.orderPlanningType == "PLANNED"
    ) {
      return setCurrentCreationStep(NewOrderSteps.ORDER_TIME_SELECTION);
    }
    switch (currentCreationStep) {
      case NewOrderSteps.CUSTOMER_SEARCH:
        return setCurrentCreationStep(NewOrderSteps.IS_A_DELIVERY_SELECTION);
      case NewOrderSteps.PRODUCTS_SELECTION:
        return setCurrentCreationStep(NewOrderSteps.CUSTOMER_SEARCH);
      case NewOrderSteps.IS_PLANNED_ORDER:
        return setCurrentCreationStep(NewOrderSteps.PRODUCTS_SELECTION);
      case NewOrderSteps.ORDER_TIME_SELECTION:
        return setCurrentCreationStep(NewOrderSteps.IS_PLANNED_ORDER);
      case NewOrderSteps.PAYMENT_TYPE_SELECTION:
        return setCurrentCreationStep(NewOrderSteps.IS_PLANNED_ORDER);
    }
  };

  const isNextButtonDisabled = () => {
    if (
      currentCreationStep == NewOrderSteps.IS_A_DELIVERY_SELECTION &&
      newOrderCtx.orderType
    ) {
      return false;
    } else if (
      currentCreationStep == NewOrderSteps.CUSTOMER_SEARCH &&
      newOrderCtx.customer.id
    ) {
      return false;
    } else if (
      currentCreationStep == NewOrderSteps.PRODUCTS_SELECTION &&
      newOrderCtx.cart.length
    ) {
      return false;
    } else if (
      currentCreationStep == NewOrderSteps.IS_PLANNED_ORDER &&
      newOrderCtx.orderPlanningType
    ) {
      return false;
    } else if (
      currentCreationStep == NewOrderSteps.ORDER_TIME_SELECTION &&
      newOrderCtx.orderPlannedTime
    ) {
      return false;
    } else if (
      currentCreationStep == NewOrderSteps.PAYMENT_TYPE_SELECTION &&
      newOrderCtx.paymentType
    ) {
      return false;
    }
    return true;
  };

  const isPreviousButtonDisabled = () => {
    if (
      currentCreationStep == NewOrderSteps.CUSTOMER_SEARCH ||
      currentCreationStep == NewOrderSteps.PRODUCTS_SELECTION ||
      currentCreationStep == NewOrderSteps.PAYMENT_TYPE_SELECTION ||
      currentCreationStep == NewOrderSteps.IS_PLANNED_ORDER ||
      currentCreationStep == NewOrderSteps.ORDER_TIME_SELECTION
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (newOrderCtx.redirectToOrdersTrigger) {
      history.replace(routes.ORDERS_PAGE);
    }
  }, [newOrderCtx.redirectToOrdersTrigger]);

  return (
    <ContextualLayout
      title={i18n.t("AppOrder.newOrder")}
      backPath={routes.ORDERS_PAGE}
      wideEnabled={currentCreationStep == NewOrderSteps.PRODUCTS_SELECTION}
    >
      {currentCreationStep == NewOrderSteps.IS_A_DELIVERY_SELECTION ? (
        <OrdersIsADeliverySelector />
      ) : currentCreationStep == NewOrderSteps.CUSTOMER_SEARCH ? (
        <OrdersCustomerResearch />
      ) : currentCreationStep == NewOrderSteps.PRODUCTS_SELECTION ? (
        <OrdersProductsSelector />
      ) : currentCreationStep == NewOrderSteps.IS_PLANNED_ORDER ? (
        <OrderIsAPlannedOrderSelector />
      ) : currentCreationStep == NewOrderSteps.ORDER_TIME_SELECTION ? (
        <OrderPlannedTimeSelector />
      ) : currentCreationStep == NewOrderSteps.PAYMENT_TYPE_SELECTION ? (
        <OrdersPaymentTypeSelector />
      ) : null}
      <div className="new-order-page__controls">
        <div className="new-order-page__controls__button-container">
          <BaseButton
            expand
            tight
            outline
            disabled={isPreviousButtonDisabled()}
            onClick={goToPreviousStep}
          >
            {i18n.t("AppMenu.previous")}
          </BaseButton>
        </div>
        <div className="new-order-page__controls__button-container">
          <BaseButton
            expand
            tight
            disabled={isNextButtonDisabled()}
            onClick={goToNextStep}
            isLoading={newOrderCtx.isBusy}
          >
            {currentCreationStep == NewOrderSteps.PAYMENT_TYPE_SELECTION
              ? i18n.t("AppSettingsRestaurantInformation.Confirm")
              : i18n.t("AppMenu.next")}
          </BaseButton>
        </div>
      </div>
    </ContextualLayout>
  );
};

export default NewOrderPage;
