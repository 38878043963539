import i18n from "../translations/i18n";

export default {
  CATEGORY_DELETION_INFOS_ALERT: {
    header: i18n.t("messages.CategoryDeletionInfosHeader"),
    detail: i18n.t("messages.CategoryDeletionInfosDetail"),
  },
  PRODUCT_DELETION_INFOS_ALERT: {
    header: i18n.t("messages.ProductDeletionInfosHeader"),
    detail: i18n.t("messages.ProductDeletionInfosDetail"),
  },
  DELIVERY_ENDING_INFOS_ALERT: {
    header: i18n.t("messages.DeliveryEndingInfosHeader"),
    detail: i18n.t("messages.DeliveryEndingInfosDetail"),
  },
};
