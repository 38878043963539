import "./SettingLabelAndInfo.css";

interface ContainerProps {
  label: string;
  info: string;
}

const SettingLabelAndInfo: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="setting-label-and-info"
      data-testid="setting-label-and-info"
    >
      <span>{props.label}</span>
      <span>{props.info}</span>
    </div>
  );
};

export default SettingLabelAndInfo;
