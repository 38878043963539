import ProductsCategoryColorItem from "../ProductsCategoryColorItem/ProductsCategoryColorItem";
import "./ProductsCategoryColorSelector.css";

interface ContainerProps {
  onSelect: Function;
  selectedColor: string;
}

const ProductsCategoryColorSelector: React.FC<ContainerProps> = (props) => {
  const colorPalette = [
    "blueviolet",
    "brown",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "crimson",
    "darkorchid",
    "darkslateblue",
    "deeppink",
    "deepskyblue",
    "dodgerblue",
    "firebrick",
    "forestgreen",
    "gold",
    "goldenrod",
    "hotpink",
    "indigo",
    "lightseagreen",
    "lightslategray",
    "limegreen",
    "mediumorchid",
    "mediumturquoise",
    "olivedrab",
    "orangered",
    "royalblue",
    "seagreen",
    "slateblue",
    "steelblue",
    "tomato",
    "maroon",
    "red",
    "purple",
    "fuchsia",
    "green",
    "lime",
    "olive",
    "yellow",
    "navy",
    "blue",
    "teal",
    "aqua",
    "orange",
  ];

  return (
    <div
      className="products-category-color-selector"
      data-testid="products-category-color-selector"
    >
      {colorPalette.map((color: string) => {
        return (
          <ProductsCategoryColorItem
            key={color}
            color={color}
            action={() => props.onSelect(color)}
            isActive={color == props.selectedColor}
          />
        );
      })}
    </div>
  );
};

export default ProductsCategoryColorSelector;
