import React, { useContext, useEffect, useState } from "react";
import editProductFormSchema from "../forms/edit-product.json";
import { BaseAlert, ErrorAlert, InputSchema, SuccessAlert } from "../types";
import AuthContext from "./auth.ctx";
import { useIonAlert } from "@ionic/react";
import infos from "../messages/infos";
import productServices from "../services/apis/productServices";
import i18n from "../translations/i18n";

type EditMode = "ADD" | "UPDATE";

const formSchema = editProductFormSchema as InputSchema[];

const EditProductContext = React.createContext({
  isBusy: false,
  editMode: "ADD",
  formSchema,
  productToUpdateId: "",
  productCategoryId: "",
  productName: "",
  productDescription: "",
  productPrice: "",
  productIsUnavailable: false,
  currentToUpdatePictureUrl: "",
  productImageFile: new File([], ""),
  productHasOptions: false,
  redirectToProductsTrigger: false,
  productOptionsIds: [] as string[],
  productLongDesc: "",
  setAsUpdate: (productId: string) => {},
  productCategorySelectionHandler: (id: string) => {},
  productNameInputHandler: (name: string) => {},
  productDescriptionInputHandler: (name: string | "") => {},
  productPriceInputHandler: (price: string) => {},
  productIsUnavailableHandler: (unavailable: boolean) => {},
  currentToUpdatePictureUrlSetter: (url: string) => {},
  productImageFileInputHandler: (imageFile: File) => {},
  productHasOptionsInputHandler: (hasOptions: boolean) => {},
  formSubmitHandler: () => {},
  resetContext: () => {},
  deleteProductHandler: () => {},
  addProductOptionsId: (optionId: string) => {},
  removeProductOptionsId: (optionId: string) => {},
  productsOptionsIdsPresetHandler: (productsOptionsIds: string[]) => {},
  productLongDescInputHandler: (name: string) => {},
});

export const EditProductContextProvider: React.FC = (props) => {
  // Auth context needed to get restaurant ID
  const authCtx = useContext(AuthContext);
  const [isBusy, setIsBusy] = useState(false);
  const [productIsUnavailable, setProductIsUnavailable] = useState(false);
  const [editMode, setEditMode] = useState("ADD" as EditMode);
  // Product data states
  const [productCategoryId, setProductCategoryId] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productLongDesc, setProductLongDesc] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImageFile, setProductImageFile] = useState<File>(
    new File([], "")
  );
  const [productHasOptions, setProductHasOptions] = useState(false);
  const [productOptionsIds, setProductOptionsIds] = useState([] as string[]);
  // Product update only data states
  const [productToUpdateId, setProductToUpdateId] = useState("");
  const [currentToUpdatePictureUrl, setCurrentToUpdatePictureUrl] =
    useState("");
  // Utility state
  const [redirectToProductsTrigger, setRedirectToProductsTrigger] =
    useState(false);

  // Utility methods
  const [present] = useIonAlert();
  const resetContext = () => {
    setEditMode("ADD");
    setProductToUpdateId("");
    setProductCategoryId("");
    setProductName("");
    setProductDescription("");
    setProductLongDesc("");
    setProductPrice("");
    setProductIsUnavailable(false);
    setCurrentToUpdatePictureUrl("");
    setProductImageFile(new File([], ""));
    setProductHasOptions(false);
    setIsBusy(false);
    setProductOptionsIds([]);
  };
  const returnToProductsPage = () => {
    setRedirectToProductsTrigger(true);
    setRedirectToProductsTrigger(false);
  };

  const setAsUpdate = (idToUpdate: string) => {
    setProductToUpdateId(idToUpdate);
    setEditMode("UPDATE");
  };

  // Data setters
  const productIsUnavailableHandler = (unavailable: boolean) => {
    setProductIsUnavailable(unavailable);
  };
  const productCategorySelectionHandler = (id: string) => {
    setProductCategoryId(id);
  };
  const productNameInputHandler = (name: string) => {
    setProductName(name);
  };

  const productDescriptionInputHandler = (description: string | "") => {
    setProductDescription(description);
  };

  const productLongDescInputHandler = (longDesc: string) => {
    setProductLongDesc(longDesc);
  };

  const productPriceInputHandler = (price: string) => {
    setProductPrice(price);
  };
  const productImageFileInputHandler = (imageFile: File) => {
    setProductImageFile(imageFile);
  };
  const productHasOptionsInputHandler = (hasOptions: boolean) => {
    setProductHasOptions(hasOptions);
  };
  const addProductOptionsId = (optionId: string) => {
    setProductOptionsIds([...productOptionsIds, optionId]);
  };

  const removeProductOptionsId = (optionId: string) => {
    setProductOptionsIds([...productOptionsIds].filter((id) => id != optionId));
  };
  // Data setter only for update
  const currentToUpdatePictureUrlSetter = (url: string) => {
    setCurrentToUpdatePictureUrl(url);
  };
  // ---> Preset options handler for update
  const productsOptionsIdsPresetHandler = (productsOptionsIds: string[]) => {
    setProductOptionsIds([...productsOptionsIds]);
  };

  // Product CRUD methods
  const createProductHandler = () => {
    setIsBusy(true);
    productServices
      .createNewProduct(
        authCtx.restaurantId,
        productCategoryId,
        productName,
        productDescription,
        +productPrice,
        productIsUnavailable,
        productImageFile,
        productHasOptions,
        productOptionsIds,
        productLongDesc
      )
      .then((success: SuccessAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            resetContext();
            returnToProductsPage();
          },
        });
      })
      .catch((error: ErrorAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            resetContext();
            returnToProductsPage();
          },
        });
      });
  };

  const updateProductHandler = () => {
    setIsBusy(true);

    productServices
      .updateProduct(
        productToUpdateId,
        authCtx.restaurantId,
        productCategoryId,
        productName,
        productDescription,
        +productPrice,
        productIsUnavailable,
        productImageFile,
        productHasOptions,
        productOptionsIds,
        productLongDesc
      )
      .then((success: SuccessAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            resetContext();
            returnToProductsPage();
          },
        });
      })
      .catch((error: ErrorAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            resetContext();
            returnToProductsPage();
          },
        });
      });
  };

  const deleteProductHandler = () => {
    present({
      header: infos.PRODUCT_DELETION_INFOS_ALERT.header,
      message: infos.PRODUCT_DELETION_INFOS_ALERT.detail,
      buttons: [
        i18n.t("AppSettingsOrderHistory.cancelled"),
        {
          text: "Ok",
          handler: (d) => {
            setIsBusy(true);
            setTimeout(() => {
              productServices
                .deleteProduct(productToUpdateId, authCtx.restaurantId)
                .then((success: BaseAlert) => {
                  setIsBusy(false);
                  present({
                    header: success.header,
                    message: success.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => returnToProductsPage(),
                  });
                })
                .catch((error: BaseAlert) => {
                  setIsBusy(false);
                  console.log(error);
                  present({
                    header: error.header,
                    message: error.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => returnToProductsPage(),
                  });
                });
            }, 150);
          },
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const formSubmitHandler = () => {
    if (!isBusy && productCategoryId && productName && productPrice) {
      editMode === "ADD" ? createProductHandler() : updateProductHandler();
    }
  };

  return (
    <EditProductContext.Provider
      value={{
        isBusy,
        editMode,
        productToUpdateId,
        formSchema,
        productCategoryId,
        productName,
        productDescription,
        productPrice,
        productIsUnavailable,
        currentToUpdatePictureUrl,
        productImageFile,
        productHasOptions,
        redirectToProductsTrigger,
        productOptionsIds,
        productLongDesc,
        setAsUpdate,
        productCategorySelectionHandler,
        productNameInputHandler,
        productDescriptionInputHandler,
        productPriceInputHandler,
        productIsUnavailableHandler,
        currentToUpdatePictureUrlSetter,
        productImageFileInputHandler,
        productHasOptionsInputHandler,
        formSubmitHandler,
        resetContext,
        deleteProductHandler,
        addProductOptionsId,
        removeProductOptionsId,
        productsOptionsIdsPresetHandler,
        productLongDescInputHandler,
      }}
    >
      {props.children}
    </EditProductContext.Provider>
  );
};

export default EditProductContext;
