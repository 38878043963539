import Product from "../models/Product";
import RestaurantInfos from "../models/RestaurantInfos";

export const getTotalPriceFromProducts = (productsList: Record<any, any>[]) => {
  const reducedAmount = productsList
    .map((product) => {
      return product.price | 0;
    })
    .reduce((prev, current) => {
      return current + prev;
    }, 0);
  return reducedAmount;
};

export const getAmountAndFees = async (
  products: Product[],
  orderType: string,
  restaurantInfos: RestaurantInfos,
  distance?: number
) => {
  let deliveryFees = 0;
  const productsPayload = products;
  const defaultFreeDeliveryFeesTrigger =
    restaurantInfos.freeDeliveryFeesTrigger;
  const defaultDeliveryFees = restaurantInfos.deliveryFees;
  const deliveryFeesPriceByKm = restaurantInfos.deliveryFeesByKm;
  let amount = getTotalPriceFromProducts(productsPayload);

  if (orderType == "DELIVERY") {
    const priceKm =
      deliveryFeesPriceByKm && distance ? deliveryFeesPriceByKm * distance : 0;

    deliveryFees =
      defaultFreeDeliveryFeesTrigger &&
      typeof defaultFreeDeliveryFeesTrigger == "number"
        ? +getTotalPriceFromProducts(productsPayload) >
          defaultFreeDeliveryFeesTrigger
          ? 0
          : defaultDeliveryFees
          ? defaultDeliveryFees + priceKm
          : priceKm
        : defaultDeliveryFees
        ? defaultDeliveryFees + priceKm
        : priceKm;

    deliveryFees = Math.round(deliveryFees);
  } else {
    deliveryFees = 0;
  }
  amount = deliveryFees ? amount + deliveryFees : amount;
  return {
    amount: amount - deliveryFees,
    deliveryFees: deliveryFees,
    total: amount,
  };
};

//   order: Order,
//   restaurantInfos: RestaurantInfos
// ) => {
//   let deliveryFees = 0;
//   let isDelivery = order.type == "DELIVERY";
//   const reducedAmount = order.products
//     .map((product) => {
//       return product.price | 0;
//     })
//     .reduce((prev, current) => {
//       return current + prev;
//     }, 0);
//   if (isDelivery) {
//     if (restaurantInfos.deliveryFees) {
//       deliveryFees = restaurantInfos.deliveryFees;
//     } else if (
//       restaurantInfos.deliveryFees &&
//       restaurantInfos.freeDeliveryFeesTrigger
//     ) {
//       if (reducedAmount < restaurantInfos.freeDeliveryFeesTrigger) {
//         deliveryFees = restaurantInfos.deliveryFees;
//       }
//     } else if (restaurantInfos.deliveryFees) {
//       deliveryFees = restaurantInfos.deliveryFees;
//     }
//     if (restaurantInfos.availableCitiesOnDelivery?.length) {
//       const cityData = restaurantInfos?.availableCitiesOnDelivery?.find(
//         (city) =>
//           city.name == order.customer?.addressCity &&
//           city.zipCode == order.customer?.addressZipcode
//       );
//       if (
//         cityData &&
//         cityData.deliveryFees !== null &&
//         cityData.deliveryFees !== undefined &&
//         typeof cityData.deliveryFees == "number"
//       ) {
//         deliveryFees =
//           cityData.freeDeliveryFeesTrigger !== null &&
//           cityData.freeDeliveryFeesTrigger !== undefined &&
//           typeof cityData.freeDeliveryFeesTrigger == "number"
//             ? reducedAmount > cityData.freeDeliveryFeesTrigger
//               ? 0
//               : cityData.deliveryFees
//             : cityData.deliveryFees;
//       }
//     }
//   }

//   const amount = order.total_price
//     ? order.delivery_fees
//       ? order.total_price - order.delivery_fees
//       : order.total_price
//     : reducedAmount;

//   deliveryFees = order.delivery_fees ? order.delivery_fees : deliveryFees;
//   return {
//     amount: (amount / 100).toFixed(2),
//     deliveryFees: (deliveryFees / 100).toFixed(2),
//     total: amount == 0 ? "-" : ((amount + deliveryFees) / 100).toFixed(2),
//   };
// };
