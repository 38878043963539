import resizeFile from "../app/image-resizer";
import { v4 as uuidv4 } from "uuid";
import { $firebaseStorage } from "../app/firebase-service";

type ImageAndThumbnailUploadResponse = {
  imagePath: string;
  imageDownloadUrl: string;
  thumbnailPath: string;
  thumbnailDownloadUrl: string;
};

// TODO !!
// FIXME : WARNING add a limit to image upload size

const storageServices = {
  uploadImageAndThumbnail(
    path: string,
    file: File
  ): Promise<ImageAndThumbnailUploadResponse> {
    return new Promise((res, rej) => {
      const fileUuid = uuidv4();
      resizeFile(file).then((thumbnailFile) => {
        $firebaseStorage
          .ref()
          .child(`${path}/${fileUuid}.jpg`)
          .put(file)
          .then((fullSizeImageUploadTask) => {
            fullSizeImageUploadTask.ref
              .getDownloadURL()
              .then((fullSizeImageDownloadUrl) => {
                $firebaseStorage
                  .ref()
                  .child(`${path}/thmb_${fileUuid}.jpg`)
                  .put(thumbnailFile as File)
                  .then((thumbnailImageUploadTask) => {
                    thumbnailImageUploadTask.ref
                      .getDownloadURL()
                      .then((thumbnailImageDownloadUrl) => {
                        res({
                          imagePath: `${path}/${fileUuid}.jpg`,
                          imageDownloadUrl: fullSizeImageDownloadUrl,
                          thumbnailPath: `${path}/thmb_${fileUuid}.jpg`,
                          thumbnailDownloadUrl: thumbnailImageDownloadUrl,
                        });
                      })
                      .catch((err) => {
                        console.log(
                          "tumbnail-image upload error (dlurl): " + err
                        );
                        rej("tumbnail-image upload error (dlurl)");
                      });
                  })
                  .catch((err) => {
                    console.log("tumbnail-image upload error : " + err);
                    rej("tumbnail-image upload error");
                  });
              })
              .catch((err) => {
                console.log("full-image upload error (dlurl) : " + err);
                rej("full-image upload error (dlurl)");
              });
          })
          .catch((err) => {
            console.log("full-image upload error : " + err);
            rej("tumbnail-image upload error");
          });
      });
    });
  },
};
export default storageServices;
