import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import AuthContext from "../../../contexts/auth.ctx";
import orderServices from "../../../services/apis/orderServices";
import SettingCard from "../SettingCard/SettingCard";
import "./RestaurantEarnings.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const RestaurantEarnings: React.FC<ContainerProps> = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [totals, setTotals] = useState({
    totalCash: 0,
    totalCard: 0,
    totalStripe: 0,
    totalOfDay: 0,
  });
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (startDate) {
      orderServices
        .getTotalAmountsOfDay(authCtx.restaurantId, startDate, endDate)
        .then((totals) => {
          setTotals(totals);
        });
    }
  }, [startDate, endDate]);

  return (
    <div className="restaurant-earnings">
      <SettingCard gridId="date-input">
        <span style={{ marginRight: "8px", fontWeight: "bold" }}>
          {i18n.t("AppSettingsOrderHistory.revenueFromDate")}
        </span>

        <DatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          isClearable={true}
        />

        {/* <DatePicker
          value={dateOfDay}
          onChange={(value: Date) => setDateOfDay(value)}
          clearIcon={null}
        /> */}
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsSalesSummary.total")}
        gridId="total-earning"
      >
        <span className="restaurant-earnings__earning">
          {totals.totalOfDay / 100}
          {constants.CURRENCY}{" "}
        </span>
      </SettingCard>
    </div>
  );
};

export default RestaurantEarnings;
