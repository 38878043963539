// NOTE Category Editor might need a seperate context
import { useIonModal } from "@ionic/react";
import { useContext, useEffect } from "react";
import EditCategoryContext from "../../../contexts/edit-category.ctx";
import EditProductContext from "../../../contexts/edit-product.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import ProductCategory from "../../../models/ProductCategory";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import CategoryEditionModal from "../../Modals/CategoryEditionModal/CategoryEditionModal";
import ProductsCategoryChip from "../ProductsCategoryChip/ProductsCategoryChip";
import API from "../../../services";
import "./ProductsCategoriesEditor.css";
import AuthContext from "../../../contexts/auth.ctx";
import productCategoryServices from "../../../services/apis/productCategoryServices";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const ProductsCategoriesEditor: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const editProductCtx = useContext(EditProductContext);
  const editCategoryCtx = useContext(EditCategoryContext);
  const authCtx = useContext(AuthContext);

  const [present, dismiss] = useIonModal(CategoryEditionModal, {
    editCategoryCtx,
    onDismiss: () => dismiss(),
  });

  const openNewCategoryModal = () => {
    editCategoryCtx.resetContext();
    present();
  };

  const openUpdateCategoryModal = () => {
    editCategoryCtx.resetContext();
    editCategoryCtx.setAsUpdate(editProductCtx.productCategoryId);
    const correspondingCategory = restaurantDataCtx.productCategoriesList.find(
      (cat) => {
        return cat.id == editProductCtx.productCategoryId;
      }
    );
    if (correspondingCategory) {
      editCategoryCtx.categoryColorSelectionHandler(
        correspondingCategory.color
      );
      editCategoryCtx.categoryNamePrefillHandler(correspondingCategory.name);
    } else {
      return;
    }
    present();
  };

  useEffect(() => {
    dismiss();
  }, [editCategoryCtx.modalClosingTrigger]);

  const positionUpdateHandler = (
    direction: "UP" | "DOWN",
    category: ProductCategory,
    index: number
  ) => {
    if (
      (direction == "UP" && index == 0) ||
      (direction == "DOWN" &&
        index == restaurantDataCtx.productCategoriesList.length - 1)
    ) {
      return;
    }
    const sideEffectedCategory =
      direction == "DOWN"
        ? [...restaurantDataCtx.productCategoriesList][index + 1]
        : [...restaurantDataCtx.productCategoriesList][index - 1];
    productCategoryServices.updateProductCategoriesPositions(
      authCtx.restaurantId,
      category.id,
      direction == "DOWN" ? index + 1 : index - 1,
      sideEffectedCategory.id,
      index
    );
  };

  return (
    <div
      className="products-categories-editor"
      data-testid="products-categories-editor"
    >
      <span className="products-categories-editor__title">
        {i18n.t("AppMenu.selectCategory")}
      </span>
      <div className="products-categories-editor__categories-frame">
        {restaurantDataCtx.productCategoriesList.length ? (
          restaurantDataCtx.productCategoriesList.map(
            (category: ProductCategory, index) => (
              <ProductsCategoryChip
                key={category.id}
                name={category.name}
                color={category.color}
                alignStart
                margin="0 0 0.5rem 0"
                isMuted={editProductCtx.productCategoryId != category.id}
                action={() =>
                  editProductCtx.productCategorySelectionHandler(category.id)
                }
                editorMode
                onPositionChange={(direction: "UP" | "DOWN") =>
                  positionUpdateHandler(direction, category, index)
                }
              />
            )
          )
        ) : (
          <div
            className={"products-categories-editor__categories-frame__no-state"}
          >
            {i18n.t("AppMenu.NoCategorie")}
          </div>
        )}
      </div>
      <div className="products-categories-editor__controls">
        <BaseButton
          expand
          tight
          margins="0 0 1rem 0"
          disabled={!editProductCtx.productCategoryId}
          onClick={() => openUpdateCategoryModal()}
        >
          {i18n.t("AppMenu.modifyCategory")}
        </BaseButton>
        <BaseButton
          expand
          tight
          margins="0 0 1rem 0"
          onClick={() => openNewCategoryModal()}
        >
          {i18n.t("AppMenu.addNewCategory")}
        </BaseButton>
      </div>
    </div>
  );
};

export default ProductsCategoriesEditor;
