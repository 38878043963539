import Order from "../../../models/Order";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import { useContext, useState } from "react";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import { DeliveryFeesByCity } from "../../../models/RestaurantInfos";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
interface ContainerProps {
  orderContext?: any;
  order?: Order;
  asRestaurantOrder?: boolean;
  updateDeliveryFeesByCity: (
    newDeliveryFeesByCity: DeliveryFeesByCity[]
  ) => void;
  onDelete?: Function;
  onPrint?: Function;
  onDismiss: Function;
  onNextOrder?: Function;
  onPreviousOrder?: Function;
  restaurantDataCtx?: any;

  isMiniCart?: boolean;
  isHistory?: boolean;
}

const DeliveryFeesByCityModal: React.FC<ContainerProps> = (props) => {
  const [availableCities, setAvailableCities] = useState(
    props.restaurantDataCtx.restaurantInfos.availableCitiesOnDelivery || []
  );
  const handleUpdateDeliveryFees = () => {
    props.updateDeliveryFeesByCity(availableCities);
    props.onDismiss();
  };

  const handleChangeDeliveryFees = (event: any, index: number) => {
    const newAvailableCities = [...availableCities];
    newAvailableCities[index].deliveryFees =
      parseFloat(event.target.value) * 100;
    setAvailableCities(newAvailableCities);
  };

  const handleChangeDeliveryFeesTrigger = (event: any, index: number) => {
    const newAvailableCities = [...availableCities];
    newAvailableCities[index].freeDeliveryFeesTrigger =
      parseFloat(event.target.value) * 100;
    setAvailableCities(newAvailableCities);
  };
  return (
    <div
      style={{
        padding: "1rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BaseModalHeader onDismiss={props.onDismiss}>
        {i18n.t("AppSettingsDeliverySettings.cityDeliveryFee")}
      </BaseModalHeader>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          flexGrow: 1,
          marginTop: "1rem",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {props.restaurantDataCtx.restaurantInfos?.availableCitiesOnDelivery?.map(
          (city: DeliveryFeesByCity, index: number) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "20%" }}>
                  <div>{city.name}</div>
                  <div>({city.zipCode})</div>
                </div>
                <div style={{ width: "38%" }}>
                  <BaseTextInput
                    label={`${i18n.t(
                      "AppSettingsDeliverySettings.deliveryFees"
                    )} ${constants.CURRENCY}`}
                    controller={{
                      value:
                        availableCities[index].deliveryFees !== null &&
                        availableCities[index].deliveryFees !== undefined
                          ? availableCities[index].deliveryFees / 100
                          : null,
                      onChange: (event: any) =>
                        handleChangeDeliveryFees(event, index),
                    }}
                  />
                </div>
                <div style={{ width: "38%" }}>
                  <BaseTextInput
                    label={`${i18n.t(
                      "AppSettingsDeliverySettings.deliveryOfferStartingFromInEuro"
                    )} (en ${constants.CURRENCY})`}
                    controller={{
                      value:
                        availableCities[index].freeDeliveryFeesTrigger !==
                          null &&
                        availableCities[index].freeDeliveryFeesTrigger !==
                          undefined
                          ? availableCities[index].freeDeliveryFeesTrigger / 100
                          : null,
                      onChange: (event: any) =>
                        handleChangeDeliveryFeesTrigger(event, index),
                    }}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div>
        <BaseButton onClick={handleUpdateDeliveryFees} expand>
          {i18n.t("AppSettingsRestaurantInformation.saveButton")}
        </BaseButton>
      </div>
    </div>
  );
};

export default DeliveryFeesByCityModal;
