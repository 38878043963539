import { useContext } from "react";
import { Redirect } from "react-router";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import StartChoices from "../../components/Start/StartChoices/StartChoices";
import StartLayout, {
  StartLayoutDispositions,
} from "../../components/Layouts/StartLayout/StartLayout";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import routes from "../../routes";

const StartPage: React.FC = () => {
  const authCtx = useContext(AuthContext);
  return (
    <StartLayout disposition={StartLayoutDispositions.START}>
      {authCtx.authState == AuthStates.NOT_INIT ? (
        <AppLoading />
      ) : authCtx.authState == AuthStates.NOT_AUTH ? (
        <StartChoices />
      ) : authCtx.userRole === "RESTAURANT" ? (
        <Redirect to={routes.ORDERS_PAGE} /> // TODO : Check if user is premium and redirect if not to a special page
      ) : authCtx.userRole === "COURIER" ? (
        <Redirect to={routes.DELIVERIES_PAGE} /> // NOTE : Courier app is always available even when premium is disabled
      ) : authCtx.userRole === "CUSTOMER" ? (
        <StartChoices />
      ) : null}
    </StartLayout>
  );
};

export default StartPage;
