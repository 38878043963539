import { IonIcon, useIonModal } from "@ionic/react";
import { receipt } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import Order from "../../../models/Order";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import OrderItem from "../../Orders/OrderItem/OrderItem";
import { Geolocation } from "@ionic-native/geolocation";
import { GCS } from "../../../services";
import "./DeliveriesList.css";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import OrderDetailModal from "../../Modals/OrderDetailModal/OrderDetailModal";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const DeliveriesList: React.FC<ContainerProps> = (props) => {
  const courierDataCtx = useContext(CourierDataContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [optimizationIsLoading, setOptimizationIsLoading] = useState(false);
  const [optimizedOrders, setOptimizedOrders] = useState([] as Order[]);
  const [displayedOrders, setDisplayedOrders] = useState([] as Order[]);
  const [selectedOrderForDetails, setSelectedOrderForDetails] = useState(
    {} as Order
  );

  const [present, dismiss] = useIonModal(OrderDetailModal, {
    order: selectedOrderForDetails,
    asRestaurantOrder: false,
    restaurantDataCtx: restaurantDataCtx,
    onDismiss: () => {
      dismiss();
    },
  });

  useEffect(() => {
    if (!optimizedOrders.length) {
      setDisplayedOrders([...courierDataCtx.inDeliveryOrdersList]);
    } else {
      const sortedOrders = [...optimizedOrders];
      sortedOrders.sort((a: Order, b: Order) => {
        let aEx = a.optimizedPosition ? a.optimizedPosition : 0;
        let bEx = b.optimizedPosition ? b.optimizedPosition : 0;
        return aEx - bEx;
      });
      setDisplayedOrders(sortedOrders);
    }
  }, [courierDataCtx.inDeliveryOrdersList, optimizedOrders]);

  useEffect(() => {
    setOptimizedOrders([]);
  }, [courierDataCtx.triggerOptimizationReset]);

  useEffect(() => {
    if (optimizedOrders.length) {
      const refreshedOptimizedOrdersList = [...optimizedOrders];
      courierDataCtx.inDeliveryOrdersList.map((order) => {
        refreshedOptimizedOrdersList.map((refreshedOrder) => {
          if (refreshedOrder.id == order.id) {
            refreshedOrder.status = order.status;
          }
        });
      });
      setOptimizedOrders(refreshedOptimizedOrdersList);
    }
  }, [courierDataCtx.triggerOptimizationRefresh]);

  const openOrderDetailHandler = (order: Order) => {
    setSelectedOrderForDetails(order);
    present();
  };

  const optimizationHandler = () => {
    setOptimizationIsLoading(true);
    Geolocation.getCurrentPosition()
      .then((resp) => {
        GCS.getOptimizedDirection(
          {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          },
          courierDataCtx.inDeliveryOrdersList
        )
          .then((response) => {
            setOptimizedOrders(response);
            setOptimizationIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setOptimizationIsLoading(false);
          });
      })
      .catch((e) => {
        console.log(e);
        setOptimizationIsLoading(false);
      });
  };

  return (
    <div
      className={
        courierDataCtx.inDeliveryOrdersList.length
          ? "deliveries-list"
          : "deliveries-list deliveries-list--wno-state"
      }
      data-testid="deliveries-list"
    >
      {!courierDataCtx.inDeliveryOrdersList.length ? (
        <div className="deliveries-list__no-state">
          <span className="deliveries-list__no-state__icon">
            <IonIcon icon={receipt} />
          </span>
          <span className="deliveries-list__no-state__text">
            {i18n.t("AppOrder.OngoingTour")}
          </span>
        </div>
      ) : (
        <>
          <div className="deliveries-list__optimize-button">
            <BaseButton
              expand
              tight
              disabled={
                courierDataCtx.inDeliveryOrdersList.length <= 1 ||
                !!optimizedOrders.length
              }
              onClick={() => optimizationHandler()}
              isLoading={optimizationIsLoading}
              color={!!optimizedOrders.length ? "success" : undefined}
            >
              {!!optimizedOrders.length
                ? i18n.t("AppOrder.OptimizedTour")
                : i18n.t("AppOrder.OptimizedTour2")}
            </BaseButton>
          </div>
          <div className="deliveries-list__orders-list">
            {displayedOrders.map((order: Order) => (
              <OrderItem
                key={order.id}
                status={order.status}
                productsList={order.products}
                customerInfos={order.customer}
                orderNumber={order.number}
                delivered={order.status == "DELIVERED"}
                date={order.createdAt}
                order={order}
                deliveryMode
                onClick={() => openOrderDetailHandler(order)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveriesList;
