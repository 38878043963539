import config from "../../configs/firebase-service.config";
import firebase from "firebase/app";
import { constants } from "../../utils/constants";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = config();

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig.sdkCredentials)
  : firebase.app();

const $firestore = firebase.firestore();
const $firebaseFunctions = app.functions(constants.REGION);
const $firebaseAuth = firebase.auth();
$firebaseAuth.setPersistence(
  process.env.NODE_ENV === "test"
    ? firebase.auth.Auth.Persistence.NONE
    : firebase.auth.Auth.Persistence.LOCAL
);
const $firebaseStorage = firebase.storage();

if (firebaseConfig.envName == "EMU") {
  $firestore.useEmulator(
    firebaseConfig.emulators.firestore.host,
    firebaseConfig.emulators.firestore.port
  );
  $firebaseFunctions.useEmulator(
    firebaseConfig.emulators.functions.host,
    firebaseConfig.emulators.functions.port
  );
  $firebaseAuth.useEmulator(
    `http://${firebaseConfig.emulators.auth.host}:${firebaseConfig.emulators.auth.port}`
  );
  $firebaseStorage.useEmulator(
    firebaseConfig.emulators.storage.host,
    firebaseConfig.emulators.storage.port
  );
} else {
  firebase.analytics();
}

export { $firestore, $firebaseFunctions, $firebaseAuth, $firebaseStorage };
export default $firestore;
