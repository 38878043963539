// TODO ON PROD
// FIXME ! NEED TO RESTRICT API KEY USAGE = https://developers.google.com/maps/documentation/javascript/get-api-key#restrict_key
// When the app domain will be fixed
// NOTE document about waypoints : https://developers.google.com/maps/documentation/directions/get-directions#specify-locations-in-the-waypoints-parameter.
import { useEffect, useState } from "react";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import { Geolocation } from "@ionic-native/geolocation";

import { GCS } from "../../../services";
import "./AddressFetcher.css";
import classNames from "classnames";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  onSelect: Function;
  value: any;
  extended?: boolean;
  onInputChange?: (text: string) => void;
}

const AddressFetcher: React.FC<ContainerProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [addressInput, setAddressInput] = useState("");
  const [deviceGeoCoordinates, setDeviceGeoCoordinates] = useState({
    lat: 46, // Center of france
    lng: 2, // Center of france
  });
  const [deviceGeoCoordinatesIsInit, setDeviceGeoCoordinatesIsInit] =
    useState(false);
  const [placesList, setPlacesList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setPlacesList([]);
    if (!deviceGeoCoordinatesIsInit) {
      Geolocation.getCurrentPosition()
        .then((resp) => {
          setDeviceGeoCoordinates({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          setDeviceGeoCoordinatesIsInit(true);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setDeviceGeoCoordinatesIsInit(true);
          setIsLoading(false);
        });
    }
    const debouncer = setTimeout(() => {
      if (addressInput.length) {
        GCS.getPlacesSuggestions(addressInput, deviceGeoCoordinates).then(
          (list: any) => {
            setPlacesList(list);
            setIsLoading(false);
          }
        );
      } else {
        setPlacesList([]);
        setIsLoading(false);
      }
    }, 1000); // Debounce amount
    return () => {
      clearTimeout(debouncer);
    };
  }, [addressInput]);

  const getResultItemClasses = (placeId: string) => {
    const classes = ["address-fetcher__results-frame__result"];
    if (placeId == props.value.place_id) {
      classes.push("address-fetcher__results-frame__result--is-active");
    }
    return classes.join(" ");
  };

  return (
    <div className="address-fetcher" data-testid="address-fetcher">
      <BaseTextInput
        label={i18n.t("AppSettingsRestaurantInformation.searchAddress")}
        controller={{
          value: addressInput,
          onChange: (event: any) => {
            setAddressInput(event.target.value);
            props?.onInputChange?.(event.target.value);
          },
        }}
      />
      <div
        className={classNames({
          "address-fetcher__results-frame": true,
          "address-fetcher__results-frame--extended": props.extended,
        })}
      >
        {isLoading && (
          <span className="address-fetcher__results-frame__loading">
            {i18n.t("AppSettingsRestaurantInformation.Loading")}
          </span>
        )}
        {!isLoading && !placesList?.length && (
          <span className="address-fetcher__results-frame__no-results">
            {i18n.t("AppSettingsRestaurantInformation.noResultsToShow")}
          </span>
        )}
        {placesList?.map((place: any) => {
          return (
            <div
              key={place.place_id}
              onClick={() => props.onSelect(place)}
              className={getResultItemClasses(place.place_id)}
            >
              {place.description}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddressFetcher;
