import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import {
  bicycle,
  fastFood,
  receipt,
  settings,
  calendarClear,
} from "ionicons/icons";
import { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import CourierDataContext from "../../contexts/courier-data.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import routes from "../../routes";
import CouriersPage from "./CouriersPage/CouriersPage";
import DeliveriesPage from "./DeliveriesPage/DeliveriesPage";
import "./Home.css";
import OrdersPage from "./OrdersPage/OrdersPage";
import ProductionPage from "./ProductionPage/ProductionPage";
import ProductsPage from "./ProductsPage/ProductsPage";
import SettingsPage from "./SettingsPage/SettingsPage";
import i18n from "../../translations/i18n";

interface ContainerProps {}

const restaurantTabsConfig = [
  {
    tab: routes.ORDERS_PAGE,
    href: routes.ORDERS_PAGE,
    icon: receipt,
    label: i18n.t("AppMenu.orders"),
  },
  {
    tab: routes.COURIERS_PAGE,
    href: routes.COURIERS_PAGE,
    icon: bicycle,
    label: i18n.t("AppSettingsRestaurantInformation.DeliveryDriver"),
  },
  {
    tab: routes.PRODUCTS_PAGE,
    href: routes.PRODUCTS_PAGE,
    icon: fastFood,
    label: i18n.t("AppMenu.menu"),
  },
  {
    tab: routes.SETTINGS_PAGE,
    href: routes.SETTINGS_PAGE,
    icon: settings,
    label: i18n.t("AppSettingsRestaurantInformation.settingsHeader"),
  },
  {
    tab: routes.PRODUCTION_PAGE,
    href: routes.PRODUCTION_PAGE,
    icon: calendarClear,
    label: i18n.t("AppSettingsDeliverySettings.Production"),
  },
];

const courierTabsConfig = [
  {
    tab: routes.DELIVERIES_PAGE, // TODO update
    href: routes.DELIVERIES_PAGE, // TODO update
    icon: receipt,
    label: i18n.t("AppOrder.MyTour"),
  },
  {
    tab: routes.SETTINGS_PAGE,
    href: routes.SETTINGS_PAGE,
    icon: settings,
    label: i18n.t("AppSettingsRestaurantInformation.settingsHeader"),
  },
];

const Home: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const courierDataCtx = useContext(CourierDataContext);
  if (
    restaurantDataCtx.restaurantInfos.productionMode &&
    restaurantTabsConfig.findIndex((item) => item.label === "Production") === -1
  ) {
    restaurantTabsConfig.push({
      tab: routes.PRODUCTION_PAGE,
      href: routes.PRODUCTION_PAGE,
      icon: calendarClear,
      label: "Production",
    });
  }
  const tabsConfig =
    authCtx.userRole === "RESTAURANT"
      ? restaurantTabsConfig
      : courierTabsConfig;

  useEffect(() => {
    if (authCtx.restaurantId && authCtx.userRole === "RESTAURANT") {
      restaurantDataCtx.initRestaurantData(authCtx.restaurantId);
    } else if (
      authCtx.restaurantId &&
      authCtx.userRole === "COURIER" &&
      authCtx.userId
    ) {
      courierDataCtx.initCourierData(authCtx.restaurantId, authCtx.userId);
    } else if (authCtx.userRole === "COURIER" && authCtx.userId) {
      courierDataCtx.initCourierData(undefined, authCtx.userId);
    }
  }, [authCtx.restaurantId, authCtx.userId]);

  return authCtx.authState == AuthStates.NOT_INIT ? (
    <AppLoading />
  ) : authCtx.authState == AuthStates.AUTH ? (
    <IonTabs>
      <IonRouterOutlet>
        <Route
          exact
          path={routes.HOME_PAGE}
          render={() => (
            <Redirect
              to={
                authCtx.userRole === "RESTAURANT"
                  ? routes.ORDERS_PAGE
                  : routes.DELIVERIES_PAGE
              }
            />
          )}
        />
        <Route exact path={routes.ORDERS_PAGE}>
          {authCtx.userRole === "RESTAURANT" ? (
            <OrdersPage />
          ) : authCtx.userRole === "COURIER" ? (
            <Redirect to={routes.DELIVERIES_PAGE} />
          ) : null}
        </Route>
        <Route exact path={routes.COURIERS_PAGE}>
          {authCtx.userRole === "RESTAURANT" ? (
            <CouriersPage />
          ) : authCtx.userRole === "COURIER" ? (
            <Redirect to={routes.DELIVERIES_PAGE} />
          ) : null}
        </Route>
        <Route exact path={routes.PRODUCTS_PAGE}>
          {authCtx.userRole === "RESTAURANT" ? (
            <ProductsPage />
          ) : authCtx.userRole === "COURIER" ? (
            <Redirect to={routes.DELIVERIES_PAGE} />
          ) : null}
        </Route>
        <Route exact path={routes.SETTINGS_PAGE}>
          <SettingsPage />
        </Route>
        <Route exact path={routes.DELIVERIES_PAGE}>
          {authCtx.userRole === "COURIER" ? (
            <DeliveriesPage />
          ) : authCtx.userRole === "RESTAURANT" ? (
            <Redirect to={routes.ORDERS_PAGE} />
          ) : null}
        </Route>
        <Route exact path={routes.PRODUCTION_PAGE}>
          {authCtx.userRole === "RESTAURANT" ? (
            <ProductionPage />
          ) : authCtx.userRole === "COURIER" ? (
            <Redirect to={routes.DELIVERIES_PAGE} />
          ) : null}
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        translucent
        className="home-tab-bar"
        data-testid="home-tab-bar"
      >
        {tabsConfig.map((tabItem) =>
          tabItem.href === routes.PRODUCTION_PAGE ? (
            restaurantDataCtx.restaurantInfos.productionMode ? (
              <IonTabButton
                tab={tabItem.tab}
                href={tabItem.href}
                key={tabItem.tab}
              >
                <IonIcon icon={tabItem.icon} />
                <IonLabel>{tabItem.label}</IonLabel>
              </IonTabButton>
            ) : null
          ) : (
            <IonTabButton
              tab={tabItem.tab}
              href={tabItem.href}
              key={tabItem.tab}
            >
              <IonIcon icon={tabItem.icon} />
              <IonLabel>{tabItem.label}</IonLabel>
            </IonTabButton>
          )
        )}
      </IonTabBar>
    </IonTabs>
  ) : (
    <Redirect to={routes.START_PAGE} />
  );
};

export default Home;
