import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import { chevronDown, chevronUp } from "ionicons/icons";
import "./ProductsCategoryChip.css";

interface ContainerProps {
  name?: string;
  color?: string;
  isMuted?: boolean;
  action: Function;
  margin?: string;
  alignStart?: boolean;
  editorMode?: boolean;
  onPositionChange?: Function;
}

const ProductsCategoryChip: React.FC<ContainerProps> = (props) => {
  return (
    <div
      onClick={() => props.action()}
      className={classNames({
        "category-chip": true,
        "category-chip--muted": props.isMuted,
      })}
      style={{
        backgroundColor: props.color ? props.color : "lightgray",
        justifyContent: props.editorMode
          ? "space-between"
          : props.alignStart
          ? "flex-start"
          : "center",
        margin: props.margin,
      }}
      data-testid="category-chip"
    >
      {props.name}
      {props.editorMode && (
        <div className="category-chip__position-controls">
          <IonIcon
            icon={chevronDown}
            onClick={(event) => {
              if (props.onPositionChange) {
                event.stopPropagation();
                props.onPositionChange("DOWN");
              }
            }}
          />
          <IonIcon
            icon={chevronUp}
            onClick={(event) => {
              if (props.onPositionChange) {
                event.stopPropagation();
                props.onPositionChange("UP");
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductsCategoryChip;
