import { useIonAlert } from "@ionic/react";
import React, { useState } from "react";
import CourierInfos from "../models/CourierInfos";
import Order from "../models/Order";
import courierServices from "../services/apis/courierServices";
import deliveryServices from "../services/apis/deliveryServices";
import orderServices from "../services/apis/orderServices";
import { BaseAlert } from "../types";
import { useEffect } from "react";
const CourierDataContext = React.createContext({
  isBusy: false,
  rtListenersList: [] as Function[],
  pendingOrdersList: [] as Order[],
  inDeliveryOrdersList: [] as Order[],
  triggerOptimizationReset: false,
  triggerOptimizationRefresh: false,
  courierInfos: {} as CourierInfos,
  initCourierData: (restaurantId: string | undefined, courierId: string) => {},
  setOrderAsDelivered: (restaurantId: string, orderId: string) => {},
  closeDeliveryHandler: (restaurantId: string) => {},
  dismissListeners: () => {},
});

// NOTE may be adequat to seperate delivery logic in an other context
export const CourierDataContextProvider: React.FC = (props) => {
  // Courier specific
  const [isBusy, setIsBusy] = useState(false);
  const [courierInfos, setCourierInfos] = useState({} as CourierInfos);
  // Orders and delivery specifics
  const [pendingOrdersList, setPendingOrdersList] = useState([] as Order[]);
  const [inDeliveryOrdersList, setInDeliveryOrdersList] = useState(
    [] as Order[]
  );

  const [prevPendingOrdersLength, setPrevPendingOrdersLength] = useState(
    inDeliveryOrdersList.length
  );
  // Listeners list to dismiss
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  // Action triggers
  const [triggerOptimizationReset, setTriggerOptimizationReset] =
    useState(false);
  const [triggerOptimizationRefresh, setTriggerOptimizationRefresh] =
    useState(false);

  // Alert presenter
  const [present] = useIonAlert();

  // On start courier data initializer
  const initCourierData = (
    restaurantId: string | undefined,
    courierId: string
  ) => {
    const rtListenersToAdd = [];
    const courierInfosListener: Function = courierServices.listenToCourierInfos(
      courierId,
      (courierInfos: CourierInfos) => setCourierInfos(courierInfos)
    );
    if (restaurantId) {
      const pendingOrdersListener: Function =
        orderServices.listenToPendingOrders(
          restaurantId,
          (pendingOrders: Order[]) => setPendingOrdersList(pendingOrders)
        );
      const inDeliveryOrdersListener: Function =
        orderServices.listenToInDeliveryOrders(
          restaurantId,
          courierId,
          (inDeliveryOrders: Order[]) =>
            setInDeliveryOrdersList(inDeliveryOrders)
        );
      rtListenersToAdd.push(pendingOrdersListener, inDeliveryOrdersListener);
    }
    // Clean up previous listeners if needed
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([...rtListenersToAdd, courierInfosListener]);
  };

  const dismissListeners = () => {
    rtListenersList.map((listener: Function) => listener());
    return true;
  };

  const playSoundNotif = () => {
    let audio = document.getElementById(
      "order-notif-audio"
    ) as HTMLAudioElement;
    audio.play();
  };

  useEffect(() => {
    if (pendingOrdersList.length > prevPendingOrdersLength) {
      if (pendingOrdersList.length) {
        playSoundNotif();
      }
    }
    setPrevPendingOrdersLength(pendingOrdersList.length);
  }, [pendingOrdersList]);

  const setOrderAsDelivered = (restaurantId: string, orderId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      deliveryServices
        .updateOrderAsDelivered(restaurantId, orderId)
        .then(() => {
          setIsBusy(false);
          setTriggerOptimizationRefresh(!triggerOptimizationRefresh);
        })
        .catch((errorAlert: BaseAlert) => {
          present({
            header: errorAlert.header,
            message: errorAlert.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => setIsBusy(false),
          });
        });
    }
  };

  const closeDeliveryHandler = (restaurantId: string) => {
    if (!isBusy) {
      setIsBusy(true);
      deliveryServices
        .closeDelivery(restaurantId, inDeliveryOrdersList)
        .then((successAlert: BaseAlert) => {
          setIsBusy(false);
          present({
            header: successAlert.header,
            message: successAlert.detail,
            buttons: [
              {
                text: "Ok",
                handler: (d) =>
                  setTriggerOptimizationReset(!triggerOptimizationReset),
              },
            ],
            onDidDismiss: (e) => {},
          });
        })
        .catch((errorAlert: BaseAlert) => {
          setIsBusy(false);
          present({
            header: errorAlert.header,
            message: errorAlert.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {},
          });
        });
    }
  };

  return (
    <CourierDataContext.Provider
      value={{
        isBusy,
        rtListenersList,
        pendingOrdersList,
        inDeliveryOrdersList,
        triggerOptimizationReset,
        triggerOptimizationRefresh,
        courierInfos,
        initCourierData,
        setOrderAsDelivered,
        closeDeliveryHandler,
        dismissListeners,
      }}
    >
      {props.children}
    </CourierDataContext.Provider>
  );
};

export default CourierDataContext;
