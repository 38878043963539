import { IonIcon } from "@ionic/react";
import {
  bicycle,
  cash,
  close,
  person,
  print,
  restaurant,
  receipt,
  mail,
} from "ionicons/icons";
import { useContext, useState } from "react";
import HomeLayout from "../../../components/Layouts/HomeLayout/HomeLayout";
import AccessoriesEditor from "../../../components/Settings/AccessoriesEditor/AccessoriesEditor";
import CourierAccountEditor from "../../../components/Settings/CourierAccountEditor/CourierAccountEditor";
import DeliverySetupEditor from "../../../components/Settings/DeliverySetupEditor/DeliverySetupEditor";
import RestaurantAccountEditor from "../../../components/Settings/RestaurantAccountEditor/RestaurantAccountEditor";
import RestaurantEarnings from "../../../components/Settings/RestaurantEarnings/RestaurantEarnings";
import CourierEarnings from "../../../components/Settings/CourierEarnings/CourierEarnings";
import RestaurantInfosEditor from "../../../components/Settings/RestaurantInfosEditor/RestaurantInfosEditor";
import SettingDoor from "../../../components/Settings/SettingDoor/SettingDoor";
import Advertisement from "../../../components/Settings/Advertisement/Advertisement";
import AuthContext from "../../../contexts/auth.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import RestaurantOrderHistory from "../../../components/Settings/RestaurantOrderHistory/RestaurantOrderHistory";
import { happyBurgerRestaurantId } from "../../../services/apis/orderServices";
import "./SettingsPage.css";
import BaseTextInput from "../../../components/@Base/BaseTextInput/BaseTextInput";
import BaseButton from "../../../components/@Base/BaseButton/BaseButton";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

type SettingDoorSetup = {
  name: string;
  icon: string;
  color: string;
  action: Function;
};

type SettingsPageModes =
  | "MAIN"
  | "RESTAURANT_INFOS"
  | "RESTAURANT_ACCOUNT"
  | "EARNINGS"
  | "COURIER_EARNINGS"
  | "ACCESSORIES_SETUP"
  | "DELIVERY_SETUP"
  | "ORDERS_HISTORY"
  | "COURIER_ACCOUNT"
  | "ADVERTISEMENT";

const SettingsPage: React.FC<ContainerProps> = (props) => {
  const [mode, setMode] = useState("MAIN" as SettingsPageModes);
  const authCtx = useContext(AuthContext);
  const restaurantCtx = useContext(RestaurantDataContext);
  const [restaurantId, setRestaurantId] = useState("");
  const RestaurantSettings: SettingDoorSetup[] = [
    {
      name: i18n.t("AppSettingsRestaurantInformation.restaurantInformation"),
      icon: restaurant,
      color: "#FFFEFA",
      action: () => setMode("RESTAURANT_INFOS"),
    },
    {
      name: i18n.t("AppSettings.accountSettings"),
      icon: person,
      color: "#FAFEFF",
      action: () => setMode("RESTAURANT_ACCOUNT"),
    },
    {
      name: i18n.t("AppSettings.salesSummary"),
      icon: cash,
      color: "#FBFFFA",
      action: () => setMode("EARNINGS"),
    },
    {
      name: i18n.t("AppSettings.courierSalesSummary"),
      icon: cash,
      color: "#FBFFFA",
      action: () => setMode("COURIER_EARNINGS"),
    },
    {
      name: i18n.t("AppSettingsAccessory.accessorySettings"),
      icon: print,
      color: "#FDFAFF",
      action: () => setMode("ACCESSORIES_SETUP"),
    },
    {
      name: i18n.t("AppSettingsDeliverySettings.deliverySettings"),
      icon: bicycle,
      color: "#FFFAFB",
      action: () => setMode("DELIVERY_SETUP"),
    },
    {
      name: i18n.t("AppSettings.orderHistory"),
      icon: receipt,
      color: "#FBFFFA",
      action: () => setMode("ORDERS_HISTORY"),
    },
    // {
    //   name: "Publicité",
    //   icon: mail,
    //   color: "#FBFFFA",
    //   action: () => setMode("ADVERTISEMENT"),
    // },
  ];

  const CourierSettings: SettingDoorSetup[] = [
    {
      name: i18n.t("AppSettingsAccount.CourierAccount"),
      icon: person,
      color: "#FAFEFF",
      action: () => setMode("COURIER_ACCOUNT"),
    },
  ];

  const getSettingTitle = () => {
    switch (mode) {
      case "RESTAURANT_INFOS":
        return RestaurantSettings[0].name;
      case "RESTAURANT_ACCOUNT":
        return RestaurantSettings[1].name;
      case "EARNINGS":
        return RestaurantSettings[2].name;
      case "COURIER_EARNINGS":
        return RestaurantSettings[4].name;
      case "ACCESSORIES_SETUP":
        return RestaurantSettings[4].name;
      case "DELIVERY_SETUP":
        return RestaurantSettings[5].name;
      case "ORDERS_HISTORY":
        return RestaurantSettings[6].name;
      // case "ADVERTISEMENT":
      //   return RestaurantSettings[6].name;
      case "COURIER_ACCOUNT":
        return CourierSettings[0].name;
    }
  };

  const isReadyForDelivery =
    restaurantCtx.restaurantInfos.stripeAccountActive &&
    !restaurantCtx.restaurantInfos.stripeInfoRequired &&
    restaurantCtx.restaurantInfos.stripeAccountId &&
    restaurantCtx.restaurantInfos.name &&
    restaurantCtx.restaurantInfos.phone &&
    restaurantCtx.restaurantInfos.addressFirstLine;

  return (
    <HomeLayout
      title={i18n.t("AppSettingsRestaurantInformation.settingsHeader")}
    >
      {mode === "MAIN" && (
        <div className="settings-page__settings-grid">
          {authCtx.userRole === "RESTAURANT"
            ? RestaurantSettings.map((settingDoor: SettingDoorSetup) => {
                if (
                  settingDoor.name ===
                    i18n.t("AppSettingsDeliverySettings.deliverySettings") &&
                  isReadyForDelivery
                ) {
                  return (
                    <SettingDoor key={settingDoor.name} {...settingDoor} />
                  );
                } else if (
                  settingDoor.name ==
                  i18n.t("AppSettingsDeliverySettings.deliverySettings")
                ) {
                  return null;
                }
                return <SettingDoor key={settingDoor.name} {...settingDoor} />;
              })
            : CourierSettings.map((settingDoor: SettingDoorSetup) => (
                <SettingDoor key={settingDoor.name} {...settingDoor} />
              ))}
          {authCtx.role == "ADMIN" && (
            <div>
              <BaseTextInput
                label="Restaurant ID"
                controller={{
                  value: restaurantId,
                  onChange: (event: any) => {
                    setRestaurantId(event.target.value);
                  },
                }}
              />
              <BaseButton
                expand
                type="button"
                onClick={() => {
                  authCtx.setRestaurantIdhandler(restaurantId);
                }}
              >
                {i18n.t("AppSettingsRestaurantInformation.Validate")}
              </BaseButton>
            </div>
          )}
        </div>
      )}
      {mode !== "MAIN" && (
        <>
          <div className="settings-page__header">
            <span className="settings-page__header__title">
              {getSettingTitle()}
            </span>
            <span
              className="settings-page__header__close-btn"
              onClick={() => setMode("MAIN")}
            >
              <IonIcon icon={close} />
            </span>
          </div>
          <div className="settings-page__content">
            {mode === "RESTAURANT_INFOS" && <RestaurantInfosEditor />}
            {mode === "RESTAURANT_ACCOUNT" && <RestaurantAccountEditor />}
            {mode === "EARNINGS" && <RestaurantEarnings />}
            {mode === "COURIER_EARNINGS" && <CourierEarnings />}
            {mode === "ACCESSORIES_SETUP" && <AccessoriesEditor />}
            {mode === "DELIVERY_SETUP" && <DeliverySetupEditor />}
            {mode === "COURIER_ACCOUNT" && <CourierAccountEditor />}
            {mode === "ORDERS_HISTORY" && <RestaurantOrderHistory />}

            {/* {mode === "ADVERTISEMENT" && <Advertisement />} */}
          </div>
        </>
      )}
    </HomeLayout>
  );
};

export default SettingsPage;
