import { useContext, useState } from "react";
import BaseButton from "../../../components/@Base/BaseButton/BaseButton";
import ContextualLayout from "../../../components/Layouts/ContextualLayout/ContextualLayout";
import OrderItem from "../../../components/Orders/OrderItem/OrderItem";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import Order from "../../../models/Order";
import API from "../../../services";
import routes from "../../../routes";
import "./NewDeliveryPage.css";
import AuthContext from "../../../contexts/auth.ctx";
import Courier from "../../../models/Courier";
import { useHistory } from "react-router";
import { useIonAlert } from "@ionic/react";
import { BaseAlert } from "../../../types";
import deliveryServices from "../../../services/apis/deliveryServices";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const NewDeliveryPage: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const courierDataCtx = useContext(CourierDataContext);
  const history = useHistory();
  const [present] = useIonAlert();
  const [selectedOrders, setSelectedOrders] = useState([] as Order[]);
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);

  const handleOrderSelection = (order: Order) => {
    if (!confirmationInProgress) {
      const existingIndex = selectedOrders.findIndex(
        (aOrder) => order.id == aOrder.id
      );
      if (existingIndex == -1) {
        setSelectedOrders([...selectedOrders, order]);
      } else {
        const newArray = [...selectedOrders];
        newArray.splice(existingIndex, 1);
        setSelectedOrders(newArray);
      }
    }
  };

  const handleOrdersInDeliveryConfirmation = () => {
    // TODO add infos from profile account
    const courier = new Courier(
      authCtx.userId,
      courierDataCtx.courierInfos.firstname,
      courierDataCtx.courierInfos.lastname,
      true,
      courierDataCtx.courierInfos.profilePictureUrl,
      courierDataCtx.courierInfos.profileThumbnailUrl,
      courierDataCtx.courierInfos.phone
    );
    setConfirmationInProgress(true);
    deliveryServices
      .createDelivery(authCtx.restaurantId, courier, selectedOrders)
      .then(() => {
        history.replace(routes.DELIVERIES_PAGE);
      })
      .catch((error: BaseAlert) => {
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => history.replace(routes.DELIVERIES_PAGE),
        });
      });
  };

  return (
    <ContextualLayout
      title={i18n.t("AppOrder.NewTour")}
      backPath={routes.DELIVERIES_PAGE}
    >
      <div className="new-delivery-page__main">
        <div
          className="new-delivery-page__main__orders-frame"
          style={{ opacity: confirmationInProgress ? "0.3" : "1" }}
        >
          {courierDataCtx.pendingOrdersList.length ? (
            courierDataCtx.pendingOrdersList
              .filter((order) => order.type == "DELIVERY")
              .map((order: Order) => (
                <OrderItem
                  key={order.id}
                  status={order.status}
                  productsList={order.products}
                  customerInfos={order.customer}
                  orderNumber={order.number}
                  delivered={order.status == "DELIVERED"}
                  date={order.createdAt}
                  order={order}
                  selected={
                    selectedOrders.findIndex(
                      (aOrder: Order) => aOrder.id == order.id
                    ) != -1
                  }
                  onClick={() => handleOrderSelection(order)}
                />
              ))
          ) : (
            <div className={"new-delivery-page__main__orders-frame__no-state"}>
              {i18n.t("AppOrder.noOrdersReady")}
            </div>
          )}
        </div>
        <div className="new-delivery-page__confirmation-button">
          <BaseButton
            expand
            disabled={!selectedOrders.length}
            isLoading={confirmationInProgress}
            onClick={handleOrdersInDeliveryConfirmation}
          >
            {i18n.t("AppSettingsRestaurantInformation.Confirm")}{" "}
            {selectedOrders.length} {i18n.t("AppMenu.orders")}
          </BaseButton>
        </div>
      </div>
    </ContextualLayout>
  );
};

export default NewDeliveryPage;
