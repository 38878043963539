import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RoleButton from "../../@Shared/RoleButton/RoleButton";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { happyBurgerRestaurantId } from "../../../services/apis/orderServices";
import "./OrdersIsADeliverySelector.css";

interface ContainerProps {}

const OrdersIsADeliverySelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  return (
    <div
      className="orders-is-a-delivery-selector"
      data-testid="orders-is-a-delivery-selector"
    >
      <RoleButton
        role="ON_PLACE"
        selected={newOrderCtx.orderType == "ON_PLACE"}
        onClick={() => newOrderCtx.orderTypeSelectionHandler("ON_PLACE")}
      />
      <RoleButton
        // disabled={!restaurantDataCtx.restaurantInfos.stripeAccountActive}
        role="DELIVERY"
        selected={newOrderCtx.orderType == "DELIVERY"}
        onClick={() => newOrderCtx.orderTypeSelectionHandler("DELIVERY")}
      />
    </div>
  );
};

export default OrdersIsADeliverySelector;
