import classNames from "classnames";
import Order from "../../../models/Order";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import OrdersFullCart from "../../Orders/OrdersFullCart/OrdersFullCart";
import OrderSummary from "../../Orders/OrderSummary/OrderSummary";
import Product from "../../../models/Product";
import { OptionItem } from "../../../models/ProductsOptions";
import OrderProductOptionsModal from "../OrderProductOptionsModal/OrderProductOptionsModal";
import { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { expandOutline } from "ionicons/icons";
import "./OrderDetailModal.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  orderContext?: any;
  order?: Order;
  asRestaurantOrder?: boolean;
  onDelete?: Function;
  onPrint?: Function;
  onDismiss: Function;
  onNextOrder?: Function;
  onPreviousOrder?: Function;
  restaurantDataCtx?: any;
  isMiniCart?: boolean;
  isHistory?: boolean;
}

let link: any = document.createElement("link");
const OrderDetailModal: React.FC<ContainerProps> = (props) => {
  const [optionsChangeModalIsOn, setOptionsChangeModalIsOn] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [modifPendingProduct, setModifPendingProduct] = useState({} as Product);
  const [modifCurrentIndex, setModifCurrentIndex] = useState(-1);

  useEffect(() => {
    var head = document.head;
    if (isFullScreen) {
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = "/css/OrderDetailModalWrapper.css";

      head.appendChild(link);
      return () => {
        if (link.parentElement == head) {
          head.removeChild(link);
        }
      };
    } else {
      if (link.parentElement == head) {
        head.removeChild(link);
      }
    }
  }, [isFullScreen]);
  const productModifHandler = (
    product: Product,
    index: number,
    quantity: number,
    options?: OptionItem[]
  ) => {
    if (modifCurrentIndex !== index) {
      setModifCurrentIndex(index);
    }
    if (product.hasOptions && options) {
      const priceWithOptions = options
        .map((option) => option.price)
        .reduce((prev, curr) => {
          return prev + curr;
        }, product.basePrice);
      const productWithOptions = new Product(
        product.id,
        product.name,
        priceWithOptions * quantity,
        product.basePrice,
        product.categoryId,
        product.hasOptions,
        product.pictureUrl,
        product.thumbnailUrl,
        quantity,
        product.description,
        product.productsOptionsIds,
        options,
        product.unavailable,
        product.longDesc
      );
      props.orderContext.modifProductCart(
        productWithOptions,
        modifCurrentIndex
      );
      setModifPendingProduct({} as Product);
      setOptionsChangeModalIsOn(false);
    } else {
      if (product.hasOptions) {
        setModifPendingProduct(product);
        setOptionsChangeModalIsOn(true);
      }
    }
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  return (
    <>
      {props.isMiniCart ? (
        <OrderProductOptionsModal
          isOpen={optionsChangeModalIsOn}
          onDismiss={() => setOptionsChangeModalIsOn(false)}
          product={modifPendingProduct}
          restaurantDataCtx={props.restaurantDataCtx}
          onOptionSubmission={(
            product: Product,
            quantity: number,
            options: OptionItem[]
          ) =>
            productModifHandler(product, modifCurrentIndex, quantity, options)
          }
          isModif={true}
        />
      ) : null}

      <div className="order-detail-modal" data-testid="order-detail-modal">
        <BaseModalHeader
          onDismiss={props.onDismiss}
          expandIcon
          expandAction={handleFullScreen}
          isFullScreen={isFullScreen}
        >
          {i18n.t("AppOrder.OrderDetails")}
        </BaseModalHeader>
        {props.orderContext && (
          <div className="order-detail-modal__cart">
            <OrdersFullCart
              externalContext={props.orderContext}
              onItemSelect={(
                product: Product,
                index: number,
                quantity: number
              ) =>
                productModifHandler(product, index, product.quantity, undefined)
              }
            />
          </div>
        )}
        {props.order && (
          <div
            className={classNames({
              "order-detail-modal__cart": true,
              "order-detail-modal__cart--as-restaurant":
                props.asRestaurantOrder,
            })}
          >
            <OrderSummary
              isFullScreen={isFullScreen}
              order={props.order}
              isHistory={props.isHistory}
              onDelete={() => {
                if (props.onDelete) {
                  props.onDelete(props.order);
                }
              }}
              restaurantDataCtx={props.restaurantDataCtx}
              onPrint={() => {
                if (props.onPrint) {
                  props.onPrint(props.order);
                }
              }}
              asRestaurantDisplay={props.asRestaurantOrder}
              onNextOrder={props.onNextOrder}
              onPreviousOrder={props.onPreviousOrder}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OrderDetailModal;
