import BaseForm from "../../@Base/BaseForm/BaseForm";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import ProductsCategoryColorSelector from "../../Products/ProductsCategoryColorSelector/ProductsCategoryColorSelector";
import "./CategoryEditionModal.css";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { InputSchema } from "../../../types";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  editCategoryCtx: {
    isLoading: boolean;
    editMode: string;
    setAsUpdate: (idToUpdate: string) => void;
    formSchema: InputSchema[];
    categoryToUpdateId: string;
    categoryName: string;
    categoryNamePrefillHandler: (name: string) => void;
    categoryColor: string;
    categoryColorSelectionHandler: (color: string) => void;
    formSubmitHandler: (name: string) => void;
    deleteCategoryHandler: () => void;
    resetContext: () => void;
  };
  onDismiss: Function;
}

const CategoryEditionModal: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="category-edition-modal"
      data-testid="category-edition-modal"
    >
      <BaseModalHeader onDismiss={props.onDismiss}>
        {props.editCategoryCtx.editMode == "UPDATE"
          ? i18n.t("AppMenu.modifyCategory")
          : i18n.t("AppMenu.addNewCategory")}
      </BaseModalHeader>
      <div className="category-edition-modal__color-selector">
        <ProductsCategoryColorSelector
          onSelect={props.editCategoryCtx.categoryColorSelectionHandler}
          selectedColor={props.editCategoryCtx.categoryColor}
        />
      </div>
      <div className="category-edition-modal__form">
        <BaseForm
          onSubmit={(data: { name: string }) =>
            props.editCategoryCtx.formSubmitHandler(data.name)
          }
          schema={props.editCategoryCtx.formSchema}
          prefill={
            props.editCategoryCtx
              ? { name: props.editCategoryCtx.categoryName }
              : undefined
          }
          tightButton
          submitDisabled={!props.editCategoryCtx.categoryColor}
        />
      </div>
      <div className="category-edition-modal__delete-button">
        {props.editCategoryCtx.editMode == "UPDATE" && (
          <BaseButton
            tight
            expand
            outline
            onClick={props.editCategoryCtx.deleteCategoryHandler}
          >
            {i18n.t("AppMenu.DeleteCategory")}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default CategoryEditionModal;
