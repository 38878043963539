import ProductsOptions from "../../models/ProductsOptions";
import { SuccessAlert } from "../../types";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const productOptionsSevices = {
  listenToProductsOptions(restaurantId: string, listenerCallback: Function) {
    const productsOptionsListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("products_options")
      .onSnapshot((querySnasphot) => {
        let productsOptions: ProductsOptions[] = [];
        querySnasphot.forEach((doc) => {
          productsOptions.push(
            new ProductsOptions(
              doc.id,
              doc.data().name,
              doc.data().type,
              doc.data().option_items,
              doc.data().is_mandatory,
              doc.data().color
            )
          );
        });
        listenerCallback(productsOptions);
      });
    return productsOptionsListener;
  },
  createNewProductsOptions(
    restaurantId: string,
    productsOptions: ProductsOptions
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const generatedId = uuidv4();
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("products_options")
        .doc(generatedId)
        .set({
          name: productsOptions.name,
          type: productsOptions.type,
          option_items: productsOptions.optionItems,
          color: productsOptions.color,
          is_mandatory: productsOptions.isMandatory,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          res(success.PRODUCTS_OPTIONS_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PRODUCTS_OPTIONS_CREATION_ERROR_ALERT);
        });
    });
  },
  updateProductsOptions(
    productsOptionsToUpdateId: string,
    restaurantId: string,
    productsOptions: ProductsOptions
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("products_options")
        .doc(productsOptionsToUpdateId)
        .set(
          {
            name: productsOptions.name,
            type: productsOptions.type,
            option_items: productsOptions.optionItems,
            color: productsOptions.color,
            is_mandatory: productsOptions.isMandatory,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.PRODUCTS_OPTIONS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PRODUCTS_OPTIONS_UPDATE_ERROR_ALERT);
        });
    });
  },
  deleteProductsOptions(
    productsOptionsToDeleteId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("products_options")
        .doc(productsOptionsToDeleteId)
        .delete()
        .then(() => {
          return $firestore
            .collection("restaurants")
            .doc(restaurantId)
            .collection("products")
            .where(
              "products_options_ids",
              "array-contains",
              productsOptionsToDeleteId
            )
            .get();
        })
        .then(async (data) => {
          if (!data.empty) {
            for (const productSnap of data.docs) {
              const newProductsOptionsIds = productSnap
                .data()
                .products_options_ids.filter(
                  (optionId: string) => optionId !== productsOptionsToDeleteId
                );
              await $firestore
                .collection("restaurants")
                .doc(restaurantId)
                .collection("products")
                .doc(productSnap.id)
                .update({ products_options_ids: newProductsOptionsIds });
            }
          }

          res(success.PRODUCTS_OPTIONS_DELETE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PRODUCTS_OPTIONS_DELETE_ERROR_ALERT);
        });
    });
  },
};
export default productOptionsSevices;
