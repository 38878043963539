import { useContext } from "react";
import SignInContext from "../../../contexts/sign-in.ctx";
import BaseForm from "../../@Base/BaseForm/BaseForm";
import "./SignInForm.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

export const LOGO_ICON_MODE = true;

const SignInForm: React.FC<ContainerProps> = (props) => {
  const signInCtx = useContext(SignInContext);

  return (
    <section className="signin-form" data-testid="signin-form">
      <BaseForm
        schema={signInCtx.formSchema}
        onSubmit={(data: Record<string, any>) =>
          signInCtx.formSubmitHandler(data.email, data.password)
        }
        submitText={i18n.t("AppSettingsAccount.SignIn")}
        isBusy={signInCtx.isBusy}
        title={i18n.t("AppSettingsAccount.Credentials")}
      />
    </section>
  );
};

export default SignInForm;
