// REVIEW LOADING AND BUSY STATE DONE
import Courier from "../../../models/Courier";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import API from "../../../services";
import ProfilePicturePlaceholder from "../../../assets/images/profile-picture-placeholder.png";
import "./CourierItem.css";
import { useContext, useState } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import { IonImg, useIonAlert } from "@ionic/react";
import { BaseAlert } from "../../../types";
import courierServices from "../../../services/apis/courierServices";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  courier: Courier;
}

const CourierItem: React.FC<ContainerProps> = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const authCtx = useContext(AuthContext);
  const [present] = useIonAlert();

  const deletePendingCourier = () => {
    setIsBusy(true);
    courierServices
      .deletePendingCourier(authCtx.restaurantId, props.courier.id)
      .then((success: BaseAlert) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {},
            },
          ],
          onDidDismiss: (e) => {
            setIsBusy(false);
          },
        });
      })
      .catch((error: BaseAlert) => {
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {},
            },
          ],
          onDidDismiss: (e) => {
            setIsBusy(false);
          },
        });
      });
  };

  const acceptPendingCourier = () => {
    setIsBusy(true);
    courierServices
      .createCourier(authCtx.restaurantId, props.courier)
      .then((success: BaseAlert) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {},
            },
          ],
          onDidDismiss: (e) => {
            setIsBusy(false);
          },
        });
      })
      .catch((error: BaseAlert) => {
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {},
            },
          ],
          onDidDismiss: (e) => {
            setIsBusy(false);
          },
        });
      });
  };

  const deleteCourier = () => {
    setIsBusy(true);
    present({
      header: i18n.t("AppSettingsOrderHistory.EraseDeliveryDriver"),
      message: i18n.t("AppSettingsOrderHistory.EraseVerification"),
      buttons: [
        {
          text: i18n.t("AppSettingsOrderHistory.cancelled"),
          handler: (d) => {},
        },
        {
          text: "Ok",
          handler: (d) => {
            courierServices
              .deleteCourier(authCtx.restaurantId, props.courier)
              .then((success: BaseAlert) => {
                present({
                  header: success.header,
                  message: success.detail,
                  buttons: [
                    {
                      text: "Ok",
                      handler: (d) => {},
                    },
                  ],
                  onDidDismiss: (e) => {
                    setIsBusy(false);
                  },
                });
              })
              .catch((error: BaseAlert) => {
                console.log(error);
                present({
                  header: error.header,
                  message: error.detail,
                  buttons: [
                    {
                      text: "Ok",
                      handler: (d) => {},
                    },
                  ],
                  onDidDismiss: (e) => {
                    setIsBusy(false);
                  },
                });
              });
          },
        },
      ],
      onDidDismiss: (e) => {
        setIsBusy(false);
      },
    });
  };

  return (
    <div className="courier-item" data-testid="courier-item">
      <div className="courier-item__avatar">
        <IonImg
          src={
            props.courier.profileThumbnailUrl
              ? props.courier.profileThumbnailUrl
              : ProfilePicturePlaceholder
          }
        />
      </div>
      <div className="courier-item__infos">
        <span>{props.courier.firstname || "-"}</span>
        <span>{props.courier.lastname || "-"}</span>
      </div>
      <div className="courier-item__actions">
        {props.courier.isPending ? (
          <div className="courier-item__actions__action-buttons">
            <BaseButton
              isLoading={isBusy}
              tight
              expand
              onClick={acceptPendingCourier}
            >
              {i18n.t("AppSettingsOrderHistory.accept")}
            </BaseButton>
            <BaseButton
              isLoading={isBusy}
              tight
              outline
              expand
              onClick={deletePendingCourier}
            >
              {i18n.t("AppSettingsOrderHistory.refuse")}
            </BaseButton>
          </div>
        ) : (
          <div className="courier-item__actions__action-buttons">
            <BaseButton
              isLoading={isBusy}
              color="danger"
              tight
              expand
              onClick={deleteCourier}
            >
              {i18n.t("AppSettingsRestaurantInformation.deleteButton")}
            </BaseButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourierItem;
