import Order from "../../../models/Order";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import { useState } from "react";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import { DeliveryFeesByCity } from "../../../models/RestaurantInfos";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import { PromoCode, PromoCodeType } from "../../../types";
import { useEffect } from "react";
import restaurantServices from "../../../services/apis/restaurantServices";
import { useIonAlert } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { trashBinOutline } from "ionicons/icons";
import { customToFixed } from "../../../utils/customTofixed";
interface ContainerProps {
  orderContext?: any;
  order?: Order;
  asRestaurantOrder?: boolean;
  updateDeliveryFeesByCity: (
    newDeliveryFeesByCity: DeliveryFeesByCity[]
  ) => void;
  onDelete?: Function;
  onPrint?: Function;
  onDismiss: Function;
  onNextOrder?: Function;
  onPreviousOrder?: Function;
  restaurantDataCtx?: any;

  isMiniCart?: boolean;
  isHistory?: boolean;
}

const TipItem = ({
  type,
  selectedType,
  text,
  setSelectedType,
}: {
  type: PromoCodeType;
  selectedType: PromoCodeType | undefined;
  text: string;
  setSelectedType: (type: PromoCodeType) => void;
}) => {
  const [hovered, setHovered] = useState(false);
  const onClick = () => {
    setSelectedType(type);
  };
  return (
    <div
      style={{
        width: "30%",
        height: 50,
        color: "#fff",
        padding: "0.5rem",
        backgroundColor: selectedType === type ? "#fe8f2e" : "#364051",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        cursor: "pointer",
        opacity: selectedType === type || !hovered ? 1 : 0.8,
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div style={{ fontSize: 14, fontWeight: "bold" }}>{text}</div>
    </div>
  );
};

const PromoCodeEditor: React.FC<ContainerProps> = (props) => {
  const [promoCodeName, setPromoCodeName] = useState("");
  const [promoCodeType, setPromoCodeType] = useState<PromoCodeType>("fixed");
  const [promoCodeAmount, setPromoCodeAmount] = useState(0);
  const [promoUsageCount, setPromoUsageCount] = useState(0);
  const [promoCodeMiniAmount, setPromoCodeMiniAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [present, dismiss] = useIonAlert();
  useEffect(() => {
    var head = document.head;
    let link: any = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/OrderDetailModalWrapper.css";

    head.appendChild(link);
    return () => {
      if (link.parentElement === head) {
        head.removeChild(link);
      }
    };
  }, []);

  const resetInputs = () => {
    setPromoCodeName("");
    setPromoCodeAmount(0);
    setPromoUsageCount(0);
    setPromoCodeMiniAmount(0);
    setPromoCodeType("fixed");
  };
  const addCouponCode = async () => {
    try {
      if (!isLoading && promoCodeName) {
        setIsLoading(true);
        const success = await restaurantServices.addCoupon(
          props.restaurantDataCtx.restaurantInfos.id,
          {
            code: promoCodeName,
            amount:
              promoCodeType === "fixed"
                ? Math.round(promoCodeAmount * 100)
                : promoCodeAmount,
            type: promoCodeType,
            minimumOrderAmount: Math.round(promoCodeMiniAmount * 100),
            promoUsageCount: promoUsageCount,
          }
        );
        resetInputs();
        present({
          header: success.header,
          message: success.detail,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {},
            },
          ],
          onDidDismiss: (e) => {},
        });
        setIsLoading(false);
      }
    } catch (err: any) {
      present({
        header: err.header,
        message: err.detail,
        buttons: [
          {
            text: "Ok",
            handler: (d) => {},
          },
        ],
        onDidDismiss: (e) => {},
      });
      setIsLoading(false);
    }
  };

  const deleteCouponCode = async (id: string) => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        await restaurantServices.deleteCoupon(
          props.restaurantDataCtx.restaurantInfos.id,
          id
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        padding: "2rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <BaseModalHeader onDismiss={props.onDismiss} isFullScreen>
        {i18n.t("AppSettingsDeliverySettings.discount")}
      </BaseModalHeader>
      <div>
        <div>
          <label
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 18,
              fontWeight: "bold",
              padding: "0.75rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.promoCodeName")}
          </label>
          <BaseTextInput
            label={i18n.t("AppSettingsDeliverySettings.enterPromoCodeName")}
            controller={{
              value: promoCodeName,
              onChange: (event: any) => {
                setPromoCodeName(event.target.value);
              },
            }}
          />
        </div>
        <div style={{ margin: "1.5rem 0" }}>
          <label
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 18,
              fontWeight: "bold",
              padding: "0.75rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.promoCodeType")}
          </label>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0.75rem",
            }}
          >
            {" "}
            <TipItem
              type={"fixed"}
              selectedType={promoCodeType as PromoCodeType}
              setSelectedType={(type: PromoCodeType) => setPromoCodeType(type)}
              text={i18n.t("AppSettingsDeliverySettings.fixedPrice")}
            />
            <TipItem
              type={"free_delivery"}
              selectedType={promoCodeType as PromoCodeType}
              setSelectedType={(type: PromoCodeType) => setPromoCodeType(type)}
              text={i18n.t("AppSettingsDeliverySettings.freeDelivery")}
            />
            <TipItem
              type={"percent"}
              selectedType={promoCodeType as PromoCodeType}
              setSelectedType={(type: PromoCodeType) => setPromoCodeType(type)}
              text={i18n.t("AppSettingsDeliverySettings.percentage")}
            />
          </div>
        </div>
        {promoCodeType === "fixed" || promoCodeType === "percent" ? (
          <div style={{ margin: "1.5rem 0" }}>
            <div
              style={{
                color: "var(--ion-color-secondary)",
                fontSize: 14,
                padding: "0.25rem 0",
                fontWeight: "bold",
              }}
            >
              {promoCodeType === "fixed"
                ? i18n.t("AppSettingsDeliverySettings.value")
                : i18n.t("AppSettingsDeliverySettings.percentage")}
            </div>
            <BaseTextInput
              label={i18n.t("AppSettingsDeliverySettings.amount")}
              rightText={promoCodeType === "fixed" ? constants.CURRENCY : "%"}
              controller={{
                type: "number",
                step: "0.01",
                min: "0",
                value: promoCodeAmount,
                onChange: (event: any) => {
                  setPromoCodeAmount(parseFloat(event.target.value));
                },
              }}
            />
          </div>
        ) : null}
        <div style={{ margin: "1.5rem 0" }}>
          <label
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 18,
              fontWeight: "bold",
              padding: "0.75rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.minimumOrderToUse")}
          </label>
          <div
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 14,
              fontStyle: "italic",
              padding: "0.25rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.keep0IfNoMini")}
          </div>
          <BaseTextInput
            type="number"
            label={i18n.t("AppSettingsDeliverySettings.amount")}
            controller={{
              type: "number",
              step: "0.01",
              min: "0",
              value: promoCodeMiniAmount,
              onChange: (event: any) => {
                setPromoCodeMiniAmount(parseFloat(event.target.value));
              },
            }}
          />
        </div>
        <div style={{ margin: "1.5rem 0" }}>
          <label
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 18,
              fontWeight: "bold",
              padding: "0.75rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.maxUsage")}
          </label>
          <div
            style={{
              color: "var(--ion-color-secondary)",
              fontSize: 14,
              fontStyle: "italic",
              padding: "0.25rem 0",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.keep0IfNoLimit")}
          </div>
          <BaseTextInput
            type="number"
            label={i18n.t("AppSettingsDeliverySettings.amount")}
            controller={{
              type: "number",
              step: "1",
              min: "0",
              value: promoUsageCount,
              onChange: (event: any) => {
                setPromoUsageCount(parseInt(event.target.value));
              },
            }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <BaseButton expand onClick={addCouponCode} isLoading={isLoading}>
            {i18n.t("AppSettingsDeliverySettings.createMyCoupon")}
          </BaseButton>
        </div>
        <div style={{ margin: "2rem 0" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
              color: "#374051",
            }}
          >
            {i18n.t("AppSettingsDeliverySettings.myCoupons")} :
          </div>
          <div
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "33%",
                fontSize: 14,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
              }}
            >
              {i18n.t("AppSettingsDeliverySettings.name")}
            </div>
            <div
              style={{
                width: "30%",
                fontSize: 14,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
              }}
            >
              {i18n.t("AppSettingsDeliverySettings.promo")}
            </div>
            <div
              style={{
                width: "20%",
                fontSize: 14,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
              }}
            >
              {i18n.t("AppSettingsDeliverySettings.maximumUse")}
            </div>
            <div
              style={{
                width: "15%",
                fontSize: 14,
                fontWeight: "bold",
                color: "var(--ion-color-secondary)",
              }}
            ></div>
          </div>
          <div style={{ maxHeight: 200, overflowY: "auto" }}>
            {props.restaurantDataCtx.promoCodes.map(
              (promoCode: PromoCode & { id: string }) => {
                return (
                  <div
                    key={promoCode.id}
                    style={{
                      margin: "0.5rem",
                      padding: "0.75rem 1rem",
                      borderStyle: "solid",
                      borderRadius: 8,
                      borderWidth: 1.5,
                      borderColor: "#ababab",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "33%",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      {promoCode.code}
                    </div>
                    <div
                      style={{
                        width: "30%",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      {promoCode.type === "free_delivery" ? (
                        i18n.t("AppSettingsDeliverySettings.freeDelivery")
                      ) : promoCode.type === "fixed" ? (
                        <div>
                          {promoCode.amount
                            ? `${customToFixed(promoCode.amount / 100)} ${
                                constants.CURRENCY
                              }`
                            : null}
                        </div>
                      ) : (
                        <div>{promoCode.amount} %</div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "20%",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "var(--ion-color-secondary)",
                      }}
                    >
                      {promoCode.promoUsageCount}
                    </div>
                    <div
                      style={{
                        width: "15%",
                        fontSize: 14,
                        textAlign: "end",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        present({
                          header: i18n.t("AppSettingsDeliverySettings.Warning"),
                          message: i18n.t(
                            "AppSettingsDeliverySettings.confirmDeleteCoupon"
                          ),
                          buttons: [
                            {
                              text: i18n.t(
                                "AppSettingsRestaurantInformation.Confirm"
                              ),
                              handler: (d) => {
                                deleteCouponCode(promoCode.id);
                              },
                            },
                            {
                              text: i18n.t(
                                "AppSettingsDeliverySettings.cancel"
                              ),
                              handler: (d) => dismiss(),
                            },
                          ],
                          onDidDismiss: (e) => {},
                        })
                      }
                    >
                      <IonIcon
                        icon={trashBinOutline}
                        style={{
                          width: 20,
                          height: 20,
                          color: "var(--ion-color-primary)",
                        }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromoCodeEditor;
