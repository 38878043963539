import { IonIcon, IonImg } from "@ionic/react";
import { card, cash } from "ionicons/icons";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import "./OrdersPaymentTypeSelector.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersPaymentTypeSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);

  const getSelectorClasses = (paymentType: "CARD" | "CASH") => {
    const base = ["orders-payment-type-selector__selector"];
    if (newOrderCtx.paymentType == paymentType) {
      base.push("orders-payment-type-selector__selector--active");
    }
    return base.join(" ");
  };

  return (
    <div
      className="orders-payment-type-selector"
      data-testid="orders-payment-type-selector"
    >
      <div
        className={getSelectorClasses("CARD")}
        onClick={() => newOrderCtx.paymentTypeSelectionHandler("CARD")}
        data-testid="orders-payment-type-selector__selector"
      >
        <div className="orders-payment-type-selector__selector__icon">
          <IonIcon icon={card} />
        </div>
        <span className="orders-payment-type-selector__selector__label">
          {i18n.t("AppOrder.paymentByCard")}
        </span>
      </div>
      <div
        className={getSelectorClasses("CASH")}
        onClick={() => newOrderCtx.paymentTypeSelectionHandler("CASH")}
        data-testid="orders-payment-type-selector__selector"
      >
        <div className="orders-payment-type-selector__selector__icon">
          <IonIcon icon={cash} />
        </div>
        <span className="orders-payment-type-selector__selector__label">
          {i18n.t("AppOrder.paymentInCash")}
        </span>
      </div>
    </div>
  );
};

export default OrdersPaymentTypeSelector;
