import Product from "../../models/Product";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { v4 as uuidv4 } from "uuid";
import { SuccessAlert } from "../../types";
import storageServices from "./storageServices";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const productServices = {
  listenToProducts(restaurantId: string, listenerCallback: Function) {
    const productsListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("products")
      .onSnapshot((querySnasphot) => {
        let products: Product[] = [];
        querySnasphot.forEach((doc) => {
          products.push(
            new Product(
              doc.id,
              doc.data().name,
              doc.data().price,
              doc.data().price,
              doc.data().category_id,
              doc.data().has_options,
              doc.data().picture_url,
              doc.data().thumbnail_url,
              1,
              doc.data().description,
              doc.data().products_options_ids,
              [],
              doc.data().unavailable,
              doc.data().long_desc
            )
          );
        });
        listenerCallback(products);
      });
    return productsListener;
  },
  createNewProduct(
    restaurantId: string,
    productCategoryId: string,
    productName: string,
    productDescription: string,
    productPrice: number,
    productIsUnavailable: boolean,
    productImageFile: File,
    productHasOptions: boolean,
    productsOptionsIds?: string[],
    productLongDesc?: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const generatedId = uuidv4();
      if (productImageFile?.size != 0) {
        const productImageDirPath = `restaurants/${restaurantId}/products/${generatedId}`;
        storageServices
          .uploadImageAndThumbnail(productImageDirPath, productImageFile)
          .then((imagePaths) => {
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("products")
              .doc(generatedId)
              .set({
                category_id: productCategoryId,
                has_options: productHasOptions || false,
                name: productName,
                description: productDescription,
                price: productPrice * 100,
                picture_url: imagePaths.imageDownloadUrl,
                picture_path: imagePaths.imagePath,
                thumbnail_url: imagePaths.thumbnailDownloadUrl,
                thumbnail_path: imagePaths.thumbnailPath,
                unavailable: productIsUnavailable,
                products_options_ids: productsOptionsIds || [],
                long_desc: productLongDesc || null,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => {
                res(success.PRODUCT_CREATION_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.PRODUCT_CREATION_ERROR_ALERT);
              });
          })
          .catch((e) => {
            console.log(e);
            rej(errors.PRODUCT_CREATION_ERROR_ALERT);
          });
      } else {
        $firestore
          .collection("restaurants")
          .doc(restaurantId)
          .collection("products")
          .doc(generatedId)
          .set({
            category_id: productCategoryId,
            has_options: productHasOptions || false,
            name: productName,
            description: productDescription,
            price: productPrice * 100,
            picture_url: "",
            picture_path: "",
            thumbnail_url: "",
            thumbnail_path: "",
            unavailable: productIsUnavailable,
            products_options_ids: productsOptionsIds || [],
            long_desc: productLongDesc || null,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            res(success.PRODUCT_CREATION_SUCCESS_ALERT);
          })
          .catch((e) => {
            console.log(e);
            rej(errors.PRODUCT_CREATION_ERROR_ALERT);
          });
      }
    });
  },
  updateProduct(
    productToUpdateId: string,
    restaurantId: string,
    productCategoryId: string,
    productName: string,
    productDescription: string,
    productPrice: number,
    productIsUnavailable: boolean,
    productImageFile: File,
    productHasOptions: boolean,
    productsOptionsIds?: string[],
    productLongDesc?: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      if (productImageFile?.size != 0) {
        const productImageDirPath = `restaurants/${restaurantId}/products/${productToUpdateId}`;
        storageServices
          .uploadImageAndThumbnail(productImageDirPath, productImageFile)
          .then((imagePaths) => {
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("products")
              .doc(productToUpdateId)
              .set(
                {
                  category_id: productCategoryId,
                  has_options: productHasOptions || false,
                  name: productName,
                  description: productDescription,
                  price: productPrice * 100,
                  picture_url: imagePaths.imageDownloadUrl,
                  picture_path: imagePaths.imagePath,
                  thumbnail_url: imagePaths.thumbnailDownloadUrl,
                  thumbnail_path: imagePaths.thumbnailPath,
                  unavailable: productIsUnavailable,
                  products_options_ids: productsOptionsIds || [],
                  long_desc: productLongDesc || null,
                  updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              )
              .then(() => {
                res(success.PRODUCT_UPDATE_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.PRODUCT_UPDATE_ERROR_ALERT);
              });
          })
          .catch((e) => {
            console.log(e);
            rej(errors.PRODUCT_UPDATE_ERROR_ALERT);
          });
      } else {
        $firestore
          .collection("restaurants")
          .doc(restaurantId)
          .collection("products")
          .doc(productToUpdateId)
          .set(
            {
              category_id: productCategoryId,
              has_options: productHasOptions || false,
              name: productName,
              description: productDescription,
              price: productPrice * 100,
              unavailable: productIsUnavailable,
              products_options_ids: productsOptionsIds || [],
              long_desc: productLongDesc || null,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          )
          .then(() => {
            res(success.PRODUCT_UPDATE_SUCCESS_ALERT);
          })
          .catch((e) => {
            console.log(e);
            rej(errors.PRODUCT_UPDATE_ERROR_ALERT);
          });
      }
    });
  },
  deleteProduct(
    productId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("products")
        .doc(productId)
        .delete()
        .then(() => {
          res(success.PRODUCT_DELETE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PRODUCT_DELETE_ERROR_ALERT);
        });
    });
  },
};
export default productServices;
