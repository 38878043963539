import React, { useContext, useState } from "react";
import editCategoryFormSchema from "../forms/edit-category.json";
import { BaseAlert, InputSchema } from "../types";
import AuthContext from "./auth.ctx";
import { useIonAlert } from "@ionic/react";
import infos from "../messages/infos";
import EditProductContext from "./edit-product.ctx";
import RestaurantDataContext from "./restaurant-data.ctx";
import productCategoryServices from "../services/apis/productCategoryServices";
import i18n from "../translations/i18n";

const formSchema = editCategoryFormSchema as InputSchema[];

const EditCategoryContext = React.createContext({
  isBusy: false,
  editMode: "ADD",
  formSchema,
  categoryToUpdateId: "",
  categoryName: "",
  categoryColor: "",
  setAsUpdate: (idToUpdate: string) => {},
  categoryNamePrefillHandler: (name: string) => {},
  categoryColorSelectionHandler: (color: string) => {},
  formSubmitHandler: (name: string) => {},
  deleteCategoryHandler: () => {},
  resetContext: () => {},
  modalClosingTrigger: false,
});

export const EditCategoryContextProvider: React.FC = (props) => {
  const [isBusy, setIsBusy] = useState(false);

  //Auth ctx is used to access restaurant ID
  const authCtx = useContext(AuthContext);
  // Edit product ctx is needed because category creation is directly linked to product edition
  const editProductCtx = useContext(EditProductContext);
  // Restaurant data ctx is needed because it contains stored categories
  const restaurantDataCtx = useContext(RestaurantDataContext);

  // Edit mode can be ADD or EDIT, either to create or update a category
  const [editMode, setEditMode] = useState("ADD");

  // Data binders
  const [categoryToUpdateId, setCategoryToUpdateId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryColor, setCategoryColor] = useState("");

  // Modal and alert managers
  const [modalClosingTrigger, setModalClosingTrigger] = useState(false);
  const [present] = useIonAlert();

  // Utility methods
  const setAsUpdate = (idToUpdate: string) => {
    setCategoryToUpdateId(idToUpdate);
    setEditMode("UPDATE");
  };
  const toggleModalClosingTrigger = () => {
    setModalClosingTrigger(!modalClosingTrigger);
  };
  const resetContext = () => {
    setCategoryToUpdateId("");
    setCategoryName("");
    setCategoryColor("");
    setEditMode("ADD");
    setIsBusy(false);
  };

  // Data setters
  const categoryColorSelectionHandler = (color: string) => {
    setCategoryColor(color);
  };
  const categoryNamePrefillHandler = (name: string) => {
    setCategoryName(name);
  };

  // CRUD methods
  const createCategoryHandler = (name: string) => {
    setIsBusy(true);
    productCategoryServices
      .createNewProductCategory(
        authCtx.restaurantId,
        name || "undefined",
        categoryColor || "red",
        restaurantDataCtx.productCategoriesList.length
      )
      .then((success: BaseAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      })
      .catch((error: BaseAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      });
  };

  const updateCategoryHandler = (name: string) => {
    setIsBusy(true);
    productCategoryServices
      .updateProductCategory(
        categoryToUpdateId,
        authCtx.restaurantId,
        name,
        categoryColor
      )
      .then((success: BaseAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      })
      .catch((error: BaseAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      });
  };

  const deleteCategoryHandler = () => {
    present({
      header: infos.CATEGORY_DELETION_INFOS_ALERT.header,
      message: infos.CATEGORY_DELETION_INFOS_ALERT.detail,
      buttons: [
        i18n.t("AppSettingsOrderHistory.cancelled"),
        {
          text: "Ok",
          handler: (d) => {
            setTimeout(() => {
              toggleModalClosingTrigger();
              productCategoryServices
                .deleteProductCategory(categoryToUpdateId, authCtx.restaurantId)
                .then((success: BaseAlert) => {
                  present({
                    header: success.header,
                    message: success.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => {
                      toggleModalClosingTrigger();
                    },
                  });
                })
                .catch((error: BaseAlert) => {
                  console.log(error);
                  present({
                    header: error.header,
                    message: error.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => {
                      toggleModalClosingTrigger();
                    },
                  });
                });
              editProductCtx.productCategorySelectionHandler("");
            }, 150);
          },
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const formSubmitHandler = (name: string) => {
    if (!isBusy) {
      return editMode === "ADD"
        ? createCategoryHandler(name)
        : updateCategoryHandler(name);
    }
  };

  return (
    <EditCategoryContext.Provider
      value={{
        isBusy,
        editMode,
        formSchema,
        categoryToUpdateId,
        categoryName,
        categoryColor,
        setAsUpdate,
        categoryNamePrefillHandler,
        categoryColorSelectionHandler,
        formSubmitHandler,
        deleteCategoryHandler,
        resetContext,
        modalClosingTrigger,
      }}
    >
      {props.children}
    </EditCategoryContext.Provider>
  );
};

export default EditCategoryContext;
