import { IonIcon, IonToggle, useIonAlert } from "@ionic/react";
import {
  alertCircle,
  checkmarkCircle,
  helpCircle,
  stopCircle,
  trash,
  warning,
} from "ionicons/icons";
import { GCS } from "../../../services";
import { useContext, useState } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import restaurantServices from "../../../services/apis/restaurantServices";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import DeliveryDelaySelector from "../DeliveryDelaySelector/DeliveryDelaySelector";
import SettingCard from "../SettingCard/SettingCard";
import SettingLabelAndInfo from "../SettingLabelAndInfo/SettingLabelAndInfo";
import buildEnvConfig from "../../../build.env.json";
import AddressFetcher from "../../@Shared/AddressFetcher/AddressFetcher";
import { useIonModal } from "@ionic/react";
import DeliveryFeesByCityModal from "./DeliveryFeesByCityModal";
import "./DeliverySetupEditor.css";
import { DeliveryFeesByCity } from "../../../models/RestaurantInfos";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";
import PromoCodeEditor from "./PromoCodeEditor";

interface ContainerProps {}

const DeliverySetupEditor: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);
  const [isBusy, setIsBusy] = useState(false);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [currentAddressInput, setCurrentAddressInput] = useState("");
  const [availableCities, setAvailableCities] = useState(
    restaurantDataCtx.restaurantInfos.availableCitiesOnDelivery || []
  );
  const [toAddCity, setToAddCity] = useState("");
  const [deliveryFees, setDeliveryFees] = useState(
    restaurantDataCtx.restaurantInfos.deliveryFees / 100 || 0
  );
  const [freeDeliveryFeesTrigger, setFreeDeliveryFeesTrigger] = useState(
    restaurantDataCtx.restaurantInfos.freeDeliveryFeesTrigger / 100 || 0
  );

  const [minimumOrderAmount, setMinimumOrderAmount] = useState(
    restaurantDataCtx.restaurantInfos.minimumOrderAmount
      ? restaurantDataCtx.restaurantInfos.minimumOrderAmount / 100
      : 0
  );

  const [maxDeliveryRange, setMaxDeliveryRange] = useState(
    restaurantDataCtx.restaurantInfos.maxDeliveryRange || 1
  );

  const [deliveryFeesByKm, setDeliveryFeesByKm] = useState(
    restaurantDataCtx.restaurantInfos.deliveryFeesByKm
      ? restaurantDataCtx.restaurantInfos.deliveryFeesByKm / 100
      : 0
  );

  const [urlSlug, setUrlSlug] = useState(
    restaurantDataCtx.restaurantInfos.urlSlug || ""
  );

  const [selectedAddress, setSelectedSelection] = useState(
    {} as google.maps.places.AutocompletePrediction
  );

  const [urlSlugHasError, setUrlSlugHasError] = useState(false);

  const [present] = useIonAlert();

  const addCityHandler = (suggestion: any) => {
    if (!isLoadingCity) {
      setIsLoadingCity(true);
      if (suggestion.place_id) {
        GCS.getPlaceGeocoding(suggestion.place_id)
          .then((data) => {
            let postalCode = data.results[0].address_components.find(function (
              component
            ) {
              return component.types[0] == "postal_code";
            });
            let city = data.results[0].address_components.find(function (
              component
            ) {
              return component.types[0] == "locality";
            });

            if (city?.short_name && postalCode?.short_name) {
              if (
                availableCities.findIndex(
                  (currentCity) =>
                    currentCity.name === city?.short_name &&
                    currentCity.zipCode === postalCode?.short_name
                ) === -1
              ) {
                setIsLoadingCity(false);
                setAvailableCities([
                  ...availableCities,
                  {
                    name: city?.short_name,
                    zipCode: postalCode?.short_name,
                    deliveryFees: null,
                    freeDeliveryFeesTrigger: null,
                  },
                ]);
              } else {
                setIsLoadingCity(false);
                present({
                  header: i18n.t("AddProductProductPage.Error"),
                  message: i18n.t(
                    "AppSettingsDeliverySettings.CityAlreadyExist"
                  ),
                  buttons: [{ text: "Ok", handler: (d) => {} }],
                  onDidDismiss: (e) => setIsBusy(false),
                });
              }
            } else {
              setIsLoadingCity(false);
              present({
                header: i18n.t("AddProductProductPage.Error"),
                message: `${i18n.t(
                  "AppSettingsRestaurantInformation.NoPostalCode"
                )} '${currentAddressInput}, ${currentAddressInput}' `,
                buttons: [{ text: "Ok", handler: (d) => {} }],
                onDidDismiss: (e) => setIsBusy(false),
              });
            }
          })
          .catch((err) => {
            present({
              header: i18n.t("AddProductProductPage.Error"),
              message: i18n.t("AddProductProductPage.ErrorOccured"),
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => setIsBusy(false),
            });
            setIsLoadingCity(false);
          });
      } else {
        setIsLoadingCity(false);
      }
    }
  };

  const removeCityHandler = (index: number) => {
    const removedArray = [...availableCities].filter((item, inIndex) => {
      if (inIndex !== index) {
        return item;
      }
    });
    setAvailableCities(removedArray);
  };

  const deliveryTimeHandler = (value: number) => {
    restaurantServices.updateDeliveryTime(authCtx.restaurantId, value);
  };

  // const deliveryTimeByKmHandler = (value: number) => {
  //   restaurantServices.updateDeliveryTimeByKm(authCtx.restaurantId, value);
  // };
  const clickAndCollectTimeHandler = (value: number) => {
    restaurantServices.updateClickAndCollectTime(authCtx.restaurantId, value);
  };

  const enableDoorPaymentToggler = (value: boolean) => {
    restaurantServices.setEnableDoorPayment(authCtx.restaurantId, value);
  };

  const deliveryAvailabilityToggler = (value: boolean) => {
    restaurantServices.setRestaurantDeliveryClosed(authCtx.restaurantId, value);
  };

  const clickAndCollectAvailabilityToggler = (value: boolean) => {
    restaurantServices.setRestaurantClickAndCollectClosed(
      authCtx.restaurantId,
      value
    );
  };
  const productionModeToggler = (value: boolean) => {
    restaurantServices.setRestaurantProductionMode(authCtx.restaurantId, value);
  };

  const updateDeliveryFeesByCity = (
    newDeliveryFeesByCity: DeliveryFeesByCity[]
  ) => {
    setIsBusy(true);
    restaurantServices
      .updateRestaurantAvailableCitiesOnDelivery(
        authCtx.restaurantId,
        newDeliveryFeesByCity
      )
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateMaxDeliveryRange = () => {
    setIsBusy(true);
    restaurantServices
      .updateMaxDeliveryRange(authCtx.restaurantId, maxDeliveryRange)
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };
  const updateRestaurantAvailableCitiesOnDeliveryHandler = () => {
    setIsBusy(true);
    restaurantServices
      .updateRestaurantAvailableCitiesOnDelivery(
        authCtx.restaurantId,
        availableCities
      )
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateDeliveryFees = () => {
    setIsBusy(true);
    restaurantServices
      .updateDeliveryFees(
        authCtx.restaurantId,
        deliveryFees,
        freeDeliveryFeesTrigger,
        deliveryFeesByKm,
        minimumOrderAmount
      )
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const updateUrlSlug = () => {
    if (!urlSlug.trim().toLowerCase()) {
      return;
    }
    setIsBusy(true);
    restaurantServices
      .updateUrlSlug(
        authCtx.restaurantId,
        restaurantDataCtx.restaurantInfos.urlSlug,
        urlSlug
      )
      .then((success) => {
        setUrlSlugHasError(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        setUrlSlugHasError(true);
        present({
          header: error.header,
          message: i18n.t("AppSettingsDeliverySettings.slugURLExistAlready"),
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  const [presentDelivreyFees, dismiss] = useIonModal(DeliveryFeesByCityModal, {
    restaurantDataCtx: restaurantDataCtx,
    updateDeliveryFeesByCity: updateDeliveryFeesByCity,
    onDismiss: () => dismiss(),
  });

  const [presentCouponManager, dismissCouponManager] = useIonModal(
    PromoCodeEditor,
    {
      restaurantDataCtx: restaurantDataCtx,
      updateDeliveryFeesByCity: updateDeliveryFeesByCity,
      onDismiss: () => dismissCouponManager(),
    }
  );
  return (
    <div className="delivery-setup-editor">
      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.announcedDeliveryTime")}
        gridId="delivery-delay"
      >
        <div>
          <DeliveryDelaySelector
            onChange={(value: number) => {
              deliveryTimeHandler(value);
            }}
            value={restaurantDataCtx.restaurantInfos.currentDeliveryTime}
          />
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t(
          "AppSettingsDeliverySettings.announcedClickAndCollectTime"
        )}
        gridId="click-and-collect-delay"
      >
        <div>
          <DeliveryDelaySelector
            onChange={(value: number) => {
              clickAndCollectTimeHandler(value);
            }}
            value={restaurantDataCtx.restaurantInfos.currentClickAndCollectTime}
          />
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.DeliveryDistance")}
        gridId="delivery-cities"
        onSave={() => updateMaxDeliveryRange()}
        isBusy={isBusy}
      >
        <BaseTextInput
          type="number"
          label={i18n.t("AppSettingsDeliverySettings.MaxDeliveryDistance")}
          controller={{
            value: maxDeliveryRange,
            onChange: (event: any) => {
              setMaxDeliveryRange(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.availability")}
        gridId="delivery-open"
      >
        <div>
          <div className="delivery-setup-editor__opener">
            <label>
              {i18n.t("AppSettingsDeliverySettings.deliveriesAreOpen")}
            </label>
            <span>
              <IonToggle
                mode={"ios"}
                checked={!restaurantDataCtx.restaurantInfos.deliveryClosed}
                onIonChange={(event) =>
                  deliveryAvailabilityToggler(!event.detail.checked)
                }
              />
            </span>
          </div>
          <div className="delivery-setup-editor__opener">
            <label>
              {i18n.t("AppSettingsDeliverySettings.clickAndCollectIsOpen")}
            </label>
            <span>
              <IonToggle
                mode={"ios"}
                checked={
                  !restaurantDataCtx.restaurantInfos.clickAndCollectClosed
                }
                onIonChange={(event) =>
                  clickAndCollectAvailabilityToggler(!event.detail.checked)
                }
              />
            </span>
          </div>
        </div>
      </SettingCard>
      <SettingCard title="Mode production" gridId="production-mode">
        <div className="delivery-setup-editor__opener">
          <label>
            {i18n.t("AppSettingsDeliverySettings.productionModeIsActive")}
          </label>
          <span>
            <IonToggle
              mode={"ios"}
              checked={restaurantDataCtx.restaurantInfos.productionMode}
              onIonChange={(event) => {
                if (event?.detail?.checked !== undefined) {
                  productionModeToggler(event.detail.checked);
                }
              }}
            />
          </span>
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.discount")}
        gridId="discount"
      >
        <div className="delivery-setup-editor__opener">
          <BaseButton onClick={() => presentCouponManager()} margins="0 0 0 0">
            {i18n.t("AppSettingsDeliverySettings.handlePromoCodes")}
          </BaseButton>
        </div>
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.deliveryFees")}
        gridId="delivery-fees"
        onSave={() => updateDeliveryFees()}
        isBusy={isBusy}
      >
        <BaseTextInput
          type="number"
          label={`${i18n.t(
            "AppSettingsDeliverySettings.defaultDeliveryFeeInEuro"
          )} ${constants.CURRENCY}`}
          controller={{
            value: deliveryFees,
            onChange: (event: any) => {
              setDeliveryFees(event.target.value);
            },
          }}
        />

        <BaseTextInput
          type="number"
          label={`${i18n.t("AppSettingsDeliverySettings.FeesKm")} ${
            constants.CURRENCY
          }`}
          controller={{
            value: deliveryFeesByKm,
            onChange: (event: any) => {
              setDeliveryFeesByKm(event.target.value);
            },
          }}
        />
        <BaseTextInput
          type="number"
          label={`${i18n.t(
            "AppSettingsDeliverySettings.deliveryOfferStartingFromInEuro"
          )} ${constants.CURRENCY}`}
          controller={{
            value: freeDeliveryFeesTrigger,
            onChange: (event: any) => {
              setFreeDeliveryFeesTrigger(event.target.value);
            },
          }}
        />

        <BaseTextInput
          type="number"
          label={`${i18n.t("AppSettingsDeliverySettings.MinimumOrderAmount")} ${
            constants.CURRENCY
          }`}
          controller={{
            value: minimumOrderAmount,
            onChange: (event: any) => {
              setMinimumOrderAmount(event.target.value);
            },
          }}
        />

        {/* <BaseButton onClick={() => presentDelivreyFees()}>
          Frais de livraison par ville
        </BaseButton> */}
      </SettingCard>

      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.PaymentDoor")}
        gridId="door-payment"
      >
        <div className="delivery-setup-editor__opener">
          <label>
            {i18n.t("AppSettingsDeliverySettings.PaymentDoorEnabled")}
          </label>
          <span>
            <IonToggle
              mode={"ios"}
              checked={restaurantDataCtx.restaurantInfos.enableDoorPayment}
              onIonChange={(event) => {
                if (event?.detail?.checked !== undefined) {
                  enableDoorPaymentToggler(event.detail.checked);
                  if (
                    event.detail.checked === true &&
                    !restaurantDataCtx.restaurantInfos.subscriptionPlan
                  ) {
                    present({
                      header: "Information",
                      message: i18n.t(
                        "AppSettingsDeliverySettings.WarningPaymentDoor"
                      ),
                      buttons: [{ text: "Ok", handler: (d) => {} }],
                      onDidDismiss: (e) => setIsBusy(false),
                    });
                  }
                }
              }}
            />
          </span>
        </div>
      </SettingCard>

      <SettingCard
        title={i18n.t("AppSettingsDeliverySettings.onlineSalesSite")}
        gridId="delivery-website"
        onSave={() => {
          if (!restaurantDataCtx.restaurantInfos.urlSlug) {
            present({
              header: i18n.t("AppSettingsDeliverySettings.Warning"),
              message: i18n.t("AppSettingsDeliverySettings.ChangeURLonce"),
              buttons: [
                {
                  text: i18n.t("AppSettingsRestaurantInformation.Confirm"),
                  handler: (d) => updateUrlSlug(),
                },
                {
                  text: i18n.t("AppSettingsOrderHistory.cancelled"),
                  handler: (d) => {},
                },
              ],
              onDidDismiss: (e) => setIsBusy(false),
            });
          } else {
            present({
              header: i18n.t("AddProductProductPage.Error"),
              message: i18n.t("AppSettingsDeliverySettings.URLsetUp"),
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => setIsBusy(false),
            });
          }
        }}
        isBusy={isBusy}
      >
        <SettingLabelAndInfo
          label={i18n.t("AppSettingsDeliverySettings.currentURL")}
          info={
            restaurantDataCtx.restaurantInfos.urlSlug
              ? buildEnvConfig.BUILD_ENV == "DEV"
                ? `${constants.SHOP_URL}/${restaurantDataCtx.restaurantInfos.urlSlug}`
                : `${constants.SHOP_URL}/${restaurantDataCtx.restaurantInfos.urlSlug}`
              : "-"
          }
        />
        <div className="delivery-setup-editor__slug-setup">
          <BaseTextInput
            label={i18n.t("AppSettingsDeliverySettings.slugURL")}
            controller={{
              value: urlSlug,
              onChange: (event: any) => {
                setUrlSlug(event.target.value);
              },
            }}
          />
          <div className="delivery-setup-editor__slug-setup__availability-indicator">
            <IonIcon
              icon={
                !restaurantDataCtx.restaurantInfos.urlSlug && !urlSlugHasError
                  ? warning
                  : !urlSlugHasError
                  ? checkmarkCircle
                  : alertCircle
              }
              color={
                !restaurantDataCtx.restaurantInfos.urlSlug && !urlSlugHasError
                  ? "medium"
                  : !urlSlugHasError
                  ? "success"
                  : "danger"
              }
            />
          </div>
        </div>
      </SettingCard>
    </div>
  );
};

export default DeliverySetupEditor;
