import { IonIcon, IonImg } from "@ionic/react";
import { time, hourglass } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import TimePicker from "../TimeScrollerSelector/TimeScrollerSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "./OrderPlannedTimeSelector.css";
import i18n from "../../../translations/i18n";

registerLocale("fr", fr);

interface ContainerProps {}

const OrderPlannedTimeSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [includedHour, setIncludedHour] = useState<Array<Date>>([]);
  const [todayWorkingHours, setTodayWorkingHours] = useState({});
  const [minDate, setMinDate] = useState<Date>(new Date());
  const tmpMaxDate = new Date();
  tmpMaxDate.setHours(23, 59, 59);
  const [maxDate, setMaxDate] = useState<Date>(tmpMaxDate);
  const getTodayWorkingHours = (todayIndex: number) => {
    const workingDays = restaurantDataCtx.workingDays;
    let todayWorkingHours = workingDays.mon;
    switch (todayIndex) {
      case 0:
        todayWorkingHours = workingDays.sun;
        break;
      case 1:
        todayWorkingHours = workingDays.mon;
        break;
      case 2:
        todayWorkingHours = workingDays.tue;
        break;
      case 3:
        todayWorkingHours = workingDays.wed;
        break;
      case 4:
        todayWorkingHours = workingDays.thu;
        break;
      case 5:
        todayWorkingHours = workingDays.fri;
        break;
      case 6:
        todayWorkingHours = workingDays.sat;
        break;
    }
    return todayWorkingHours;
  };

  const getIncludedHours = (todayWorkingHours: Array<any>) => {
    let includedHour: Array<Date> = [];
    if (todayWorkingHours) {
      todayWorkingHours.forEach((hourRange) => {
        for (let i = hourRange.start * 3; i <= hourRange.end * 3; i++) {
          const hour = Math.floor(i / 6);
          const tenth = i % 6;
          const nowDate = new Date();
          nowDate.setHours(hour, tenth * 10);
          if (nowDate > new Date()) {
            includedHour.push(nowDate);
          }
        }
      });
    }
    return includedHour;
  };

  useEffect(() => {
    const todayIndex: number = minDate.getDay();
    setTodayWorkingHours(getTodayWorkingHours(todayIndex));

    //Only include opening hours
    setIncludedHour(getIncludedHours(getTodayWorkingHours(todayIndex)));
    if (getIncludedHours(getTodayWorkingHours(todayIndex)).length) {
      newOrderCtx.orderPlanningTimeSelectionHandler(
        getIncludedHours(getTodayWorkingHours(todayIndex))[0]
      );
    }
  }, []);

  // Get opening hours accodring to today's day

  if (includedHour.length == 0 && todayWorkingHours) {
    return (
      <div
        className="orders-planned-order-time-selector"
        data-testid="orders-planned-order-time-selector"
      >
        <span
          className="orders-planned-order-time-selector-text"
          data-testid="orders-planned-order-time-selector-text"
        >
          {i18n.t("AppOrder.NoSchedule")}
        </span>
      </div>
    );
  }

  return (
    <div
      className="orders-planned-order-time-selector"
      data-testid="orders-planned-order-time-selector"
    >
      <span
        className="orders-planned-order-time-selector-text"
        data-testid="orders-planned-order-time-selector-text"
      >
        {i18n.t("AppOrder.selectTime")}{" "}
      </span>
      {/* <TimePicker
      /> */}
      <DatePicker
        locale="fr"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={10}
        timeCaption="Heure"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        selected={newOrderCtx.orderPlannedTime}
        onChange={(date: Date) =>
          newOrderCtx.orderPlanningTimeSelectionHandler(date)
        }
        minDate={minDate}
        maxDate={maxDate}
        minTime={minDate}
        maxTime={maxDate}
        includeTimes={includedHour.length == 0 ? undefined : includedHour}
        dropdownMode={"scroll"}
        calendarClassName="orders-planned-order-time-datepicker-calendar"
        className="orders-planned-order-time-datepicker"
        timeClassName={() => "orders-planned-order-time-datepicker-time"}
        wrapperClassName="orders-planned-order-time-datepicker-wrapper"
        popperPlacement="bottom"
      />
    </div>
  );
};

export default OrderPlannedTimeSelector;
