import { IonIcon, IonImg } from "@ionic/react";
import { time, hourglass } from "ionicons/icons";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import "./OrderIsAPlannedOrderSelector.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrderIsAPlannedOrderSelector: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);

  const getSelectorClasses = (orderPlanningType: "NOW" | "PLANNED") => {
    const base = ["orders-planned-order-type-selector__selector"];
    if (newOrderCtx.orderPlanningType == orderPlanningType) {
      base.push("orders-planned-order-type-selector__selector--active");
    }
    return base.join(" ");
  };

  return (
    <div
      className="orders-planned-order-type-selector"
      data-testid="orders-planned-order-type-selector"
    >
      <div
        className={getSelectorClasses("NOW")}
        onClick={() => newOrderCtx.orderPlanningTypeSelectionHandler("NOW")}
        data-testid="orders-planned-order-type-selector__selector"
      >
        <div className="orders-planned-order-type-selector__selector__icon">
          <IonIcon icon={hourglass} />
        </div>
        <span className="orders-planned-order-type-selector__selector__label">
          {i18n.t("AppOrder.orderToBeCompletedASAP")}
        </span>
      </div>
      <div
        className={getSelectorClasses("PLANNED")}
        onClick={() => newOrderCtx.orderPlanningTypeSelectionHandler("PLANNED")}
        data-testid="orders-planned-order-type-selector__selector"
      >
        <div className="orders-planned-order-type-selector__selector__icon">
          <IonIcon icon={time} />
        </div>
        <span className="orders-planned-order-type-selector__selector__label">
          {i18n.t("AppOrder.plannedOrder")}
        </span>
      </div>
    </div>
  );
};

export default OrderIsAPlannedOrderSelector;
