import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./auth.ctx";
import { useIonAlert } from "@ionic/react";
import CustomerInfos from "../models/CustomerInfos";
import customerServices from "../services/apis/customerServices";

const CustomerResearchContext = React.createContext({
  isBusy: false,
  customerPhone: "",
  customerSearchResults: [] as CustomerInfos[],
  customerPhoneInputHandler: (phone: string) => {},
  resetContext: () => {},
  reDoSearch: () => {},
});

export const CustomerResearchContextProvider: React.FC = (props) => {
  // Use of the auth context to get the restaurant id
  const authCtx = useContext(AuthContext);
  // Is searching is a busy props
  const [isBusy, setIsBusy] = useState(false);
  const [reDoSearchTrigger, setRedoSearchTrigger] = useState(false);
  // Input value is handled directly inside the context (bc. no automatic form)
  const [customerPhone, setCustomerPhone] = useState("");
  // Results are stored inside the search results array
  const [customerSearchResults, setCustomerSearchResults] = useState(
    [] as CustomerInfos[]
  );

  const [present] = useIonAlert();

  const resetContext = () => {
    setCustomerPhone("");
    setIsBusy(false);
  };

  const customerPhoneInputHandler = (phone: string) => {
    setCustomerPhone(phone);
  };

  const reDoSearch = () => {
    setRedoSearchTrigger(!reDoSearchTrigger);
  };

  useEffect(() => {
    if (!isBusy) {
      if (customerPhone.length != 10) {
        setCustomerSearchResults([]);
      }
      if (customerPhone.length == 10) {
        setIsBusy(true);
        customerServices
          .searchCustomerByPhone(customerPhone, authCtx.restaurantId)
          .then((results: CustomerInfos[]) => {
            setCustomerSearchResults(results);
            setIsBusy(false);
          })
          .catch((error) => {
            present({
              header: error.header,
              message: error.detail,
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {
                setIsBusy(false);
              },
            });
          });
      }
    }
  }, [customerPhone, reDoSearchTrigger]);

  return (
    <CustomerResearchContext.Provider
      value={{
        isBusy,
        customerSearchResults,
        customerPhone,
        customerPhoneInputHandler,
        resetContext,
        reDoSearch,
      }}
    >
      {props.children}
    </CustomerResearchContext.Provider>
  );
};

export default CustomerResearchContext;
