import { useContext } from "react";
import SignUpContext from "../../../contexts/sign-up.ctx";
import { SelectorRoles } from "../../../types";
import BaseForm from "../../@Base/BaseForm/BaseForm";
import "./SignUpForm.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const SignUpForm: React.FC<ContainerProps> = (props) => {
  const signUpCtx = useContext(SignUpContext);

  return (
    <section className="signup-form" data-testid="signup-form">
      <BaseForm
        schema={signUpCtx.formSchema}
        onSubmit={(data: { email: string; password: string }) =>
          signUpCtx.formSubmitHandler(data.email, data.password)
        }
        submitText={i18n.t("AppSettingsAccount.CreateAccount")}
        isBusy={signUpCtx.isBusy}
        title={i18n.t("AppSettingsAccount.myInformationPopupHeader")}
      />
    </section>
  );
};

export default SignUpForm;
// NOTE : Sign Up is now only available for courier
// TODO : might be a good idea to add courier informations to prevent emptyness bug
