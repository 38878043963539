import { useIonAlert } from "@ionic/react";
import { useContext, useState } from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import SettingCard from "../SettingCard/SettingCard";
import i18n from "../../../translations/i18n";

import "./AccessoriesEditor.css";

interface ContainerProps {}

const AccessoriesEditor: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const [posMacAddressValue, setPosMacAddressValue] = useState(
    restaurantDataCtx.posMacAddress || ""
  );

  const [present] = useIonAlert();

  const posMacAddressUpdateHandler = () => {
    if (!posMacAddressValue) {
      return;
    }
    restaurantDataCtx.posMacAddressUpdateHandler(posMacAddressValue);
    present({
      header: i18n.t("AppSettingsAccessory.Update"),
      message: i18n.t("AppSettingsAccessory.PrinterUpdated"),
      buttons: [{ text: "Ok", handler: (d) => {} }],
      onDidDismiss: (e) => {},
    });
  };

  return (
    <div className="accessories-editor">
      <SettingCard
        title={i18n.t("AppSettingsAccessory.btPrinterConfiguration")}
        gridId="printer-setup"
        onSave={() => posMacAddressUpdateHandler()}
      >
        <BaseTextInput
          label={i18n.t("AppSettingsAccessory.macAddress")}
          controller={{
            value: posMacAddressValue,
            onChange: (event: any) => {
              setPosMacAddressValue(event.target.value);
            },
          }}
        />
      </SettingCard>
    </div>
  );
};

export default AccessoriesEditor;
