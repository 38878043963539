import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import ProductCategory from "../../models/ProductCategory";
import { SuccessAlert } from "../../types";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { v4 as uuidv4 } from "uuid";

const productCategoryServices = {
  listenToProductCategories(restaurantId: string, listenerCallback: Function) {
    const productCategoriesListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("product_categories")
      .orderBy("position")
      .onSnapshot((querySnapshot) => {
        let productCategories: ProductCategory[] = [];
        querySnapshot.forEach((doc) => {
          productCategories.push(
            new ProductCategory(
              doc.id,
              doc.data().name,
              doc.data().color,
              doc.data().position
            )
          );
        });
        listenerCallback(productCategories);
      });
    return productCategoriesListener;
  },
  createNewProductCategory(
    restaurantId: string,
    categoryName: string,
    categoryColor: string,
    categoryPosition: number
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const generatedId = uuidv4();
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("product_categories")
        .doc(generatedId)
        .set({
          name: categoryName,
          color: categoryColor,
          position: categoryPosition,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          res(success.CATEGORY_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CATEGORY_CREATION_ERROR_ALERT);
        });
    });
  },
  updateProductCategory(
    categoryToUpdateId: string,
    restaurantId: string,
    categoryName: string,
    categoryColor: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("product_categories")
        .doc(categoryToUpdateId)
        .set(
          {
            name: categoryName,
            color: categoryColor,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.CATEGORY_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CATEGORY_UPDATE_ERROR_ALERT);
        });
    });
  },
  deleteProductCategory(
    categoryToUpdateId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("product_categories")
        .doc(categoryToUpdateId)
        .delete()
        .then(() => {
          res(success.CATEGORY_DELETE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CATEGORY_DELETE_ERROR_ALERT);
        });
    });
  },
  updateProductCategoriesPositions(
    restaurantId: string,
    firstCategoryId: string,
    firstCategoryNewPosition: number,
    secondCategoryId: string,
    secondCategoryNewPosition: number
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("product_categories")
        .doc(firstCategoryId)
        .set(
          {
            position: firstCategoryNewPosition,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          $firestore
            .collection("restaurants")
            .doc(restaurantId)
            .collection("product_categories")
            .doc(secondCategoryId)
            .set(
              {
                position: secondCategoryNewPosition,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            )
            .then(() => {
              res(success.CATEGORY_UPDATE_SUCCESS_ALERT);
            })
            .catch((e) => {
              console.log(e);
              rej(errors.CATEGORY_UPDATE_ERROR_ALERT);
            });
        })
        .catch((e) => {
          console.log(e);
          rej(errors.CATEGORY_UPDATE_ERROR_ALERT);
        });
    });
  },
};

export default productCategoryServices;
