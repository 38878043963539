import { IonIcon, IonImg, useIonAlert } from "@ionic/react";
import { navigate, card, time, call, navigateCircle } from "ionicons/icons";
import { OrdersStatus } from "../../../types";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import ProfilePicturePlaceholder from "../../../assets/images/profile-picture-placeholder.png";
import "./OrderItem.css";
import dayjs from "dayjs";
import CustomerInfos from "../../../models/CustomerInfos";
import Courier from "../../../models/Courier";
import Product from "../../../models/Product";
import Order from "../../../models/Order";
import { MouseEvent, useContext, useEffect, useState } from "react";
import OrdersPageContext from "../../../contexts/orders-page.ctx";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import AuthContext from "../../../contexts/auth.ctx";
import classNames from "classnames";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";

import { constants } from "../../../utils/constants";

import i18n from "../../../translations/i18n";
interface ContainerProps {
  status: OrdersStatus;
  delivered: boolean;
  productsList: Product[];
  customerInfos?: CustomerInfos;
  courierInfos?: Courier;
  orderNumber: string;
  date: Date;
  order: Order;
  selected?: boolean;
  onClick?: Function;
  deliveryMode?: boolean;
}

const OrderItem: React.FC<ContainerProps> = (props) => {
  const ordersPageCtx = useContext(OrdersPageContext);
  const courierDataCtx = useContext(CourierDataContext);
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [present] = useIonAlert();
  var deliveryTimeDateObj = props.order.createdAt
    ? restaurantDataCtx.restaurantInfos.currentDeliveryTime
      ? new Date(
          props.order.createdAt.getTime() +
            restaurantDataCtx.restaurantInfos.currentDeliveryTime * 60000
        )
      : new Date(props.order.createdAt.getTime() + 30 * 60000)
    : null;

  var clickAndCollectTimeDateObj = props.order.createdAt
    ? restaurantDataCtx.restaurantInfos.currentClickAndCollectTime
      ? new Date(
          props.order.createdAt.getTime() +
            restaurantDataCtx.restaurantInfos.currentClickAndCollectTime * 60000
        )
      : new Date(props.order.createdAt.getTime() + 20 * 60000)
    : null;
  const deliveryConfirmationHandler = () => {
    const setAsDelivered = () => {
      courierDataCtx.setOrderAsDelivered(authCtx.restaurantId, props.order.id);
    };

    present({
      header: i18n.t("AppSettingsRestaurantInformation.ConfirmDelivery"),
      message: i18n.t("AppSettingsRestaurantInformation.CompletedDelivery"),
      buttons: [
        {
          text: i18n.t("AppSettingsOrderHistory.cancelled"),
          handler: (d) => {},
        },
        {
          text: i18n.t("AppSettingsRestaurantInformation.Confirm"),
          handler: (d) => setAsDelivered(),
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const closingConfirmationHandler = (orderId: string) => {
    const setAsClosed = () => {
      ordersPageCtx.setOrderAsClosed(orderId);
    };
    present({
      header: i18n.t("AppSettingsRestaurantInformation.ConfirmService"),
      message: i18n.t("AppSettingsRestaurantInformation.ServedOrder"),
      buttons: [
        {
          text: i18n.t("AppSettingsOrderHistory.cancelled"),
          handler: (d) => {},
        },
        {
          text: i18n.t("AppSettingsRestaurantInformation.Confirm"),
          handler: (d) => setAsClosed(),
        },
      ],
      onDidDismiss: (e) => {},
    });
  };

  const amount = props.order.total_price
    ? (props.order.total_price / 100).toFixed(2)
    : "0";
  const amountWithoutFees = props.order.total_price
    ? props.order.delivery_fees
      ? ((props.order.total_price - props.order.delivery_fees) / 100).toFixed(2)
      : (props.order.total_price / 100).toFixed(2)
    : 0;
  const deliveryFees = props.order.delivery_fees
    ? (props.order.delivery_fees / 100).toFixed(2)
    : "0";
  return (
    <div className="order-item-main-container">
      <div
        style={{ opacity: props.order.status == "CANCELED" ? 0.6 : 1 }}
        className={classNames({
          "order-item": true,
          "order-item--delivery-mode": props.deliveryMode,
          "order-item--local-unpaid":
            props.order.specialStatus == "LOCAL_UNPAID",
          "order-item--web-paid": props.order.specialStatus == "WEB_PAID",
          "order-item--selected": props.selected,
        })}
        data-testid="order-item"
        onClick={() => (props.onClick ? props.onClick() : null)}
      >
        <div
          className={
            !props.selected
              ? "order-item__header"
              : "order-item__header order-item__header--selected"
          }
        >
          {(props.order.optimizedPosition ||
            props.order.optimizedPosition == 0) && (
            <div className="order-item__header__position-indicator">
              {props.order.optimizedPosition + 1}
            </div>
          )}
          <div className="order-item__header__products">
            <div className="order-item__header__products__main">
              <IonImg src={props.productsList[0]?.pictureUrl} />
            </div>
            <div className="order-item__header__products__aside">
              <div className="order-item__header__products__aside__first">
                {props.productsList[1]?.pictureUrl && (
                  <IonImg src={props.productsList[1]?.pictureUrl} />
                )}
              </div>
              <div className="order-item__header__products__aside__second">
                {props.productsList.length <= 3
                  ? props.productsList[2]?.pictureUrl && (
                      <IonImg src={props.productsList[2]?.pictureUrl} />
                    )
                  : `+${props.productsList.length - 2}`}
              </div>
            </div>
          </div>
          <div className="order-item__header__customer-infos">
            {props.order.type == "ON_PLACE" ? (
              <div className="order-item__header__customer-infos__no-delivery">
                {props.order.specialStatus == "LOCAL_UNPAID" &&
                props.order.planningType !== "PLANNED" ? (
                  <div className="order-item__header__planning-type__planned__type--container">
                    <span>{i18n.t("AppSettingsOrderHistory.NoPay")}</span>
                    {props.customerInfos ? (
                      <span className="order-item__header__planning-type__planned__type--phone">
                        {props.customerInfos?.phone}
                      </span>
                    ) : null}
                  </div>
                ) : (
                  <>
                    {props.order.planningType == "PLANNED" &&
                    !props.order.shouldStartOrder ? (
                      <>
                        <div className="order-item__header__planning-type__planned__type--container">
                          <span>
                            {i18n.t("AppSettingsRestaurantInformation.Collect")}
                          </span>
                          {props.customerInfos ? (
                            <span className="order-item__header__planning-type__planned__type--phone">
                              {props.customerInfos?.phone}
                            </span>
                          ) : null}
                        </div>
                        <span className="order-item__header__planning-type__planned__type">
                          {i18n.t("AppSettingsOrderHistory.planned")}
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="order-item__header__planning-type__planned__type--container">
                          <span>
                            {i18n.t("AppSettingsRestaurantInformation.Collect")}
                          </span>
                          {props.customerInfos ? (
                            <span className="order-item__header__planning-type__planned__type--phone">
                              {props.customerInfos?.phone}
                            </span>
                          ) : null}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {props.order.planningType == "PLANNED" &&
                !props.order.shouldStartOrder ? (
                  <div className="order-item__header__customer-infos__infos__container">
                    <div className="order-item__header__customer-infos__infos">
                      <div className="order-item__header__customer-infos__infos__name__planning-type">
                        <span>
                          {[
                            props.customerInfos?.firstname,
                            props.customerInfos?.lastname,
                          ].join(" ")}
                        </span>
                        <span className="order-item__header__customer-infos__infos__planned">
                          {i18n.t("AppSettingsOrderHistory.planned")}
                        </span>
                      </div>

                      <span>{props.customerInfos?.addressFirstLine}</span>
                      <span>{props.customerInfos?.addressSecondLine}</span>
                      <span>
                        {[
                          props.customerInfos?.addressZipcode,
                          props.customerInfos?.addressCity,
                        ].join(" ")}
                      </span>
                      <span>{props.customerInfos?.phone}</span>
                    </div>
                    {props.order.specialStatus == "LOCAL_UNPAID" ? (
                      <>
                        <span>{i18n.t("AppSettingsOrderHistory.NoPay")}</span>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="order-item__header__customer-infos__infos__container">
                    <div className="order-item__header__customer-infos__infos">
                      <div className="order-item__header__customer-infos__infos__name__planning-type">
                        <span>
                          {[
                            props.customerInfos?.firstname,
                            props.customerInfos?.lastname,
                          ].join(" ")}
                        </span>
                      </div>
                      <span>{props.customerInfos?.addressFirstLine}</span>
                      <span>{props.customerInfos?.addressSecondLine}</span>
                      <span>
                        {[
                          props.customerInfos?.addressZipcode,
                          props.customerInfos?.addressCity,
                        ].join(" ")}
                      </span>
                      <span>{props.customerInfos?.phone}</span>
                    </div>
                    {props.order.specialStatus == "LOCAL_UNPAID" ? (
                      <>
                        <span>{i18n.t("AppSettingsOrderHistory.NoPay")}</span>
                      </>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="order-item__infos-and-actions">
          <div className="order-item__infos-and-actions__top">
            <div className="order-item__infos-and-actions__top__order-number">
              {"N°" + props.orderNumber}
            </div>
            {props.status == "RECORDED" &&
              props.order.type == "DELIVERY" &&
              (props.order.planningType !== "PLANNED" ||
                (props.order.planningType == "PLANNED" &&
                  props.order.shouldStartOrder)) && (
                <div className="order-item__infos-and-actions__top__action">
                  <div className="order-item__infos-and-actions__top__action__button">
                    <BaseButton
                      tight
                      expand
                      isLoading={courierDataCtx.isBusy}
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        ordersPageCtx.setOrderAsReady(props.order.id);
                      }}
                    >
                      {i18n.t("AppOrder.ready")}
                    </BaseButton>
                  </div>
                </div>
              )}
            {props.status == "RECORDED" &&
              props.order.type == "ON_PLACE" &&
              (props.order.planningType !== "PLANNED" ||
                (props.order.planningType == "PLANNED" &&
                  props.order.shouldStartOrder)) && (
                <div className="order-item__infos-and-actions__top__action">
                  <div className="order-item__infos-and-actions__top__action__button">
                    <BaseButton
                      tight
                      expand
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        props.order.specialStatus == "LOCAL_UNPAID"
                          ? ordersPageCtx.setOrderAsPaid(props.order)
                          : ordersPageCtx.setOrderAsReady(props.order.id);
                      }}
                      color={
                        props.order.specialStatus == "LOCAL_UNPAID"
                          ? "secondary"
                          : "primary"
                      }
                    >
                      {props.order.specialStatus == "LOCAL_UNPAID"
                        ? i18n.t("AppSettingsOrderHistory.Pay")
                        : i18n.t("AppOrder.ready")}
                    </BaseButton>
                  </div>
                </div>
              )}
            {props.status == "RECORDED" &&
              props.order.planningType == "PLANNED" &&
              !props.order.shouldStartOrder && (
                <div className="order-item__infos-and-actions__top__action">
                  <div className="order-item__infos-and-actions__top__action__button">
                    <BaseButton
                      tight
                      expand
                      onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        ordersPageCtx.setOrderAsToStart(props.order.id);
                      }}
                      color={"secondary"}
                    >
                      {i18n.t("AppSettingsRestaurantInformation.to-do")}
                    </BaseButton>
                  </div>
                </div>
              )}

            {props.status === "NOT_IN_PRODUCTION" && (
              <div className="order-item__infos-and-actions__top__action">
                <div className="order-item__infos-and-actions__top__action__button">
                  <BaseButton
                    tight
                    expand
                    onClick={(event: MouseEvent) => {
                      event.stopPropagation();
                      ordersPageCtx.setOrderToProduction(props.order.id);
                    }}
                    color={"secondary"}
                  >
                    {i18n.t("AppSettingsRestaurantInformation.Production")}
                  </BaseButton>
                </div>
              </div>
            )}

            {props.status == "READY" && props.order.type == "ON_PLACE" && (
              <div className="order-item__infos-and-actions__top__action">
                <div className="order-item__infos-and-actions__top__action__button">
                  <BaseButton
                    tight
                    expand
                    onClick={(event: MouseEvent) => {
                      event.stopPropagation();
                      closingConfirmationHandler(props.order.id);
                    }}
                    color={"primary"}
                  >
                    {i18n.t("AppSettingsRestaurantInformation.Served")}
                  </BaseButton>
                </div>
              </div>
            )}

            {props.status == "READY" &&
              props.order.type == "DELIVERY" &&
              props.customerInfos && (
                <div className="order-item__infos-and-actions__top__action">
                  <div className="order-item__infos-and-actions__top__action__status">
                    <div className="status-dot" />
                    <span>
                      {i18n.t(
                        "AppSettingsRestaurantInformation.PendingDelivery"
                      )}
                    </span>
                  </div>
                </div>
              )}
            {(props.status == "OUT" || props.status == "DELIVERED") &&
              !props.deliveryMode && (
                <div className="order-item__infos-and-actions__top__action">
                  <div className="order-item__infos-and-actions__top__action__status">
                    <div className="courier-profile-picture">
                      <IonImg
                        src={
                          props.courierInfos
                            ? props.courierInfos.profilePictureUrl
                            : ProfilePicturePlaceholder
                        }
                      />
                    </div>
                    <div
                      className={
                        props.delivered
                          ? "status-dot success-dot"
                          : "status-dot warning-dot"
                      }
                    />
                    <span>{props.delivered ? "Livrée" : "En cours"}</span>
                  </div>
                </div>
              )}
            {props.deliveryMode && (
              <div className="order-item__infos-and-actions__top__action">
                <div className="order-item__infos-and-actions__top__action__button">
                  <a
                    href={`tel:${props.order.customer?.phone}`}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <BaseButton tight expand outline>
                      <IonIcon icon={call} />
                      <span style={{ marginLeft: "0.5rem" }}>Appeler</span>
                    </BaseButton>
                  </a>
                </div>
              </div>
            )}
          </div>
          {!props.deliveryMode ? (
            <div className="order-item__infos-and-actions__bottom">
              <div className="order-item__infos-and-actions__bottom__total-amount">
                <IonIcon icon={card} />
                {props.order.type == "DELIVERY" ? (
                  <span>
                    {amount +
                      `${constants.CURRENCY} (` +
                      amountWithoutFees +
                      `${constants.CURRENCY} + ` +
                      deliveryFees +
                      `${constants.CURRENCY} ${i18n.t(
                        "AppSettingsDeliverySettings.Fees"
                      )})`}
                  </span>
                ) : (
                  <span>{amount + ` ${constants.CURRENCY}`}</span>
                )}
              </div>
              <div className="order-item__infos-and-actions__bottom__distance">
                <IonIcon icon={navigate} />
                <span>
                  {
                    "-"
                    // TODO implement real calculation
                  }
                </span>
              </div>

              <div className="order-item__infos-and-actions__bottom__hour">
                <IonIcon icon={time} />
                <span>{dayjs(props.date).format("HH:mm")}</span>
              </div>
              {props.order.planningType == "PLANNED" ? (
                <div className="order-item__infos-and-actions__bottom__hour__planned-time">
                  <IonIcon icon={time} />
                  <span>{dayjs(props.order.plannedTime).format("HH:mm")}</span>
                </div>
              ) : props.order.planningType == "NOW" &&
                (props.order.paymentType == "IRL" ||
                  props.order.paymentType == "STRIPE") &&
                props.order.type == "ON_PLACE" &&
                restaurantDataCtx.restaurantInfos.currentClickAndCollectTime ? (
                <div className="order-item__infos-and-actions__bottom__hour__planned-time">
                  <IonIcon icon={time} />
                  <span>
                    {dayjs(clickAndCollectTimeDateObj).format("HH:mm")}
                  </span>
                </div>
              ) : props.order.planningType == "NOW" &&
                (props.order.paymentType == "IRL" ||
                  props.order.paymentType == "STRIPE") &&
                props.order.type == "DELIVERY" &&
                restaurantDataCtx.restaurantInfos.currentDeliveryTime ? (
                <div className="order-item__infos-and-actions__bottom__hour__planned-time">
                  <IonIcon icon={time} />
                  <span>{dayjs(deliveryTimeDateObj).format("HH:mm")}</span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="order-item__infos-and-actions__bottom--delivery-mode">
              {props.order.status == "OUT" ? (
                <BaseButton
                  tight
                  expand
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    deliveryConfirmationHandler();
                  }}
                >
                  {i18n.t("AppSettingsRestaurantInformation.MarkDelivered")}
                </BaseButton>
              ) : (
                <BaseButton tight expand color="success" disabled>
                  {i18n.t("AppSettingsRestaurantInformation.Delivered")}
                </BaseButton>
              )}
              <a
                onClick={(event) => {
                  event.stopPropagation();
                }}
                // href={`http://maps.google.com/maps?daddr=${props.order.customer?.addressLatitude},${props.order.customer?.addressLongitude}`}
                href={`https://www.waze.com/ul?ll=${props.order.customer?.addressLatitude},${props.order.customer?.addressLongitude}&navigate=yes&zoom=17`}
              >
                <BaseButton tight expand outline>
                  <IonIcon icon={navigateCircle} />
                  <span style={{ marginLeft: "0.5rem" }}>
                    {i18n.t("AppSettingsRestaurantInformation.Navigate")}
                  </span>
                </BaseButton>
              </a>
            </div>
          )}
        </div>
      </div>
      {props.order.status == "CANCELED" ? (
        <div className="order-item--canceled-container">
          {i18n.t("AppSettingsOrderHistory.cancelled")}
        </div>
      ) : null}
    </div>
  );
};

export default OrderItem;
