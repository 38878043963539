import { useIonModal } from "@ionic/react";
import { useContext, useEffect } from "react";
import CustomerResearchContext from "../../../contexts/customer-research.ctx";
import NewCustomerContext from "../../../contexts/new-customer.ctx";
import NewOrderContext from "../../../contexts/new-order.ctx";
import CustomerInfos from "../../../models/CustomerInfos";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import NewCustomerModal from "../../Modals/NewCustomerModal/NewCustomerModal";
import OrdersCustomerItem from "../OrdersCustomerItem/OrdersCustomerItem";
import "./OrdersCustomerResearch.css";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { GCS } from "../../../services";
import customerServices from "../../../services/apis/customerServices";
import { useState } from "react";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersCustomerResearch: React.FC<ContainerProps> = (props) => {
  const customerResearchCtx = useContext(CustomerResearchContext);
  const newCustomerCtx = useContext(NewCustomerContext);
  const newOrderCtx = useContext(NewOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [present, dismiss] = useIonModal(NewCustomerModal, {
    newCustomerCtx,
    onDismiss: () => dismiss(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const openNewCustomerModal = () => {
    newCustomerCtx.resetContext();
    newCustomerCtx.phoneInputHandler(customerResearchCtx.customerPhone);
    present();
  };

  useEffect(() => {
    if (newCustomerCtx.modalClosingTrigger == true) {
      dismiss();
      customerResearchCtx.reDoSearch();
    }
  }, [newCustomerCtx.modalClosingTrigger]);

  const onCustomerSelect = async (customer: CustomerInfos) => {
    if (customer.distanceToRestaurant) {
      newOrderCtx.customerSelectionHandler(customer);
    } else {
      setIsLoading(true);
      const distance = await GCS.calculateRealDistance(
        restaurantDataCtx.restaurantInfos.addressPlaceId,
        customer.addressPlaceId
      );
      customer.distanceToRestaurant = distance;
      await customerServices.updateCustomer(
        restaurantDataCtx.restaurantInfos.id,
        customer.id,
        {
          distance_to_restaurant: distance,
          address_city: customer.addressCity,
          address_first_line: customer.addressFirstLine,
          address_latitude: customer.addressLatitude,
          address_longitude: customer.addressLongitude,
          address_place_id: customer.addressPlaceId,
          address_second_line: customer.addressSecondLine,
          address_zipcode: customer.addressZipcode,
        }
      );
      newOrderCtx.customerSelectionHandler(customer);
      setIsLoading(false);
    }
  };
  return (
    <div
      className="orders-customer-research"
      data-testid="orders-customer-research"
    >
      <span className="orders-customer-research__title">
        {i18n.t("NewDeliveryClient.customerSearch")}
      </span>
      <div className="orders-customer-research__phone-search-input">
        <BaseTextInput
          label={i18n.t("AppSettingsRestaurantInformation.phoneNumber")}
          type="number"
          controller={{
            value: customerResearchCtx.customerPhone,
            onChange: (event: any) =>
              customerResearchCtx.customerPhoneInputHandler(event.target.value),
          }}
        />
      </div>
      <div className="orders-customer-research__search-results-frame">
        {customerResearchCtx.isBusy ? (
          <div className="orders-customer-research__search-results-frame__loading-container">
            <span>{i18n.t("NewDeliveryClient.Search")}</span>
          </div>
        ) : customerResearchCtx.customerPhone.length == 10 &&
          customerResearchCtx.customerSearchResults.length == 0 &&
          !customerResearchCtx.isBusy ? (
          <div className="orders-customer-research__search-results-frame__no-results-container">
            <span>{i18n.t("NewDeliveryClient.noResult")}</span>
          </div>
        ) : (
          customerResearchCtx.customerSearchResults.map(
            (customer: CustomerInfos) => {
              return (
                <OrdersCustomerItem
                  key={customer.id}
                  customer={customer}
                  onSelect={() => onCustomerSelect(customer)}
                  isSelected={customer.id == newOrderCtx.customer.id}
                />
              );
            }
          )
        )}
      </div>
      <div className="products-categories-editor__controls">
        <BaseButton
          expand
          tight
          margins="0 0 1rem 0"
          disabled={customerResearchCtx.customerPhone.length != 10}
          onClick={() => openNewCustomerModal()}
          isLoading={isLoading}
        >
          {i18n.t("NewDeliveryClient.newClient")}
        </BaseButton>
      </div>
    </div>
  );
};

export default OrdersCustomerResearch;
