import React, { useContext, useState } from "react";
import { BaseAlert } from "../types";
import AuthContext from "./auth.ctx";
import { useIonAlert } from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import ProductsOptions, {
  OptionItem,
  OptionType,
} from "../models/ProductsOptions";
import productOptionsSevices from "../services/apis/productOptionsServices";
import i18n from "../translations/i18n";

const EditProductOptionsContext = React.createContext({
  isBusy: false,
  editMode: "ADD",
  optionToUpdateId: "",
  optionName: "",
  optionType: "ONE",
  optionIsMandatory: false,
  optionItemEditIsUnavailable: false,
  optionItems: [] as OptionItem[],
  optionColor: "dark",
  currentOptionItemIndex: -1,
  optionItemEditName: "",
  optionItemEditPrice: "",
  modalClosingTrigger: false,
  setAsUpdate: (idToUpdate: string) => {},
  optionNameInputHandler: (name: string) => {},
  optionTypeChangeHandler: (type: OptionType) => {},
  optionIsMandatoryChangeHandler: (isMandatory: boolean) => {},
  optionItemEditIsUnavailableChangeHandler: (isUnavailable: boolean) => {},
  colorChangeHandler: () => {},
  removeOptionItem: (optionItemIndex: number) => {},
  addOptionItem: () => {},
  updateOptionItem: () => {},
  selectOptionItem: (optionItemIndex: number) => {},
  unselectOptionItem: () => {},
  optionItemEditNameInputHandler: (name: string) => {},
  optionItemEditPriceInputHandler: (price: string) => {},
  formSubmitHandler: () => {},
  deleteOptionHandler: () => {},
  resetContext: () => {},
  prefillForUpdate: (productsOptions: ProductsOptions) => {},
});

export const EditProductOptionsContextProvider: React.FC = (props) => {
  // Auth context needed to get restaurant ID
  const authCtx = useContext(AuthContext);
  const [isBusy, setIsBusy] = useState(false); // NOTE may need to implement business in UI
  // |----- OPTIONS AS GROUP STATES -----|
  const [editMode, setEditMode] = useState("ADD");
  const [optionToUpdateId, setOptionToUpdateId] = useState("");
  const [optionName, setOptionName] = useState("");
  const [optionType, setOptionType] = useState("ONE" as OptionType);
  const [optionIsMandatory, setOptionIsMandatory] = useState(false);
  const [optionItemEditIsUnavailable, setOptionItemEditIsUnavailable] =
    useState(false);

  const [optionItems, setOptionItems] = useState([] as OptionItem[]);
  const [optionColor, setOptionColor] = useState(
    "dark" as "dark" | "success" | "danger" | "tertiary"
  );
  // |----- OPTION AS ITEM STATES -----|
  const [currentOptionItemIndex, setCurrentOptionItemIndex] = useState(-1);
  const [optionItemEditName, setOptionItemEditName] = useState("");
  const [optionItemEditPrice, setOptionItemEditPrice] = useState("");
  // Utility state
  const [modalClosingTrigger, setModalClosingTrigger] = useState(false);

  // Utility methods
  const [present] = useIonAlert();
  const toggleModalClosingTrigger = () => {
    setModalClosingTrigger(!modalClosingTrigger);
  };
  const resetContext = () => {
    setOptionToUpdateId("");
    setOptionName("");
    setOptionType("ONE");
    setOptionIsMandatory(false);
    setOptionItemEditIsUnavailable(false);
    setOptionItems([]);
    setEditMode("ADD");
    setOptionItemEditName("");
    setOptionItemEditPrice("");
    setCurrentOptionItemIndex(-1);
    setIsBusy(false);
  };

  // |----- OPTION AS ITEM -----|
  // Data setters
  const optionItemEditNameInputHandler = (name: string) => {
    setOptionItemEditName(name);
  };
  const optionItemEditPriceInputHandler = (price: string) => {
    setOptionItemEditPrice(price);
  };
  const optionItemEditIsUnavailableChangeHandler = (isUnavailable: boolean) => {
    setOptionItemEditIsUnavailable(isUnavailable);
  };

  // Option Item selector
  const selectOptionItem = (optionItemIndex: number) => {
    if (currentOptionItemIndex == optionItemIndex) {
      setCurrentOptionItemIndex(-1);
      setOptionItemEditName("");
      setOptionItemEditPrice("");
      setOptionItemEditIsUnavailable(false);
    } else {
      setCurrentOptionItemIndex(optionItemIndex);
      setOptionItemEditName(optionItems[optionItemIndex].name);
      setOptionItemEditPrice(
        (optionItems[optionItemIndex].price / 100).toString()
      );
      setOptionItemEditIsUnavailable(
        optionItems[optionItemIndex].unavailable || false
      );
    }
  };
  const unselectOptionItem = () => {
    setCurrentOptionItemIndex(-1);
  };
  // Option item quasi-CRUD manager
  const addOptionItem = () => {
    setOptionItems([
      ...optionItems,
      {
        id: uuidv4(),
        name: optionItemEditName,
        price: +optionItemEditPrice * 100,
        color: optionColor,
        unavailable: optionItemEditIsUnavailable,
      },
    ]);
    setOptionItemEditName("");
    setOptionItemEditPrice("");
    setOptionItemEditIsUnavailable(false);
    setCurrentOptionItemIndex(-1);
  };
  const updateOptionItem = () => {
    const newOptionItems = [...optionItems];
    newOptionItems[currentOptionItemIndex].name = optionItemEditName;
    newOptionItems[currentOptionItemIndex].unavailable =
      optionItemEditIsUnavailable;
    newOptionItems[currentOptionItemIndex].price = +optionItemEditPrice * 100;
    newOptionItems[currentOptionItemIndex].color = optionColor;
    setOptionItems(newOptionItems);
    setOptionItemEditName("");
    setOptionItemEditPrice("");
    setOptionItemEditIsUnavailable(false);
    setCurrentOptionItemIndex(-1);
  };
  const removeOptionItem = (optionItemIndex: number) => {
    const newOptionItems = [...optionItems];
    newOptionItems.splice(optionItemIndex, 1);
    setOptionItemEditName("");
    setOptionItemEditPrice("");
    setOptionItemEditIsUnavailable(false);
    setCurrentOptionItemIndex(-1);
    setOptionItems(newOptionItems);
  };

  // |----- OPTIONS AS GROUP -----|
  // Update mode
  const setAsUpdate = (idToUpdate: string) => {
    setOptionToUpdateId(idToUpdate);
    setEditMode("UPDATE");
  };
  // Data setters
  const optionNameInputHandler = (name: string) => {
    setOptionName(name);
  };
  const optionTypeChangeHandler = (type: OptionType) => {
    setOptionType(type);
  };

  const optionIsMandatoryChangeHandler = (isMandatory: boolean | false) => {
    setOptionIsMandatory(isMandatory);
  };

  const colorChangeHandler = () => {
    switch (optionColor) {
      case "dark":
        setOptionColor("success");
        break;
      case "success":
        setOptionColor("danger");
        break;
      case "danger":
        setOptionColor("tertiary");
        break;
      case "tertiary":
        setOptionColor("dark");
        break;
      default:
        setOptionColor("success");
        break;
    }
  };
  // Options GROUP CRUD handler
  const createOptionsHandler = (productsOptions: ProductsOptions) => {
    setIsBusy(true);
    productOptionsSevices
      .createNewProductsOptions(authCtx.restaurantId, productsOptions)
      .then((success: BaseAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      })
      .catch((error: BaseAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      });
  };
  const prefillForUpdate = (productsOptions: ProductsOptions) => {
    setOptionName(productsOptions.name);
    setOptionType(productsOptions.type);
    setOptionIsMandatory(
      productsOptions.isMandatory ? productsOptions.isMandatory : false
    );
    setOptionItems([...productsOptions.optionItems]);
    setOptionColor(
      productsOptions.color as "dark" | "success" | "danger" | "tertiary"
    );
    setOptionToUpdateId(productsOptions.id);
    setEditMode("UPDATE");
  };
  const updateOptionsHandler = (productsOptions: ProductsOptions) => {
    setIsBusy(true);
    productOptionsSevices
      .updateProductsOptions(
        optionToUpdateId,
        authCtx.restaurantId,
        productsOptions
      )
      .then((success: BaseAlert) => {
        setIsBusy(false);
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      })
      .catch((error: BaseAlert) => {
        setIsBusy(false);
        console.log(error);
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            toggleModalClosingTrigger();
          },
        });
      });
  };
  const deleteOptionHandler = () => {
    // NOTE should be delete optionSHandler for more clarity ?
    present({
      header: i18n.t("AddProductOptionPage.ConfirmDeletion"),
      message: i18n.t("AddProductOptionPage.DeleteOptionGroup"),
      buttons: [
        i18n.t("AppSettingsOrderHistory.cancelled"),
        {
          text: "Ok",
          handler: (d) => {
            setTimeout(() => {
              setIsBusy(true);
              productOptionsSevices
                .deleteProductsOptions(optionToUpdateId, authCtx.restaurantId)
                .then((success: BaseAlert) => {
                  setIsBusy(false);
                  present({
                    header: success.header,
                    message: success.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => {
                      toggleModalClosingTrigger();
                    },
                  });
                })
                .catch((error: BaseAlert) => {
                  console.log(error);
                  setIsBusy(false);
                  present({
                    header: error.header,
                    message: error.detail,
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => {
                      toggleModalClosingTrigger();
                    },
                  });
                });
            }, 150);
          },
        },
      ],
      onDidDismiss: (e) => {},
    });
  };
  const formSubmitHandler = () => {
    if (!isBusy) {
      const productsOptions = new ProductsOptions(
        "",
        optionName,
        optionType as OptionType,
        optionItems,
        optionIsMandatory,
        optionColor
      );
      return editMode === "ADD"
        ? createOptionsHandler(productsOptions)
        : updateOptionsHandler(productsOptions);
    }
  };

  return (
    <EditProductOptionsContext.Provider
      value={{
        isBusy,
        editMode,
        optionToUpdateId,
        optionName,
        optionType,
        optionIsMandatory,
        optionItemEditIsUnavailable,
        optionItems,
        optionColor,
        currentOptionItemIndex,
        optionItemEditName,
        optionItemEditPrice,
        modalClosingTrigger,
        setAsUpdate,
        optionNameInputHandler,
        formSubmitHandler,
        deleteOptionHandler,
        resetContext,
        optionTypeChangeHandler,
        optionIsMandatoryChangeHandler,
        optionItemEditIsUnavailableChangeHandler,
        colorChangeHandler,
        removeOptionItem,
        addOptionItem,
        updateOptionItem,
        selectOptionItem,
        unselectOptionItem,
        optionItemEditNameInputHandler,
        optionItemEditPriceInputHandler,
        prefillForUpdate,
      }}
    >
      {props.children}
    </EditProductOptionsContext.Provider>
  );
};

export default EditProductOptionsContext;
