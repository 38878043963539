import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import routes from "./routes";
/* Pages components*/
import StartPage from "./pages/Start/StartPage";
import SignUpPage from "./pages/Start/SignUpPage/SignUpPage";
import SignInPage from "./pages/Start/SignInPage/SignInPage";
import ResetPasswordPage from "./pages/Start/ResetPasswordPage/ResetPasswordPage";
import Home from "./pages/Home/Home";
import ProductEditionPage from "./pages/Contextual/ProductEditionPage/ProductEditionPage";
import RootProvider from "./contexts/@store/RootProvider";
import NewOrderPage from "./pages/Contextual/NewOrderPage/NewOrderPage";
import NewDeliveryPage from "./pages/Contextual/NewDeliveryPage/NewDeliveryPage";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
const App: React.FC = () => {
  return (
    <RootProvider>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path={routes.NEW_DELIVERY_PAGE}>
              <NewDeliveryPage />
            </Route>
            <Route path={routes.NEW_ORDER_PAGE}>
              <NewOrderPage />
            </Route>
            <Route path={routes.PRODUCT_EDITION_PAGE}>
              <ProductEditionPage />
            </Route>
            <Route path={routes.HOME_PAGE}>
              <Home />
            </Route>
            <Route exact path={routes.START_PAGE}>
              <StartPage />
            </Route>
            <Route exact path={routes.SIGN_UP_PAGE}>
              <SignUpPage />
            </Route>
            <Route exact path={routes.SIGN_IN_PAGE}>
              <SignInPage />
            </Route>
            <Route exact path={routes.RESET_PASSWORD_PAGE}>
              <ResetPasswordPage />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </RootProvider>
  );
};

export default App;
