import React, { useContext, useEffect, useState } from "react";
import signinFormSchema from "../forms/signin.json";
import { ErrorAlert, InputSchema, SignInResponse } from "../types";
import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";
import routes from "../routes";
import API from "../services";
import AuthContext from "./auth.ctx";

import authService from "../services/apis/authServices";

const formSchema = signinFormSchema as InputSchema[];

const SignInContext = React.createContext({
  isBusy: false,
  formSchema,
  formSubmitHandler: (email: string, password: string) => {},
});

export const SignInContextProvider: React.FC = (props) => {
  const [isBusy, setIsBusy] = useState(false);

  // Utility alert method
  const [present] = useIonAlert();

  const formSubmitHandler = (email: string, password: string) => {
    if (!isBusy) {
      setIsBusy(true);
      authService
        .signInWithEmailAndPassword(email, password)
        .then((success: SignInResponse) => {
          setIsBusy(false);
        })
        .catch((error: ErrorAlert) => {
          setIsBusy(false);
          console.log(error);
          present({
            header: error.header,
            message: error.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {},
          });
        });
    }
  };

  return (
    <SignInContext.Provider
      value={{
        isBusy,
        formSchema,
        formSubmitHandler,
      }}
    >
      {props.children}
    </SignInContext.Provider>
  );
};

export default SignInContext;
