import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import QRCode from "react-qr-code";
import "./RestaurantQRCodeModal.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  qrString: string;
  onDismiss: Function;
}

const RestaurantQRCodeModal: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="restaurant-q-r-code-modal"
      data-testid="restaurant-q-r-code-modal"
    >
      <BaseModalHeader onDismiss={() => props.onDismiss()}>
        {i18n.t("AppDelivery.addDeliveryPerson")}
      </BaseModalHeader>
      <div className="restaurant-q-r-code-modal__content">
        <div className="restaurant-q-r-code-modal__content__text">
          <span>{i18n.t("AppDelivery.toAddDeliveryPerson")}</span>
        </div>
        <div className="restaurant-q-r-code-modal__content__qr">
          <QRCode value={props.qrString} />
        </div>
      </div>
    </div>
  );
};

export default RestaurantQRCodeModal;
