import Courier from "../../models/Courier";
import CustomerInfos from "../../models/CustomerInfos";
import Order from "../../models/Order";
import Product from "../../models/Product";
import { CourierEarnings, SuccessAlert } from "../../types";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
export const happyBurgerRestaurantId = "9fce979a-02f0-4156-b33d-9f2c215bf8cf";
const orderServices = {
  listenToNowOrders(restaurantId: string, listenerCallback: Function) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("orders")
      .where("status", "in", [
        "RECORDED",
        "READY",
        "OUT",
        "DELIVERED",
        "NOT_IN_PRODUCTION",
      ])
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];
        querySnasphot.forEach((doc) => {
          if (
            doc.data().order_planning_type == "NOW" ||
            !("order_planning_type" in doc.data()) ||
            (doc.data().order_planning_type == "PLANNED" &&
              doc.data().should_start_order == true)
          ) {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  getClosedOrderFromDateRange(
    restaurantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<Order[]> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .where("status", "in", ["CLOSED", "CANCELED"])
        .where("created_at", ">=", startDate)
        .where("created_at", "<=", endDate)
        .orderBy("created_at", "desc")
        .get()
        .then((querySnasphot) => {
          let orders: Order[] = [];
          querySnasphot.forEach((doc) => {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          });
          res(orders);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  },
  listenToPlannedOrders(restaurantId: string, listenerCallback: Function) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("orders")
      .where("status", "in", [
        "RECORDED",
        "READY",
        "OUT",
        "DELIVERED",
        "NOT_IN_PRODUCTION",
      ])
      .orderBy("order_planned_time", "asc")
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];
        querySnasphot.forEach((doc) => {
          if (
            doc.data().order_planning_type == "PLANNED" &&
            doc.data().should_start_order == false
          ) {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  listenToPendingOrders(restaurantId: string, listenerCallback: Function) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("orders")
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];
        querySnasphot.forEach((doc) => {
          if (doc.data().status == "READY") {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  listenToInDeliveryOrders(
    restaurantId: string,
    courierId: string,
    listenerCallback: Function
  ) {
    const ordersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("orders")
      .where("status", "in", ["OUT", "DELIVERED"])
      .onSnapshot((querySnasphot) => {
        let orders: Order[] = [];
        querySnasphot.forEach((doc) => {
          if (doc.data().courier?.id == courierId) {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          }
        });
        listenerCallback(orders);
      });
    return ordersListener;
  },
  getOrderFromDateRange(
    restaurantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<Order[]> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .where("created_at", ">=", startDate)
        .where("created_at", "<=", endDate)
        .orderBy("created_at", "desc")
        .get()
        .then((querySnasphot) => {
          let orders: Order[] = [];
          querySnasphot.forEach((doc) => {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          });
          res(orders);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  },
  createNewOrder(
    restaurantId: string,
    orderType: string,
    cart: Product[],
    paymentType: string,
    orderPlanningType: string,
    orderPlannedTime: Date,
    shouldStartOrder: boolean,
    customer?: CustomerInfos,
    productionMode?: boolean,
    deliveryFees?: number,
    totalPrice?: number
  ): Promise<{ success: SuccessAlert; order: Order }> {
    return new Promise(async (res, rej) => {
      const generatedId = uuidv4();
      const productsPayload = cart.map((product) => {
        return {
          id: product.id,
          name: product.name,
          category: product.categoryId,
          price: product.price,
          picture_url: product.pictureUrl,
          thumbnail_url: product.thumbnailUrl,
          quantity: product.quantity,
          has_options: product.hasOptions,
          products_options_ids: product.productsOptionsIds || [],
          options: product.optionsPreset || [],
        };
      });
      let customerPayload: any = null;
      if (customer?.id) {
        customerPayload = {
          id: customer.id,
          firstname: customer.firstname,
          lastname: customer.lastname,
          phone: customer.phone,
          address_city: customer.addressCity,
          address_first_line: customer.addressFirstLine,
          address_second_line: customer.addressSecondLine,
          address_latitude: customer.addressLatitude,
          address_longitude: customer.addressLongitude,
          address_place_id: customer.addressPlaceId,
          address_zipcode: customer.addressZipcode,
          distance_to_restaurant: customer.distanceToRestaurant,
        };
      }
      // Fetch Last Order To Get A Number
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .orderBy("created_at", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          let lastNumber = 0;
          querySnapshot.forEach((doc) => {
            lastNumber = doc.data().number;
          });
          const orderNumber = lastNumber + 1;
          const orderPlannedTimeTimestamp =
            firebase.firestore.Timestamp.fromDate(orderPlannedTime);
          const newOrderPayload = {
            number: orderNumber,
            payment_type: paymentType,
            order_planning_type: orderPlanningType,
            order_planned_time: orderPlannedTimeTimestamp,
            order_type: orderType,
            should_start_order: shouldStartOrder,
            status: productionMode ? "NOT_IN_PRODUCTION" : "RECORDED",
            products: productsPayload,
            customer: customerPayload,
            courier: null,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            delivery_fees: deliveryFees,
            total_price: totalPrice,
          };
          $firestore
            .collection("restaurants")
            .doc(restaurantId)
            .collection("orders")
            .doc(generatedId)
            .set({
              ...newOrderPayload,
            })
            .then(() => {
              $firestore
                .collection("restaurants")
                .doc(restaurantId)
                .collection("orders")
                .doc(generatedId)
                .get()
                .then((doc) => {
                  if (doc && doc.exists) {
                    const products = doc
                      .data()
                      ?.products.map((product: Record<any, any>) => {
                        return new Product(
                          product.id,
                          product.name,
                          product.price,
                          product.basePrice || product.price,
                          product.category_id,
                          product.has_options,
                          product.picture_url,
                          product.thumbnail_url,
                          product.quantity,
                          product.description,
                          product.products_options_ids,
                          product.options,
                          product.unavailable,
                          product.long_desc
                        );
                      }) as Product[];
                    const customer = new CustomerInfos(
                      doc.data()?.customer?.id,
                      doc.data()?.customer?.phone,
                      doc.data()?.customer?.firstname,
                      doc.data()?.customer?.lastname,
                      doc.data()?.customer?.address_first_line,
                      doc.data()?.customer?.address_second_line,
                      doc.data()?.customer?.address_zipcode,
                      doc.data()?.customer?.address_city,
                      doc.data()?.customer?.address_latitude,
                      doc.data()?.customer?.address_longitude,
                      doc.data()?.customer?.address_place_id,
                      doc.data()?.customer?.distance_to_restaurant
                    );
                    const courier = new Courier(
                      doc.data()?.courier?.id,
                      doc.data()?.courier?.firstname,
                      doc.data()?.courier?.lastname,
                      false,
                      doc.data()?.courier?.profile_picture_url,
                      doc.data()?.courier?.profile_thumbnail_url,
                      doc.data()?.courier?.phone
                    );
                    const createdOrder = new Order(
                      doc.id,
                      doc.data()?.number,
                      doc.data()?.payment_type,
                      doc.data()?.order_type,
                      doc.data()?.order_planning_type,
                      doc.data()?.order_planned_time?.toDate(),
                      doc.data()?.should_start_order,
                      doc.data()?.status,
                      doc.data()?.created_at?.toDate(),
                      products,
                      customer,
                      courier
                    );
                    res({
                      success: success.ORDER_CREATION_SUCCESS_ALERT,
                      order: createdOrder,
                    });
                  } else {
                    rej(errors.ORDER_CREATION_ERROR_ALERT);
                  }
                })
                .catch((e) => {
                  console.log(e);
                  rej(errors.ORDER_CREATION_ERROR_ALERT);
                });
            })
            .catch((e) => {
              console.log(e);
              rej(errors.ORDER_CREATION_ERROR_ALERT);
            });
        })
        .catch((e) => {
          console.log("Can't retrieve latest order" + e);
          rej(errors.ORDER_CREATION_RETRIEVE_ERROR_ALERT);
        });
    });
  },
  setOrderAsReady(
    orderId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            status: "READY",
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  setOrderAsPaid(orderId: string, restaurantId: string): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            special_status: "",
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  setOrderAsClosed(
    orderId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            status: "CLOSED",
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  setOrderToProduction(
    orderId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            status: "RECORDED",
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  setOrderAsToStart(
    orderId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            should_start_order: true,
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  setOrderAsCanceled(
    orderId: string,
    restaurantId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            status: "CANCELED",
          },
          { merge: true }
        )
        .then(() => {
          res(success.ORDER_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          rej(errors.ORDER_UPDATE_ERROR_ALERT);
        });
    });
  },
  getTotalAmountsOfDay(
    restaurantId: string,
    startDate: Date,
    endDate?: Date | null
  ): Promise<{
    totalCash: number;
    totalCard: number;
    totalStripe: number;
    totalOfDay: number;
  }> {
    if (!endDate) {
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);
    }

    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .where("created_at", ">=", startDate)
        .where("created_at", "<=", endDate)
        .get()
        .then((querySnasphot) => {
          let orders: Order[] = [];
          querySnasphot.forEach((doc) => {
            const products = doc
              .data()
              .products.map((product: Record<any, any>) => {
                return new Product(
                  product.id,
                  product.name,
                  product.price,
                  product.basePrice || product.price,
                  product.category_id,
                  product.has_options,
                  product.picture_url,
                  product.thumbnail_url,
                  product.quantity,
                  product.description,
                  product.products_options_ids,
                  product.options,
                  product.unavailable,
                  product.long_desc
                );
              }) as Product[];
            const customer = new CustomerInfos(
              doc.data().customer?.id,
              doc.data().customer?.phone,
              doc.data().customer?.firstname,
              doc.data().customer?.lastname,
              doc.data().customer?.address_first_line,
              doc.data().customer?.address_second_line,
              doc.data().customer?.address_zipcode,
              doc.data().customer?.address_city,
              doc.data().customer?.address_latitude,
              doc.data().customer?.address_longitude,
              doc.data().customer?.address_place_id,
              doc.data().customer?.distance_to_restaurant
            );
            const courier = new Courier(
              doc.data().courier?.id,
              doc.data().courier?.firstname,
              doc.data().courier?.lastname,
              false,
              doc.data().courier?.profile_picture_url,
              doc.data().courier?.profile_thumbnail_url,
              doc.data().courier?.phone
            );
            orders.push(
              new Order(
                doc.id,
                doc.data().number,
                doc.data().payment_type,
                doc.data().order_type,
                doc.data().order_planning_type,
                doc.data().order_planned_time?.toDate(),
                doc.data().should_start_order,
                doc.data().status,
                doc.data().created_at?.toDate(),
                products,
                customer,
                courier,
                undefined,
                doc.data().special_status,
                doc.data().delivery_fees,
                doc.data().total_price,
                doc.data().tip
              )
            );
          });
          let totalCash = 0;
          let totalCard = 0;
          let totalStripe = 0;
          let totalOfDay = 0;
          orders.map((order: Order) => {
            order.products.map((product: Product) => {
              totalOfDay = totalOfDay + product.price;
              switch (order.paymentType) {
                case "CASH":
                  totalCash = totalCash + product.price;
                  break;
                case "CARD":
                  totalCard = totalCard + product.price;
                  break;
                case "STRIPE":
                  totalStripe = totalStripe + product.price;
                  break;
              }
            });
          });
          res({ totalCash, totalCard, totalStripe, totalOfDay });
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  },
  getCourierEarnings(
    restaurantId: string,
    startDate: Date,
    endDate?: Date | null
  ): Promise<CourierEarnings[]> {
    if (!endDate) {
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);
    }
    const courierEarnings: CourierEarnings[] = [];
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("couriers")
        .get()
        .then(async (courierSnaps) => {
          for (const courierSnap of courierSnaps.docs) {
            const courier = new Courier(
              courierSnap.data()?.id,
              courierSnap.data()?.firstname,
              courierSnap.data()?.lastname,
              false,
              courierSnap.data()?.profile_picture_url,
              courierSnap.data()?.profile_thumbnail_url,
              courierSnap.data()?.phone
            );

            const courierOrder = await $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("orders")
              .where("courier.id", "==", courierSnap.id)
              .where("created_at", ">=", startDate)
              .where("created_at", "<=", endDate)
              .get();
            let orders: Order[] = [];
            courierOrder.forEach((doc) => {
              const products = doc
                .data()
                .products.map((product: Record<any, any>) => {
                  return new Product(
                    product.id,
                    product.name,
                    product.price,
                    product.basePrice || product.price,
                    product.category_id,
                    product.has_options,
                    product.picture_url,
                    product.thumbnail_url,
                    product.quantity,
                    product.description,
                    product.products_options_ids,
                    product.options,
                    product.unavailable,
                    product.long_desc
                  );
                }) as Product[];
              const customer = new CustomerInfos(
                doc.data().customer?.id,
                doc.data().customer?.phone,
                doc.data().customer?.firstname,
                doc.data().customer?.lastname,
                doc.data().customer?.address_first_line,
                doc.data().customer?.address_second_line,
                doc.data().customer?.address_zipcode,
                doc.data().customer?.address_city,
                doc.data().customer?.address_latitude,
                doc.data().customer?.address_longitude,
                doc.data().customer?.address_place_id,
                doc.data().customer?.distance_to_restaurant
              );

              orders.push(
                new Order(
                  doc.id,
                  doc.data().number,
                  doc.data().payment_type,
                  doc.data().order_type,
                  doc.data().order_planning_type,
                  doc.data().order_planned_time?.toDate(),
                  doc.data().should_start_order,
                  doc.data().status,
                  doc.data().created_at?.toDate(),
                  products,
                  customer,
                  courier,
                  undefined,
                  doc.data().special_status,
                  doc.data().delivery_fees,
                  doc.data().total_price,
                  doc.data().tip
                )
              );
            });

            let totalOfDay = 0;
            let totalTip = 0;
            let totalDelivery = 0;
            orders.map((order: Order) => {
              totalTip = order.tip ? totalTip + order.tip : totalTip;
              totalDelivery = order.delivery_fees
                ? totalDelivery + order.delivery_fees
                : totalDelivery;
              totalOfDay = totalTip + totalDelivery;
            });
            courierEarnings.push({
              courier: courier,
              totalDelivery,
              totalTip,
              totalOfDay,
            });
          }
          res(courierEarnings);
        })

        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  },
};
export default orderServices;
