import "./DeliveryDelaySelector.css";

interface ContainerProps {
  onChange: Function;
  value: number;
}

const DeliveryDelaySelector: React.FC<ContainerProps> = (props) => {
  const minusDeliveryTimeHandler = () => {
    if (props.value > 0) {
      props.onChange(props.value - 5);
    }
  };
  const plusDeliveryTimeHandler = () => {
    props.onChange(props.value + 5);
  };

  return (
    <div className="delivery-delay-selector">
      <div className="delivery-delay-selector__delivery-time-updater">
        <div
          className="delivery-delay-selector__delivery-time-updater__diminish"
          onClick={minusDeliveryTimeHandler}
        >
          -
        </div>
        <div style={{ padding: "0 0.5rem" }}>{props.value} min</div>

        <div
          className="delivery-delay-selector__delivery-time-updater__raise"
          onClick={plusDeliveryTimeHandler}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default DeliveryDelaySelector;
