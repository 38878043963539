import { IonIcon } from "@ionic/react";
import { close, expandOutline } from "ionicons/icons";
import "./BaseModalHeader.css";

interface ContainerProps {
  onDismiss: Function;
  expandIcon?: boolean;
  expandAction?: Function;
  isFullScreen?: boolean;
}

const BaseModalHeader: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-modal-header" data-testid="base-modal-header">
      <span className="base-modal-header__title">{props.children}</span>
      <div className="base-modal-header__action-container">
        {props.expandIcon && props.expandAction && (
          <div
            className="base-modal-header__close-icon"
            onClick={() => props.expandAction?.()}
          >
            <IonIcon icon={expandOutline} />
          </div>
        )}
        <div
          className="base-modal-header__close-icon"
          onClick={() => props.onDismiss()}
        >
          <IonIcon icon={close} />
        </div>
      </div>
    </div>
  );
};

export default BaseModalHeader;
