import React, { useEffect, useState } from "react";
import API from "../services";
import { $firebaseAuth } from "../services/app/firebase-service";

export enum AuthStates {
  "AUTH",
  "NOT_AUTH",
  "NOT_INIT",
}

const AuthContext = React.createContext({
  authState: AuthStates.NOT_INIT,
  role: "",
  userRole: "",
  restaurantId: "",
  userId: "",
  userEmail: "",
  signOut: () => {},
  setRestaurantIdhandler: (id: string) => {},
});

export const AuthContextProvider: React.FC = (props) => {
  const [authState, setAuthState] = useState(AuthStates.NOT_INIT);
  const [role, setRole] = useState("");
  const [userRole, setUserRole] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  // TODO : Add a props for premium user status
  useEffect(() => {
    const authStateChangedUnsubscribe = $firebaseAuth.onAuthStateChanged(
      (user) => {
        if (!user) {
          setAuthState(AuthStates.NOT_AUTH);
          setRole("");
          setUserRole("");
          setRestaurantId("");
          setUserId("");
        } else {
          user.getIdTokenResult().then((token) => {
            setAuthState(AuthStates.AUTH);
            setRole(token.claims.role);
            setUserRole(token.claims.user_role);
            setRestaurantId(token.claims.restaurant_id);
            setUserId(user.uid);
            setUserEmail(user.email || "");
            // TODO : Set the user premium status
          });
        }
      }
    );
    return () => authStateChangedUnsubscribe();
  }, []);

  const signOutHandler = () => {
    $firebaseAuth.signOut();
  };

  const setRestaurantIdhandler = (id: string) => {
    setRestaurantId(id);
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        role,
        userRole,
        restaurantId,
        userId,
        userEmail,
        signOut: signOutHandler,
        setRestaurantIdhandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
