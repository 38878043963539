import axios from "axios";
import { $firebaseAuth } from "../app/firebase-service";
import buildEnv from "../../build.env.json";
const apiEndpoints = {
  cancelSubscription: {
    dev: "https://us-central1-delizi-dev.cloudfunctions.net/sellerApi/createSmsCheckoutSessionDev",
    prod: "https://us-central1-delizi-master.cloudfunctions.net/sellerApi/createSmsCheckoutSession",
  },
};

const smsServices = {
  purchaseSms(): Promise<string> {
    return new Promise((res, rej) => {
      $firebaseAuth.currentUser?.getIdToken().then((idToken) => {
        if (idToken) {
          const apiEndpoint =
            buildEnv.BUILD_ENV == "DEV"
              ? apiEndpoints.cancelSubscription.dev
              : apiEndpoints.cancelSubscription.prod;
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          axios
            .post(
              apiEndpoint,
              {},
              {
                headers: headers,
              }
            )
            .then((response) => {
              res(response.data.url);
            })
            .catch((err) => {
              console.log(err);
              rej(err);
            });
        } else {
          rej("No id token");
        }
      });
    });
  },
};
export default smsServices;
