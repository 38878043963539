import "./CourierNoStateItem.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const CourierNoStateItem: React.FC<ContainerProps> = (props) => {
  return (
    <div className="courier-no-state-item" data-testid="courier-no-state-item">
      <span>{i18n.t("AppDelivery.noDeliveryPersonRegisteredOrWaiting")}</span>
    </div>
  );
};

export default CourierNoStateItem;
