import { useContext, useEffect, useState } from "react";
import EditProductContext from "../../../contexts/edit-product.ctx";
import ProductsPageContext from "../../../contexts/products-page.ctx";
import Product from "../../../models/Product";
import ProductsGridItem from "../ProductsGridItem/ProductsGridItem";
import ProductsGridNoStateItem from "../ProductsGridNoStateItem/ProductsGridNoStateItem";
import "./ProductsGrid.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  onItemSelect: Function;
  isInOrderMode?: boolean;
}

const ProductsGrid: React.FC<ContainerProps> = (props) => {
  const productsPageCtx = useContext(ProductsPageContext);

  const selectGridItemHandler = (product: Product) => {
    props.onItemSelect(product);
  };

  const getClasses = () => {
    const base = ["products-grid"];
    if (props.isInOrderMode) {
      base.push("products-grid--order-mode");
    }
    return base.join(" ");
  };

  const getGridClasses = () => {
    const base = ["products-grid__grid"];
    if (props.isInOrderMode) {
      base.push("products-grid__grid--order-mode");
    }
    return base.join(" ");
  };

  return (
    <div className={getClasses()} data-testid="products-grid">
      <span>{i18n.t("AppMenu.products")}</span>
      <div className={getGridClasses()}>
        {productsPageCtx.currentProductsList.length ? (
          productsPageCtx.currentProductsList.map((product: Product, index) => {
            return (
              <ProductsGridItem
                key={product.id}
                name={product.name}
                description={product.description}
                price={product.price}
                basePrice={product.basePrice}
                hasOptions={product.hasOptions}
                pictureUrl={product.thumbnailUrl}
                unavailable={product.unavailable}
                onClick={() => selectGridItemHandler(product)}
                isCartEdit={!props.isInOrderMode}
                longDesc={product.longDesc}
              />
            );
          })
        ) : (
          <ProductsGridNoStateItem />
        )}
      </div>
    </div>
  );
};

export default ProductsGrid;
