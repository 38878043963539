import { PromoCode, SuccessAlert } from "../../types";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import Courier from "../../models/Courier";
import Order from "../../models/Order";

const deliveryServices = {
  createDelivery(
    restaurantId: string,
    courier: Courier,
    orders: Order[]
  ): Promise<SuccessAlert> {
    // Sequentially resolve all of the updates
    return new Promise((res, rej) => {
      orders
        .map((order) => {
          return () => {
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("orders")
              .doc(order.id)
              .set(
                {
                  status: "OUT",
                  courier: {
                    id: courier.id,
                    firstname: courier.firstname,
                    lastname: courier.lastname,
                    profile_picture_url: courier.profilePictureUrl,
                    profile_thumbnail_url: courier.profileThumbnailUrl,
                    phone: courier.phone,
                  },
                  updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              );
          };
        })
        .reduce((prev, task) => {
          return prev.then(task).catch((err) => {
            console.log(err);
            rej(errors.DELIVERY_CREATION_ERROR_ALERT);
          });
        }, Promise.resolve());

      res(success.DELIVERY_CREATION_SUCCESS_ALERT);
    });
  },
  updateOrderAsDelivered(
    restaurantId: string,
    orderId: string
  ): Promise<SuccessAlert> {
    // Sequentially resolve all of the updates
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("orders")
        .doc(orderId)
        .set(
          {
            status: "DELIVERED",
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.DELIVERY_UPDATE_SUCCESS_ALERT);
        })
        .catch(() => {
          res(errors.DELIVERY_UPDATE_ERROR_ALERT);
        });
    });
  },
  closeDelivery(restaurantId: string, orders: Order[]): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      orders
        .map((order) => {
          return () => {
            let updatePayload: {
              status: string;
              updated_at: firebase.firestore.FieldValue;
              courier?: any;
            } = {
              status: order.status == "DELIVERED" ? "CLOSED" : "READY",
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            };
            if (order.status != "DELIVERED") {
              updatePayload = {
                ...updatePayload,
                courier: {
                  id: "",
                  firstname: "",
                  lastname: "",
                  profile_picture_url: "",
                  profile_thumbnail_url: "",
                  phone: "",
                },
              };
            }
            $firestore
              .collection("restaurants")
              .doc(restaurantId)
              .collection("orders")
              .doc(order.id)
              .set(updatePayload, { merge: true });
          };
        })
        .reduce((prev, task) => {
          return prev.then(task).catch((err) => {
            console.log(err);
            rej(errors.DELIVERY_CLOSING_ERROR_ALERT);
          });
        }, Promise.resolve());
      res(success.DELIVERY_CLOSING_SUCCESS_ALERT);
    });
  },
};

export default deliveryServices;
