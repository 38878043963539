import React, { useContext, useEffect, useState } from "react";
import { ErrorAlert, SuccessAlert } from "../types";
import AuthContext from "./auth.ctx";
import { getPlatforms, useIonAlert } from "@ionic/react";
import CustomerInfos from "../models/CustomerInfos";
import Product from "../models/Product";
import Order from "../models/Order";
import PosPrint from "../services/app/pos-print";
import RestaurantDataContext from "./restaurant-data.ctx";
import orderServices from "../services/apis/orderServices";
import { getAmountAndFees } from "../helpers/getTotalPriceFromOrder";
import { GCS } from "../services";
type OrderType = "DELIVERY" | "ON_PLACE";
type OrderPlanningType = "NOW" | "PLANNED";

const equalsArrayIgnoreOrder = (
  a: Array<any> | undefined,
  b: Array<any> | undefined
) => {
  if (a && b) {
    let result =
      a.length === b.length &&
      a.every(function (element) {
        return b.includes(element);
      });
    return result;
  } else if (a == b) {
    return true;
  } else {
    return false;
  }
};

const NewOrderContext = React.createContext({
  isBusy: false,
  orderType: "ON_PLACE",
  customer: new CustomerInfos("", "", "", "", "", "", "", "", 0, 0, "", 0),
  cart: [] as Product[],
  paymentType: "",
  orderPlanningType: "NOW",
  orderPlannedTime: new Date(),
  shouldStartOrder: true,
  redirectToOrdersTrigger: false,
  orderTypeSelectionHandler: (type: OrderType) => {},
  orderPlanningTypeSelectionHandler: (type: OrderPlanningType) => {},
  orderPlanningTimeSelectionHandler: (type: Date) => {},
  customerSelectionHandler: (customer: CustomerInfos) => {},
  addProductToCart: (product: Product) => {},
  modifProductCart: (product: Product, index: number) => {},
  removeProductFromCart: (index: number) => {},
  paymentTypeSelectionHandler: (paymentType: "CARD" | "CASH") => {},
  resetContext: () => {},
  submitNewOrder: async () => {},
});

export const NewOrderContextProvider: React.FC = (props) => {
  // Auth context needed to get restaurant ID
  const authCtx = useContext(AuthContext);
  const [isBusy, setIsBusy] = useState(false);
  // Restaurant data needed for (remote) POS print data
  const restaurantDataCtx = useContext(RestaurantDataContext);
  // Order data states
  const [orderType, setOrderType] = useState("ON_PLACE");
  // Order planning data states
  const [orderPlanningType, setOrderPlanningType] = useState("NOW");
  const [orderPlannedTime, setOrderPlannedTime] = useState(new Date());
  const [shouldStartOrder, setShouldStartOrder] = useState(true);

  const [customer, setCustomer] = useState(
    new CustomerInfos("", "", "", "", "", "", "", "", 0, 0, "", 0)
  );
  const [cart, setCart] = useState([] as Product[]);
  const [paymentType, setPaymentType] = useState("");

  // Utility methods
  const [redirectToOrdersTrigger, setRedirectToProductsTrigger] =
    useState(false);
  const [present] = useIonAlert();
  const resetContext = () => {
    setOrderType("ON_PLACE");
    setOrderPlanningType("NOW");
    setShouldStartOrder(true);
    setOrderPlannedTime(new Date());
    setCustomer(new CustomerInfos("", "", "", "", "", "", "", "", 0, 0, "", 0));
    setCart([]);
    setIsBusy(false);
  };
  const returnToOrdersPage = () => {
    setRedirectToProductsTrigger(true);
    setRedirectToProductsTrigger(false);
  };

  // Data setters
  const orderTypeSelectionHandler = (type: OrderType) => {
    setOrderType(type);
  };
  const orderPlanningTypeSelectionHandler = (type: OrderPlanningType) => {
    setOrderPlanningType(type);
    if (type == "NOW") {
      setShouldStartOrder(true);
    } else if (type == "PLANNED") {
      setShouldStartOrder(false);
    }
  };
  const orderPlanningTimeSelectionHandler = (date: Date) => {
    setOrderPlannedTime(date);
  };

  const customerSelectionHandler = (customer: CustomerInfos) => {
    setCustomer(customer);
  };
  const addProductToCart = (product: Product) => {
    setCart([...cart, product]);
  };

  const modifProductCart = (product: Product, index: number) => {
    const tmpCart = [...cart];

    tmpCart[index] = product;
    setCart(tmpCart);
  };

  const removeProductFromCart = (index: number) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };
  const paymentTypeSelectionHandler = (paymentType: "CARD" | "CASH") => {
    setPaymentType(paymentType);
  };

  // POS print callable method
  const handleTicketPrint = (order: Order) => {
    PosPrint.printOrderTicket(order, restaurantDataCtx.posMacAddress);
  };

  // Create new order method
  const submitNewOrder = async () => {
    if (!isBusy) {
      setIsBusy(true);

      const priceObject = await getAmountAndFees(
        cart,
        orderType,
        restaurantDataCtx.restaurantInfos,
        customer.distanceToRestaurant
      );
      orderServices
        .createNewOrder(
          authCtx.restaurantId,
          orderType,
          cart,
          paymentType,
          orderPlanningType,
          orderPlannedTime,
          shouldStartOrder,
          customer,
          restaurantDataCtx.restaurantInfos.productionMode,
          priceObject.deliveryFees,
          priceObject.total
        )
        .then((response: { success: SuccessAlert; order: Order }) => {
          setIsBusy(false);
          present({
            header: response.success.header,
            message: response.success.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              const platforms = getPlatforms();
              if (platforms.includes("hybrid")) {
                handleTicketPrint(response.order);
              }
              resetContext();
              returnToOrdersPage();
            },
          });
        })
        .catch((error: ErrorAlert) => {
          setIsBusy(false);
          console.log(error);
          present({
            header: error.header,
            message: error.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              resetContext();
              returnToOrdersPage();
            },
          });
        });
    }
  };

  return (
    <NewOrderContext.Provider
      value={{
        isBusy,
        orderType,
        orderPlanningType,
        orderPlannedTime,
        shouldStartOrder,
        customer,
        cart,
        paymentType,
        redirectToOrdersTrigger,
        orderTypeSelectionHandler,
        orderPlanningTypeSelectionHandler,
        orderPlanningTimeSelectionHandler,
        customerSelectionHandler,
        addProductToCart,
        modifProductCart,
        removeProductFromCart,
        paymentTypeSelectionHandler,
        resetContext,
        submitNewOrder,
      }}
    >
      {props.children}
    </NewOrderContext.Provider>
  );
};

export default NewOrderContext;
