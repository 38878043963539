// REVIEW : fixed bug of multi uniqueness selection
// TODO : add the fix in shop
import { IonModal, useIonAlert } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import Product from "../../../models/Product";
import ProductsOptions, { OptionItem } from "../../../models/ProductsOptions";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import "./OrderProductOptionsModal.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  isOpen: boolean;
  onDismiss: Function;
  product: Product;
  onOptionSubmission: Function;
  isModif?: boolean;
  restaurantDataCtx?: any;
}

const OrderProductOptionsModal: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = props.restaurantDataCtx;
  const [productsOptionsList, setProductsOptionsList] = useState(
    [] as ProductsOptions[]
  );

  const [productQuantity, setProductQuantity] = useState(
    props.product.quantity
  );

  const [productsOptionsSelections, setProductsOptionsSelections] = useState(
    props.product.optionsPreset
      ? props.product.optionsPreset
      : ([] as OptionItem[])
  );

  const [present] = useIonAlert();

  useEffect(() => {
    // NOTE : Color management might be better in the futur
    const productsOptions = [...restaurantDataCtx.productsOptionsList].filter(
      (options) => {
        return props.product.productsOptionsIds?.includes(options.id);
      }
    );
    const coloredProductsOptions: any[] = []; // TODO better type usage
    props.product.productsOptionsIds?.map((pOp) => {
      const coloredOptionItems: {
        name: string;
        id: string;
        price: number;
        color: string;
      }[] = [];
      const index = productsOptions.findIndex((item) => item.id == pOp);
      if (index !== -1) {
        productsOptions[index].optionItems.map((pOpItem: any) => {
          coloredOptionItems.push({
            ...pOpItem,
            color: productsOptions[index].color,
          });
        });
        coloredProductsOptions.push({
          ...productsOptions[index],
          optionItems: coloredOptionItems,
        });
      }
    });
    setProductsOptionsList([...coloredProductsOptions]);
    if (props.product.optionsPreset) {
      setProductsOptionsSelections(props.product.optionsPreset);
    }
    setProductQuantity(props.product.quantity);
  }, [props.product.productsOptionsIds]);

  const productsOptionsSelectionHandler = (
    selection: OptionItem,
    options: ProductsOptions
  ) => {
    if (options.type == "MULTI") {
      if (
        productsOptionsSelections.find((option) => option.id == selection.id)
      ) {
        setProductsOptionsSelections(
          [...productsOptionsSelections].filter((pOp) => pOp.id != selection.id)
        );
      } else {
        setProductsOptionsSelections([...productsOptionsSelections, selection]);
      }
    } else {
      const newSelection = [...productsOptionsSelections];
      const cleanedUpSelection = newSelection.filter((alreadySelected) => {
        if (
          options.optionItems.findIndex(
            (opts) => opts.id == alreadySelected.id
          ) == -1
        ) {
          return alreadySelected;
        }
      });

      if (
        !newSelection.find(
          (alreadySelected) => alreadySelected.id == selection.id
        )
      ) {
        cleanedUpSelection.push(selection);
      }
      setProductsOptionsSelections(cleanedUpSelection);
    }
  };
  const handleAddProduct = () => {
    try {
      const tmpProductsOptionsList = [...productsOptionsList];
      tmpProductsOptionsList.forEach((productOptions) => {
        if (productOptions.isMandatory) {
          let mandatoryOptionSelected = false;
          productOptions.optionItems.forEach((option) => {
            if (
              productsOptionsSelections.findIndex(
                (prodOpts) => prodOpts.id == option.id
              ) !== -1
            ) {
              mandatoryOptionSelected = true;
            }
          });
          if (!mandatoryOptionSelected) {
            throw new Error();
          }
        }
      });
      props.onOptionSubmission(
        props.product,
        productQuantity,
        productsOptionsSelections
      );
      setProductsOptionsSelections([]);
    } catch (err) {
      console.log(err);
      present({
        header: i18n.t("AddProductProductPage.Error"),
        message: i18n.t("AddProductProductPage.MandatoryOptions"),
        buttons: [
          {
            text: "Ok",
            handler: (d) => {},
          },
        ],
        onDidDismiss: (e) => {},
      });
    }
  };
  const feedOptionsPresets = () => {
    if (props.product.optionsPreset) {
      setProductsOptionsSelections(props.product.optionsPreset);
    }
    if (props.product.quantity) {
      setProductQuantity(props.product.quantity);
    }
  };

  return (
    <IonModal
      isOpen={props.isOpen}
      backdropDismiss={false}
      onDidPresent={() => feedOptionsPresets()}
    >
      <div className="order-product-options-modal">
        <BaseModalHeader
          onDismiss={() => {
            props.onDismiss();
            setProductsOptionsSelections([]);
          }}
        >
          {i18n.t("AddProductProductPage.ProductOptions")}
        </BaseModalHeader>
        <div className="order-product-options-modal__options-frame">
          {productsOptionsList.map((options, index) => {
            return (
              <div
                className="order-product-options-modal__options-frame__options"
                key={options.id}
              >
                <span>
                  <span>{options.name}</span>
                  <span>
                    {options.type == "MULTI"
                      ? i18n.t("AddProductProductPage.Multiple")
                      : i18n.t("AddProductProductPage.Unique")}{" "}
                    {options.isMandatory
                      ? i18n.t("AddProductProductPage.Mandatory")
                      : i18n.t("AddProductProductPage.Optional")}{" "}
                  </span>
                </span>
                {options.optionItems.map((option) => {
                  return (
                    <div
                      key={options.id + option.id}
                      onClick={() =>
                        option.unavailable
                          ? null
                          : productsOptionsSelectionHandler(option, options)
                      }
                      className={
                        productsOptionsSelections.find(
                          (pOp) => pOp.id == option.id
                        ) && !option.unavailable
                          ? "order-product-options-modal__options-frame__options__item order-product-options-modal__options-frame__options__item--active"
                          : !option.unavailable
                          ? "order-product-options-modal__options-frame__options__item"
                          : "order-product-options-modal__options-frame__options__item order-product-options-modal__options-frame__options__item--unavailable"
                      }
                      style={{
                        color: !productsOptionsSelections.find(
                          (pOp) => pOp.id == option.id
                        )
                          ? `var(--ion-color-${options.color}-shade)`
                          : undefined,
                      }}
                    >
                      <span>{option.name}</span>
                      <span>
                        {option.price
                          ? "+ " +
                            (option.price / 100).toFixed(2) +
                            ` ${constants.CURRENCY}`
                          : ` - ${constants.CURRENCY}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="order-product-options-modal__quantity-container">
          <div className="order-product-options-modal__quantity-text">
            {i18n.t("AddProductProductPage.Quantity")}
          </div>
          <div className="order-product-options-modal__quantity-setter">
            <div
              className="order-product-options-modal__quantity-plus-minus"
              onClick={() => {
                setProductQuantity(Math.max(productQuantity - 1, 0));
              }}
            >
              -
            </div>
            <div className="order-product-options-modal__quantity-amount">
              {productQuantity}
            </div>
            <div
              className="order-product-options-modal__quantity-plus-minus"
              onClick={() => {
                setProductQuantity(productQuantity + 1);
              }}
            >
              +
            </div>
          </div>
        </div>
        <div className="order-product-options-modal__confirm-button">
          <BaseButton
            expand
            tight
            onClick={() => {
              handleAddProduct();
            }}
          >
            {props.isModif ? "Confirmer la modification" : "Ajouter le produit"}
          </BaseButton>
        </div>
      </div>
    </IonModal>
  );
};

export default OrderProductOptionsModal;
