import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import "./HomeLayout.css";
import { useContext } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import CourierDataContext from "../../../contexts/courier-data.ctx";
type TabActionButton = {
  icon: string;
  action: Function;
};

interface HomeLayoutProps {
  title: string;
  button?: TabActionButton;
  showButton?: boolean;
}

const HomeLayout: React.FC<HomeLayoutProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const courierDataCtx = useContext(CourierDataContext);
  const showNotif = courierDataCtx.pendingOrdersList.length > 0;
  return (
    <IonPage className="products-page" data-testid="products-page">
      <IonHeader
        className="home-layout-header ion-no-border"
        data-testid="home-layout-header"
        mode="ios"
        translucent
      >
        <IonToolbar className="home-layout-header__toolbar" mode="ios">
          <div className="home-layout-header__toolbar-content">
            <h2 className="home-layout-header__toolbar-title">{props.title}</h2>
            {props.button ? (
              <div
                onClick={() => props.button?.action()}
                className={"home-layout-header__action-button"}
              >
                <IonIcon icon={props.button.icon} />
                {authCtx.userRole === "COURIER" && showNotif ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "15px",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      border: "1px solid white",
                    }}
                  ></div>
                ) : null}
              </div>
            ) : (
              <div className="home-layout-header__action-button--empty" />
            )}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>{props.children}</IonContent>
    </IonPage>
  );
};

export default HomeLayout;
