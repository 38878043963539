import Courier from "../../models/Courier";
import { $firestore } from "../app/firebase-service";
import success from "../../messages/success";
import errors from "../../messages/error";
import { SuccessAlert } from "../../types";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import storageServices from "./storageServices";
import CourierInfos from "../../models/CourierInfos";

const courierServices = {
  listenToCouriers(restaurantId: string, listenerCallback: Function) {
    const couriersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("couriers")
      .onSnapshot((querySnasphot) => {
        let couriers: Courier[] = [];
        querySnasphot.forEach((doc) => {
          couriers.push(
            new Courier(
              doc.id,
              doc.data().firstname,
              doc.data().lastname,
              false,
              doc.data().profile_picture_url,
              doc.data().profile_thumbnail_url,
              doc.data().phone
            )
          );
        });
        listenerCallback(couriers);
      });
    return couriersListener;
  },
  listenToPendingCouriers(restaurantId: string, listenerCallback: Function) {
    const couriersListener = $firestore
      .collection("restaurants")
      .doc(restaurantId)
      .collection("pending_couriers")
      .onSnapshot((querySnasphot) => {
        let couriers: Courier[] = [];
        querySnasphot.forEach((doc) => {
          couriers.push(
            new Courier(
              doc.id,
              doc.data().firstname,
              doc.data().lastname,
              true,
              doc.data().profile_picture_url,
              doc.data().profile_thumbnail_url,
              doc.data().phone
            )
          );
        });
        listenerCallback(couriers);
      });
    return couriersListener;
  },
  createPendingCourier(
    restaurantId: string,
    courier: Courier
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("pending_couriers")
        .doc(courier.id)
        .set(
          {
            firstname: courier.firstname,
            lastname: courier.lastname,
            profile_picture_url: courier.profilePictureUrl,
            profile_thumbnail_url: courier.profileThumbnailUrl,
            phone: courier.phone,
          },
          { merge: true }
        )
        .then(() => {
          res(success.PENDING_COURIER_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PENDING_COURIER_CREATION_ERROR_ALERT);
        });
    });
  },
  deletePendingCourier(
    restaurantId: string,
    courierId: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("pending_couriers")
        .doc(courierId)
        .delete()
        .then(() => {
          res(success.PENDING_COURIER_DELETION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PENDING_COURIER_DELETION_ERROR_ALERT);
        });
    });
  },
  createCourier(restaurantId: string, courier: Courier): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("couriers")
        .doc(courier.id)
        .set(
          {
            firstname: courier.firstname,
            lastname: courier.lastname,
            profile_picture_url: courier.profilePictureUrl,
            profile_thumbnail_url: courier.profileThumbnailUrl,
            phone: courier.phone,
          },
          { merge: true }
        )
        .then(() => {
          res(success.COURIER_CREATION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.COURIER_CREATION_ERROR_ALERT);
        });
    });
  },
  deleteCourier(restaurantId: string, courier: Courier): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("restaurants")
        .doc(restaurantId)
        .collection("couriers")
        .doc(courier.id)
        .delete()
        .then(() => {
          res(success.COURIER_DELETION_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.COURIER_DELETION_ERROR_ALERT);
        });
    });
  },
  listenToCourierInfos(userId: string, listenerCallback: Function) {
    const courierInfosListener = $firestore
      .collection("users")
      .doc(userId)
      .onSnapshot((doc) => {
        doc.data();
        listenerCallback(
          new CourierInfos(
            doc.id,
            doc.data()?.email,
            doc.data()?.firstname,
            doc.data()?.lastname,
            doc.data()?.phone,
            doc.data()?.profile_picture_url,
            doc.data()?.profile_thumbnail_url
          )
        );
      });
    return courierInfosListener;
  },
  updateCourierProfilePicture(
    userId: string,
    courierPictureImageFile: File
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      if (courierPictureImageFile?.size != 0) {
        const courierPictureImageDirPath = `users/${userId}/profile_pictures`;
        storageServices
          .uploadImageAndThumbnail(
            courierPictureImageDirPath,
            courierPictureImageFile
          )
          .then((imagePaths) => {
            $firestore
              .collection("users")
              .doc(userId)
              .set(
                {
                  profile_picture_url: imagePaths.imageDownloadUrl,
                  profile_picture_path: imagePaths.imagePath,
                  profile_thumbnail_url: imagePaths.thumbnailDownloadUrl,
                  profile_thumbnail_path: imagePaths.thumbnailPath,
                  updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              )
              .then(() => {
                res(success.USER_INFOS_UPDATE_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
              });
          })
          .catch((e) => {
            console.log(e);
            rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
          });
      }
      return;
    });
  },
  updateCourierBaseInfos(
    userId: string,
    courierFirstname: string,
    courierLastname: string,
    courierPhone: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      $firestore
        .collection("users")
        .doc(userId)
        .set(
          {
            firstname: courierFirstname,
            lastname: courierLastname,
            phone: courierPhone,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          res(success.USER_INFOS_UPDATE_SUCCESS_ALERT);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
        });
    });
  },
  // NOTE : Deprecated method
  updateCourierInfos(
    userId: string,
    courierFirstname: string,
    courierLastname: string,
    courierPhone: string,
    courierPictureImageFile: File
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      if (courierPictureImageFile?.size != 0) {
        const courierPictureImageDirPath = `users/${userId}/profile_pictures`;
        storageServices
          .uploadImageAndThumbnail(
            courierPictureImageDirPath,
            courierPictureImageFile
          )
          .then((imagePaths) => {
            $firestore
              .collection("users")
              .doc(userId)
              .set(
                {
                  firstname: courierFirstname,
                  lastname: courierLastname,
                  phone: courierPhone,
                  profile_picture_url: imagePaths.imageDownloadUrl,
                  profile_picture_path: imagePaths.imagePath,
                  profile_thumbnail_url: imagePaths.thumbnailDownloadUrl,
                  profile_thumbnail_path: imagePaths.thumbnailPath,
                  updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              )
              .then(() => {
                res(success.USER_INFOS_UPDATE_SUCCESS_ALERT);
              })
              .catch((e) => {
                console.log(e);
                rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
              });
          })
          .catch((e) => {
            console.log(e);
            rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
          });
      } else {
        $firestore
          .collection("users")
          .doc(userId)
          .set(
            {
              firstname: courierFirstname,
              lastname: courierLastname,
              phone: courierPhone,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          )
          .then(() => {
            res(success.USER_INFOS_UPDATE_SUCCESS_ALERT);
          })
          .catch((e) => {
            console.log(e);
            rej(errors.USER_INFOS_UPDATE_ERROR_ALERT);
          });
      }
    });
  },
};
export default courierServices;
