import { IonIcon, IonModal, IonToggle } from "@ionic/react";
import {
  albumsOutline,
  colorPalette,
  squareOutline,
  trash,
} from "ionicons/icons";
import { useContext, useRef } from "react";
import EditProductOptionsContext from "../../../contexts/edit-options.ctx";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import "./ProductsOptionsEditionModal.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  isOpen: boolean;
  onDismiss: Function;
}

const ProductsOptionsEditionModal: React.FC<ContainerProps> = (props) => {
  const editProductsOptionsCtx = useContext(EditProductOptionsContext);
  const inputRef = useRef<HTMLInputElement>();
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleOptionItem();
    }
  };

  const handleOptionItem = () => {
    if (editProductsOptionsCtx.optionItemEditName) {
      editProductsOptionsCtx.currentOptionItemIndex == -1
        ? editProductsOptionsCtx.addOptionItem()
        : editProductsOptionsCtx.updateOptionItem();

      inputRef.current?.focus();
    }
  };
  const optionDeleteHandler = (
    event: React.MouseEvent<HTMLIonIconElement, MouseEvent>,
    itemIndex: number
  ) => {
    event.stopPropagation();
    editProductsOptionsCtx.removeOptionItem(itemIndex);
  };

  return (
    <IonModal isOpen={props.isOpen} backdropDismiss={false}>
      <div className="products-options-edition-modal">
        <BaseModalHeader onDismiss={props.onDismiss}>
          {editProductsOptionsCtx.editMode == "ADD"
            ? i18n.t("AddProductOptionPage.createOptionGroup")
            : i18n.t("AddProductOptionPage.modifyOptionGroup")}
        </BaseModalHeader>
        <div className="products-options-edition-modal__editor">
          <div className="products-options-edition-modal__editor__name-input">
            <BaseTextInput
              label={i18n.t("AddProductOptionGroupCreation.groupName")}
              controller={{
                value: editProductsOptionsCtx.optionName,
                onChange: (event: any) =>
                  editProductsOptionsCtx.optionNameInputHandler(
                    event.target.value
                  ),
              }}
            />
            <div className="products-options-edition-modal__editor__type-input">
              <div className="products-options-edition-modal__editor__type-input__color">
                <span className="products-options-edition-modal__editor__type-input__color__label">
                  {i18n.t("AddProductOptionGroupCreation.color")}
                </span>
                <BaseButton
                  color={editProductsOptionsCtx.optionColor}
                  tight
                  onClick={() => editProductsOptionsCtx.colorChangeHandler()}
                >
                  <IonIcon icon={colorPalette} />
                </BaseButton>
              </div>
              <div
                className="products-options-edition-modal__editor__type-input__one"
                style={{
                  opacity: editProductsOptionsCtx.optionType == "ONE" ? 1 : 0.5,
                }}
              >
                <span className="products-options-edition-modal__editor__type-input__one__label">
                  {i18n.t("AddProductOptionGroupCreation.unique")}
                </span>
                <BaseButton
                  tight
                  onClick={() =>
                    editProductsOptionsCtx.optionTypeChangeHandler("ONE")
                  }
                >
                  <IonIcon icon={squareOutline} />
                </BaseButton>
              </div>
              <div
                className="products-options-edition-modal__editor__type-input__two"
                style={{
                  opacity:
                    editProductsOptionsCtx.optionType == "MULTI" ? 1 : 0.5,
                }}
              >
                <span className="products-options-edition-modal__editor__type-input__two__label">
                  {i18n.t("AddProductOptionGroupCreation.multi")}
                </span>
                <BaseButton
                  tight
                  onClick={() =>
                    editProductsOptionsCtx.optionTypeChangeHandler("MULTI")
                  }
                >
                  <IonIcon icon={albumsOutline} />
                </BaseButton>
              </div>
            </div>
          </div>
          <div className="products-infos-editor__infos-editor__has-options-container">
            <IonToggle
              checked={editProductsOptionsCtx.optionIsMandatory}
              onIonChange={(e) =>
                editProductsOptionsCtx.optionIsMandatoryChangeHandler(
                  !editProductsOptionsCtx.optionIsMandatory
                )
              }
            />
            <span>
              {i18n.t("AddProductOptionGroupCreation.mandatoryChoice")}
            </span>
          </div>
          <div className="products-options-edition-modal__editor__options-items-frame">
            {editProductsOptionsCtx.optionItems.map((item, index) => {
              return (
                <div
                  key={item.name + index}
                  className={
                    editProductsOptionsCtx.currentOptionItemIndex == index
                      ? "products-options-edition-modal__editor__options-items-frame__item products-options-edition-modal__editor__options-items-frame__item--active"
                      : "products-options-edition-modal__editor__options-items-frame__item"
                  }
                  onClick={() => editProductsOptionsCtx.selectOptionItem(index)}
                >
                  <span>{item.name}</span>
                  <div className="products-options-edition-modal__editor__options-items-frame__item__price-action">
                    <span>{`${
                      item.price
                        ? (item.price / 100).toFixed(2).toLocaleString()
                        : "-"
                    } ${constants.CURRENCY}`}</span>
                    <div className="products-options-edition-modal__editor__options-items-frame__item__price-action__delete">
                      <IonIcon
                        icon={trash}
                        color="danger"
                        onClick={(event) => optionDeleteHandler(event, index)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="products-options-edition-modal__editor__item-editor"
            onKeyDown={handleKeyDown}
          >
            <div className="products-options-edition-modal__editor__item-editor__name">
              <BaseTextInput
                label={i18n.t("AddProductOptionGroupCreation.name")}
                inputRef={inputRef}
                controller={{
                  value: editProductsOptionsCtx.optionItemEditName,
                  onChange: (event: any) =>
                    editProductsOptionsCtx.optionItemEditNameInputHandler(
                      event.target.value
                    ),
                }}
              />
            </div>
            <div className="products-options-edition-modal__editor__item-editor__price">
              <BaseTextInput
                label={i18n.t("AddProductOptionGroupCreation.price")}
                type="number"
                controller={{
                  value: editProductsOptionsCtx.optionItemEditPrice,
                  onChange: (event: any) =>
                    editProductsOptionsCtx.optionItemEditPriceInputHandler(
                      event.target.value
                    ),
                }}
              />
            </div>
            <div className="products-options-edition-modal__editor__options-items-frame__item__available-action">
              <div>{i18n.t("AddProductOptionGroupCreation.available")}</div>
              <IonToggle
                checked={!editProductsOptionsCtx.optionItemEditIsUnavailable}
                onIonChange={(e) =>
                  editProductsOptionsCtx.optionItemEditIsUnavailableChangeHandler(
                    !editProductsOptionsCtx.optionItemEditIsUnavailable
                  )
                }
              />
            </div>
          </div>
          <div className="products-options-edition-modal__editor__item-editor__confirm-button">
            <BaseButton
              expand
              tight
              margins="8px 0"
              disabled={!editProductsOptionsCtx.optionItemEditName}
              onClick={handleOptionItem}
            >
              {editProductsOptionsCtx.currentOptionItemIndex == -1
                ? i18n.t("AddProductOptionGroupCreation.addOption")
                : i18n.t("AddProductOptionGroupCreation.ModifyOption")}
            </BaseButton>
          </div>
          <div className="products-options-edition-modal__editor__actions">
            {editProductsOptionsCtx.editMode == "UPDATE" && (
              <BaseButton
                expand
                tight
                margins="8px 1rem 8px 0"
                outline
                onClick={() => editProductsOptionsCtx.deleteOptionHandler()}
              >
                {i18n.t("AppSettingsRestaurantInformation.deleteButton")}
              </BaseButton>
            )}
            <BaseButton
              expand
              tight
              margins="8px 0"
              disabled={
                !editProductsOptionsCtx.optionName ||
                !editProductsOptionsCtx.optionItems.length
              }
              onClick={editProductsOptionsCtx.formSubmitHandler}
            >
              {i18n.t("AddProductOptionGroupCreation.save")}
            </BaseButton>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default ProductsOptionsEditionModal;
