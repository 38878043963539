import { OptionItem } from "./ProductsOptions";

export default class {
  public id;
  public name;
  public price;
  public basePrice;
  public categoryId;
  public hasOptions;
  public pictureUrl;
  public thumbnailUrl;
  public quantity;
  public productsOptionsIds;
  public optionsPreset;
  public description;
  public unavailable;
  public longDesc;
  constructor(
    id: string,
    name: string,
    price: number,
    basePrice: number,
    categoryId: string,
    hasOptions: boolean,
    pictureUrl: string,
    thumbnailUrl: string,
    quantity: number,
    description?: string,
    productsOptionsIds?: string[],
    optionsPreset?: OptionItem[],
    unavailable?: boolean,
    longDesc?: string
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.basePrice = basePrice;
    this.categoryId = categoryId;
    this.hasOptions = hasOptions;
    this.pictureUrl = pictureUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.quantity = quantity;
    this.productsOptionsIds = productsOptionsIds;
    this.optionsPreset = optionsPreset;
    this.description = description;
    this.unavailable = unavailable;
    this.unavailable = unavailable;
    this.longDesc = longDesc;
  }
}
