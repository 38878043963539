// REVIEW LOADING AND BUSY STATE DONE
import { IonIcon, useIonAlert } from "@ionic/react";
import { logIn, qrCode } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import API from "../../../services";
import "./NoRestaurantPrompt.css";
import Courier from "../../../models/Courier";
import AuthContext from "../../../contexts/auth.ctx";
import QrReader from "react-qr-reader";
import CourierDataContext from "../../../contexts/courier-data.ctx";
import courierServices from "../../../services/apis/courierServices";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const NoRestaurantPrompt: React.FC<ContainerProps> = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [scanIsOn, setScanIsOn] = useState(false);
  const [scannedId, setScannedId] = useState("");
  const authCtx = useContext(AuthContext);
  const courierDataCtx = useContext(CourierDataContext);
  const [present] = useIonAlert();

  const qrErrorHandler = (error: any) => {
    console.log(error);
  };

  const qrReadHandler = (data: any) => {
    if (data) {
      setScannedId(data);
    }
  };

  useEffect(() => {
    if (scannedId) {
      setIsBusy(true);
      const courier = new Courier(
        authCtx.userId,
        courierDataCtx.courierInfos.firstname,
        courierDataCtx.courierInfos.lastname,
        true,
        courierDataCtx.courierInfos.profilePictureUrl,
        courierDataCtx.courierInfos.profileThumbnailUrl,
        courierDataCtx.courierInfos.phone
      );
      courierServices
        .createPendingCourier(scannedId, courier)
        .then((success) => {
          present({
            header: success.header,
            message: success.detail,
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              setScanIsOn(false);
              setScannedId("");
              setIsBusy(false);
            },
          });
        })
        .catch((err) => {
          setIsBusy(false);
        });
    }
  }, [scannedId]);

  return (
    <div className="no-restaurant-prompt" data-testid="no-restaurant-prompt">
      {!scanIsOn ? (
        <>
          <span className="no-restaurant-prompt__text">
            {i18n.t("AppSettingsRestaurantInformation.NoRestaurant")}
            <span style={{ color: "red", display: "block", marginTop: "1rem" }}>
              {i18n.t(
                "AppSettingsRestaurantInformation.InformationNotCompleted"
              )}
            </span>
          </span>
          {courierDataCtx.courierInfos.firstname &&
          courierDataCtx.courierInfos.lastname &&
          courierDataCtx.courierInfos.phone &&
          courierDataCtx.courierInfos.profilePictureUrl ? (
            <div className="no-restaurant-prompt__action">
              <BaseButton isLoading={isBusy} onClick={() => setScanIsOn(true)}>
                <span className="no-restaurant-prompt__qr-icon">
                  <IonIcon icon={qrCode} />
                </span>
                {i18n.t("AppSettingsRestaurantInformation.Scan")}
              </BaseButton>
            </div>
          ) : null}

          <span className="no-restaurant-prompt__text">
            {i18n.t("AppSettingsRestaurantInformation.RestaurantAccepted")}
          </span>

          <div className="no-restaurant-prompt__action">
            <BaseButton onClick={() => authCtx.signOut()}>
              {i18n.t("AppSettingsRestaurantInformation.LogIn")}
            </BaseButton>
          </div>
        </>
      ) : (
        <div className="no-restaurant-prompt__scanner">
          <QrReader
            delay={300}
            onError={qrErrorHandler}
            onScan={qrReadHandler}
            style={{ width: "100%" }}
          />
          <BaseButton
            expand
            onClick={() => {
              setScannedId("");
              setScanIsOn(false);
            }}
            margins={"32px 0 0 0"}
          >
            {i18n.t("AppSettingsOrderHistory.cancelled")}
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default NoRestaurantPrompt;
