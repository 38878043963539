import React, { useContext, useEffect, useState } from "react";
import { ErrorAlert, SuccessAlert } from "../types";
import { GCS } from "../services";
import AuthContext from "./auth.ctx";
import { useIonAlert } from "@ionic/react";
import customerServices from "../services/apis/customerServices";
import RestaurantDataContext from "./restaurant-data.ctx";
import i18n from "../translations/i18n";

const NewCustomerContext = React.createContext({
  isBusy: false,
  phone: "",
  addressComplement: "",
  firstname: "",
  lastname: "",
  selectedSuggestion: {},
  modalClosingTrigger: false,
  phoneInputHandler: (phone: string) => {},
  addressComplementInputHandler: (complement: string) => {},
  firstnameInputHandler: (firstname: string) => {},
  lastnameInputHandler: (lastname: string) => {},
  suggestionSelectionHandler: (suggestion: any) => {},
  formSubmitHandler: () => {},
  resetContext: () => {},
});

export const NewCustomerContextProvider: React.FC = (props) => {
  // Auth context needed to get restaurant ID
  const authCtx = useContext(AuthContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const [isBusy, setIsBusy] = useState(false);
  // Form data states
  const [phone, setPhone] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  // GCS selected result
  const [selectedSuggestion, setSelectedSelection] = useState(
    {} as google.maps.places.AutocompletePrediction
  );
  // Utility state
  const [modalClosingTrigger, setModalClosingTrigger] = useState(false);

  // Utility methods
  const [present] = useIonAlert();
  const returnToCustomerSelectionPage = () => {
    setModalClosingTrigger(true);
    setModalClosingTrigger(false);
  };
  const resetContext = () => {
    setPhone("");
    setFirstname("");
    setLastname("");
    setAddressComplement("");
    setSelectedSelection({} as google.maps.places.AutocompletePrediction);
    setIsBusy(false);
  };

  // Data setters
  const phoneInputHandler = (phone: string) => {
    setPhone(phone);
  };
  const firstnameInputHandler = (firstname: string) => {
    setFirstname(firstname);
  };
  const lastnameInputHandler = (lastname: string) => {
    setLastname(lastname);
  };
  const addressComplementInputHandler = (addressComplement: string) => {
    setAddressComplement(addressComplement);
  };
  // GCS result selection handler
  const suggestionSelectionHandler = (suggestion: any) => {
    setSelectedSelection(suggestion);
  };

  // New customer creation method
  const formSubmitHandler = () => {
    if (!isBusy) {
      setIsBusy(true);
      GCS.getPlaceGeocoding(selectedSuggestion.place_id).then(async (data) => {
        let postalCode = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "postal_code";
        });
        let city = data.results[0].address_components.find(function (
          component
        ) {
          return component.types[0] == "locality";
        });
        const distance = await GCS.calculateRealDistance(
          restaurantDataCtx.restaurantInfos.addressPlaceId,
          selectedSuggestion.place_id
        );
        const customerPayload = {
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          address_place_id: selectedSuggestion.place_id,
          address_first_line:
            selectedSuggestion.structured_formatting.main_text,
          address_second_line: addressComplement,
          address_zipcode: postalCode?.short_name,
          address_city: city?.short_name,
          address_latitude: data.results[0].geometry.location.lat(),
          address_longitude: data.results[0].geometry.location.lng(),
          distance_to_restaurant: distance,
        };
        customerServices
          .createNewCustomer(authCtx.restaurantId, customerPayload)
          .then((success: SuccessAlert) => {
            setIsBusy(false);
            present({
              header: success.header,
              message: success.detail,
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {
                resetContext();
                returnToCustomerSelectionPage();
              },
            });
          })
          .catch((error: ErrorAlert) => {
            setIsBusy(false);
            present({
              header: i18n.t("AppSettingsRestaurantInformation.InvalidAddress"),
              message: i18n.t("AppSettingsRestaurantInformation.AdressInvalid"), // 🔥 hotfix
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {
                resetContext();
                returnToCustomerSelectionPage();
              },
            });
          });
      });
    }
  };

  return (
    <NewCustomerContext.Provider
      value={{
        isBusy,
        phone,
        addressComplement,
        firstname,
        lastname,
        selectedSuggestion,
        modalClosingTrigger,
        phoneInputHandler,
        addressComplementInputHandler,
        firstnameInputHandler,
        lastnameInputHandler,
        suggestionSelectionHandler,
        formSubmitHandler,
        resetContext,
      }}
    >
      {props.children}
    </NewCustomerContext.Provider>
  );
};

export default NewCustomerContext;
