import "./ProductsCategoryColorItem.css";

interface ContainerProps {
  color: string;
  isActive: boolean;
  action: Function;
}

const ProductsCategoryColorItem: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="products-category-color-item"
      style={{ backgroundColor: props.color }}
      data-testid="products-category-color-item"
      onClick={() => props.action()}
    >
      {props.isActive && (
        <div className="products-category-color-item__selected-dot" />
      )}
    </div>
  );
};

export default ProductsCategoryColorItem;
