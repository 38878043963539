import i18n from "../translations/i18n";

export default {
  SIGNUP_SUCCESS_ALERT: {
    header: i18n.t("messages.SignupSuccessHeader"),
    detail: i18n.t("messages.SignupSuccessDetail"),
  },
  SEND_PASSWORD_RESET_EMAIL_SUCCESS_ALERT: {
    header: i18n.t("messages.SendPasswordResetEmailSuccessHeader"),
    detail: (email: string) =>
      `${i18n.t("messages.SendPasswordResetEmailSuccessDetail")} ${email}`,
  },
  CATEGORY_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.CategoryCreationSuccessHeader"),
    detail: i18n.t("messages.CategoryCreationSuccessDetail"),
  },
  CATEGORY_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.CategoryUpdateSuccessHeader"),
    detail: i18n.t("messages.CategoryUpdateSuccessDetail"),
  },
  CATEGORY_DELETE_SUCCESS_ALERT: {
    header: i18n.t("messages.CategoryDeleteSuccessHeader"),
    detail: i18n.t("messages.CategoryDeleteSuccessDetail"),
  },

  PRODUCTS_OPTIONS_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductsOptionsCreationSuccessHeader"),
    detail: i18n.t("messages.ProductsOptionsCreationSuccessDetail"),
  },
  PRODUCTS_OPTIONS_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductsOptionsUpdateSuccessHeader"),
    detail: i18n.t("messages.ProductsOptionsUpdateSuccessDetail"),
  },
  PRODUCTS_OPTIONS_DELETE_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductsOptionsDeleteSuccessHeader"),
    detail: i18n.t("messages.ProductsOptionsDeleteSuccessDetail"),
  },

  PRODUCT_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductCreationSuccessHeader"),
    detail: i18n.t("messages.ProductCreationSuccessDetail"),
  },
  PRODUCT_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductUpdateSuccessHeader"),
    detail: i18n.t("messages.ProductUpdateSuccessDetail"),
  },
  PRODUCT_DELETE_SUCCESS_ALERT: {
    header: i18n.t("messages.ProductDeleteSuccessHeader"),
    detail: i18n.t("messages.ProductDeleteSuccessDetail"),
  },
  CUSTOMER_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.CustomerCreationSuccessHeader"),
    detail: i18n.t("messages.CustomerCreationSuccessDetail"),
  },
  ORDER_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.OrderCreationSuccessHeader"),
    detail: i18n.t("messages.OrderCreationSuccessDetail"),
  },
  ORDER_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.OrderUpdateSuccessHeader"),
    detail: i18n.t("messages.OrderUpdateSuccessDetail"),
  },
  PENDING_COURIER_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.PendingCourierCreationSuccessHeader"),
    detail: i18n.t("messages.PendingCourierCreationSuccessDetail"),
  },
  PENDING_COURIER_DELETION_SUCCESS_ALERT: {
    header: i18n.t("messages.PendingCourierDeletionSuccessHeader"),
    detail: i18n.t("messages.PendingCourierDeletionSuccessDetail"),
  },
  COURIER_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.CourierCreationSuccessHeader"),
    detail: i18n.t("messages.CourierCreationSuccessDetail"),
  },
  COURIER_DELETION_SUCCESS_ALERT: {
    header: i18n.t("messages.CourierDeletionSuccessHeader"),
    detail: i18n.t("messages.CourierDeletionSuccessDetail"),
  },
  DELIVERY_CREATION_SUCCESS_ALERT: {
    header: i18n.t("messages.DeliveryCreationSuccessHeader"),
    detail: i18n.t("messages.DeliveryCreationSuccessDetail"),
  },
  DELIVERY_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.DeliveryUpdateSuccessHeader"),
    detail: i18n.t("messages.DeliveryUpdateSuccessDetail"),
  },
  DELIVERY_CLOSING_SUCCESS_ALERT: {
    header: i18n.t("messages.DeliveryClosingSuccessHeader"),
    detail: i18n.t("messages.DeliveryClosingSuccessDetail"),
  },
  PASSWORD_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.PasswordUpdateSuccessHeader"),
    detail: i18n.t("messages.PasswordUpdateSuccessDetail"),
  },
  RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.RestaurantInfosUpdateSuccessHeader"),
    detail: i18n.t("messages.RestaurantInfosUpdateSuccessDetail"),
  },
  USER_INFOS_UPDATE_SUCCESS_ALERT: {
    header: i18n.t("messages.UserInfosUpdateSuccessHeader"),
    detail: i18n.t("messages.UserInfosUpdateSuccessDetail"),
  },
  COUPON_ADDED_SUCCESS_ALERT: {
    header: i18n.t("messages.CouponAddedSuccessHeader"),
    detail: i18n.t("messages.CouponAddedSuccessDetail"),
  },
  COUPON_DELETE_SUCCESS_ALERT: {
    header: i18n.t("messages.CouponDeleteSuccessHeader"),
    detail: i18n.t("messages.CouponDeleteSucessDetail"),
  },
};
