import React, { useContext, useEffect, useState } from "react";
import Product from "../models/Product";
import RestaurantDataContext from "./restaurant-data.ctx";

const ProductsPageContext = React.createContext({
  currentProductsCategoryId: "",
  currentProductsList: [] as Product[],
  productsCategorySelectionHandler: (categoryId: string) => {},
});

export const ProductsPageContextProvider: React.FC = (props) => {
  // Restaurant context needed to get products list
  const restaurantDataCtx = useContext(RestaurantDataContext);

  // UI displayer states
  const [currentProductsCategoryId, setCurrentProductsCategoryId] =
    useState("");
  const [currentProductsList, setCurrentProductsList] = useState(
    [] as Product[]
  );

  // Category selection handler
  const productsCategorySelectionHandler = (categoryId: string) => {
    setCurrentProductsCategoryId(categoryId);
  };

  // Side effect to handle conditional product display
  useEffect(() => {
    if (!currentProductsCategoryId) {
      setCurrentProductsList(restaurantDataCtx.productsList);
    } else {
      const products = restaurantDataCtx.productsList.filter(
        (product: Product) => {
          return currentProductsCategoryId == product.categoryId;
        }
      );
      setCurrentProductsList(products);
    }
  }, [restaurantDataCtx.productsList, currentProductsCategoryId]);

  return (
    <ProductsPageContext.Provider
      value={{
        currentProductsCategoryId,
        currentProductsList,
        productsCategorySelectionHandler,
      }}
    >
      {props.children}
    </ProductsPageContext.Provider>
  );
};

export default ProductsPageContext;
