import { useContext, useEffect } from "react";
import ProductsPageContext from "../../../contexts/products-page.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import ProductCategory from "../../../models/ProductCategory";
import ProductsCategoryChip from "../ProductsCategoryChip/ProductsCategoryChip";
import "./ProductsCategorySelector.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  isInOrderMode?: boolean;
}

const ProductsCategorySelector: React.FC<ContainerProps> = (props) => {
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const productsPageCtx = useContext(ProductsPageContext);

  useEffect(() => {
    productsPageCtx.productsCategorySelectionHandler("");
    return () => productsPageCtx.productsCategorySelectionHandler("");
  }, []);

  const getClasses = () => {
    const base = ["products-category-selector"];
    if (props.isInOrderMode) {
      base.push("products-category-selector--order-mode");
    }
    return base.join(" ");
  };

  return (
    <div className={getClasses()} data-testid="products-category-selector">
      <span>{i18n.t("AppMenu.categories")}</span>
      <div className="products-category-selector__category-list">
        <ProductsCategoryChip
          key={"ALL_CAT_CHIP"}
          name={i18n.t("AppOrder.all")}
          color="grey"
          isMuted={productsPageCtx.currentProductsCategoryId != ""}
          action={() => productsPageCtx.productsCategorySelectionHandler("")}
        />
        {restaurantDataCtx.productCategoriesList.map(
          (category: ProductCategory) => (
            <ProductsCategoryChip
              key={category.id}
              name={category.name}
              color={category.color}
              isMuted={productsPageCtx.currentProductsCategoryId != category.id}
              action={() =>
                productsPageCtx.productsCategorySelectionHandler(category.id)
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default ProductsCategorySelector;
