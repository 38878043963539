export default {
  START_PAGE: "/",
  SIGN_UP_PAGE: "/signup",
  SIGN_IN_PAGE: "/signin",
  RESET_PASSWORD_PAGE: "/reset-password",
  HOME_PAGE: "/home",
  ORDERS_PAGE: "/home/orders",
  COURIERS_PAGE: "/home/couriers",
  PRODUCTS_PAGE: "/home/products",
  ACCOUNT_PAGE: "/home/account",
  DELIVERIES_PAGE: "/home/delivery",
  PRODUCT_EDITION_PAGE: "/ctxl/add-product",
  NEW_ORDER_PAGE: "/ctxl/new-order",
  NEW_DELIVERY_PAGE: "/ctxl/new-delivery",
  SETTINGS_PAGE: "/home/settings",
  PRODUCTION_PAGE: "/home/production",
};
