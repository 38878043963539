import "./StartChoices.css";
import routes from "../../../routes";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import Logo from "../../@Shared/Logo/Logo";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

export const signUpButtonRoute = routes.SIGN_UP_PAGE;
export const signInButtonRoute = routes.SIGN_IN_PAGE;

const StartChoices: React.FC<ContainerProps> = (props) => {
  return (
    <>
      <header className="start-logo" data-testid="start-logo">
        <Logo size="base" />
      </header>
      <section className="start-choices" data-testid="start-choices">
        <BaseButton big routerLink={signInButtonRoute} expand>
          {i18n.t("AppSettingsAccount.SignIn")}
        </BaseButton>
        <BaseButton big routerLink={signUpButtonRoute} expand outline>
          {i18n.t("AppSettingsAccount.DeliverySignUp")}
        </BaseButton>
      </section>
    </>
  );
};

export default StartChoices;
