import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Courier from "../models/Courier";
import Order from "../models/Order";
import Product from "../models/Product";
import ProductCategory from "../models/ProductCategory";
import RestaurantInfos from "../models/RestaurantInfos";
import WorkingDays from "../models/WorkingDays";
import { Storage } from "@ionic/storage";
import ProductsOption from "../models/ProductsOptions";
import productCategoryServices from "../services/apis/productCategoryServices";
import productServices from "../services/apis/productServices";
import orderServices from "../services/apis/orderServices";
import courierServices from "../services/apis/courierServices";
import restaurantServices from "../services/apis/restaurantServices";
import productOptionsSevices from "../services/apis/productOptionsServices";
import smsServices from "../services/apis/smsServices";
import { getPlatforms } from "@ionic/react";
import { PosPrint } from "../services";
import { PromoCode } from "../types";
const RestaurantDataContext = React.createContext({
  rtListenersList: [] as Function[],
  ordersList: [] as Order[],
  plannedOrdersList: [] as Order[],
  productCategoriesList: [] as ProductCategory[],
  productsList: [] as Product[],
  productsOptionsList: [] as ProductsOption[],
  couriersList: [] as Courier[],
  pendingCouriersList: [] as Courier[],
  restaurantInfos: {} as RestaurantInfos,
  workingDays: {} as WorkingDays,
  posMacAddress: "",
  promoCodes: [] as PromoCode[],
  initRestaurantData: (restaurantId: string) => {},
  posMacAddressUpdateHandler: (mac: string) => {},
  purchaseSmsHandler: (mac: string) => {},
  dismissListeners: () => {},
});

export const RestaurantDataContextProvider: React.FC = (props) => {
  // Restaurant specific data states
  const [restaurantInfos, setRestaurantInfos] = useState({} as RestaurantInfos);
  const [workingDays, setWorkingDays] = useState({} as WorkingDays);
  const history = useHistory();
  const [couriersList, setCouriersList] = useState([] as Courier[]);
  const [posMacAddress, setPosMacAddress] = useState("");
  const [pendingCouriersList, setPendingCouriersList] = useState(
    [] as Courier[]
  );

  const [promoCodes, setPromoCodes] = useState([] as PromoCode[]);

  // Orders and products states
  const [productCategoriesList, setProductCategoriesList] = useState(
    [] as ProductCategory[]
  );
  const [productsOptionsList, setProductsOptionsList] = useState(
    [] as ProductsOption[]
  );
  const [productsList, setProductsList] = useState([] as Product[]);
  const [ordersList, setOrdersList] = useState([] as Order[]);
  // Order delayed
  const [plannedOrdersList, setPlannedOrdersList] = useState([] as Order[]);

  const [prevOrderCreatedAt, setPrevOrderCreatedAt] = useState(
    ordersList[0]?.createdAt
  );
  const [prevPlannedOrderCreatedAt, setPrevPlannedOrderCreatedAt] = useState(
    plannedOrdersList[0]?.createdAt
  );

  // Realtime listeners utility state
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);
  // Initialization sequence with listeners management
  const initRestaurantData = async (restaurantId: string) => {
    const storage = new Storage();
    await storage.create();
    const storedPosMacAddress = await storage.get("posMacAddress");
    if (storedPosMacAddress) {
      setPosMacAddress(storedPosMacAddress);
    }
    const productCategoriesListener: Function =
      productCategoryServices.listenToProductCategories(
        restaurantId,
        (categories: ProductCategory[]) => setProductCategoriesList(categories)
      );
    const productsListener: Function = productServices.listenToProducts(
      restaurantId,
      (products: Product[]) => setProductsList(products)
    );
    const promoCodeListener: Function = restaurantServices.listenToCoupon(
      restaurantId,
      (coupons: PromoCode[]) => {
        setPromoCodes(coupons);
      }
    );

    const ordersListener: Function = orderServices.listenToNowOrders(
      restaurantId,
      (orders: Order[]) => {
        setOrdersList(orders);
      }
    );

    const plannedOrdersListener: Function = orderServices.listenToPlannedOrders(
      restaurantId,
      (orders: Order[]) => {
        setPlannedOrdersList(orders);
      }
    );
    const couriersListener: Function = courierServices.listenToCouriers(
      restaurantId,
      (couriers: Courier[]) => setCouriersList(couriers)
    );
    const pendingCouriersListener: Function =
      courierServices.listenToPendingCouriers(
        restaurantId,
        (couriers: Courier[]) => setPendingCouriersList(couriers)
      );
    const restaurantInfosListener: Function =
      restaurantServices.listenToRestaurantInfos(
        restaurantId,
        (restaurantInfos: RestaurantInfos) =>
          setRestaurantInfos(restaurantInfos)
      );
    const restaurantWorkingDaysListener: Function =
      restaurantServices.listenToRestaurantWorkingDays(
        restaurantId,
        (workingDays: WorkingDays) => setWorkingDays(workingDays)
      );
    const productsOptionsListener: Function =
      productOptionsSevices.listenToProductsOptions(
        restaurantId,
        (productsOptions: ProductsOption[]) => {
          setProductsOptionsList(productsOptions);
        }
      );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([
      productCategoriesListener,
      productsListener,
      ordersListener,
      plannedOrdersListener,
      couriersListener,
      pendingCouriersListener,
      restaurantInfosListener,
      restaurantWorkingDaysListener,
      productsOptionsListener,
      promoCodeListener,
    ]);
  };

  const handleTicketPrint = (order: Order) => {
    PosPrint.printOrderTicket(order, posMacAddress);
  };

  useEffect(() => {
    if (ordersList.length) {
      if (prevOrderCreatedAt) {
        if (ordersList[0].createdAt.getTime() > prevOrderCreatedAt.getTime()) {
          if (
            ordersList[0].paymentType == "IRL" ||
            ordersList[0].paymentType == "STRIPE"
          ) {
            const platforms = getPlatforms();

            if (platforms.includes("hybrid")) {
              handleTicketPrint(ordersList[0]);
            }
            playSoundNotif();
          }
        }
      } else {
        if (
          ordersList[0].paymentType == "IRL" ||
          ordersList[0].paymentType == "STRIPE"
        ) {
          const platforms = getPlatforms();
          if (platforms.includes("hybrid")) {
            handleTicketPrint(ordersList[0]);
          }
          playSoundNotif();
        }
      }
    }
    setPrevOrderCreatedAt(ordersList[0]?.createdAt);
  }, [ordersList]);

  useEffect(() => {
    if (plannedOrdersList.length) {
      if (prevPlannedOrderCreatedAt) {
        if (
          plannedOrdersList[0].createdAt.getTime() >
          prevPlannedOrderCreatedAt.getTime()
        ) {
          if (
            plannedOrdersList[0].paymentType == "IRL" ||
            plannedOrdersList[0].paymentType == "STRIPE"
          ) {
            const platforms = getPlatforms();
            if (platforms.includes("hybrid")) {
              handleTicketPrint(plannedOrdersList[0]);
            }
            playSoundNotif();
          }
        }
      } else {
        if (
          plannedOrdersList[0].paymentType == "IRL" ||
          plannedOrdersList[0].paymentType == "STRIPE"
        ) {
          const platforms = getPlatforms();
          if (platforms.includes("hybrid")) {
            handleTicketPrint(plannedOrdersList[0]);
          }
          playSoundNotif();
        }
      }
    }
    setPrevPlannedOrderCreatedAt(plannedOrdersList[0]?.createdAt);
  }, [plannedOrdersList]);

  const playSoundNotif = () => {
    let audio = document.getElementById(
      "order-notif-audio"
    ) as HTMLAudioElement;
    audio.play();
  };
  const dismissListeners = () => {
    rtListenersList.map((listener: Function) => listener());
    return true;
  };

  const createCouponCode = (promoCode: PromoCode) => {
    restaurantServices.addCoupon(restaurantInfos.id, promoCode);
  };
  const purchaseSmsHandler = () => {
    smsServices
      .purchaseSms()
      .then((url) => {
        history.push(url);
      })
      .catch((e) => {});
  };
  const posMacAddressUpdateHandler = async (mac: string) => {
    const storage = new Storage();
    await storage.create();
    storage.set("posMacAddress", mac);
    setPosMacAddress(mac);
  };

  return (
    <RestaurantDataContext.Provider
      value={{
        rtListenersList,
        ordersList,
        plannedOrdersList,
        productCategoriesList,
        productsList,
        productsOptionsList,
        couriersList,
        pendingCouriersList,
        posMacAddress,
        restaurantInfos,
        workingDays,
        promoCodes,
        posMacAddressUpdateHandler,
        initRestaurantData,
        purchaseSmsHandler,
        dismissListeners,
      }}
    >
      {props.children}
    </RestaurantDataContext.Provider>
  );
};

export default RestaurantDataContext;
