import { IonPage } from "@ionic/react";
import { add } from "ionicons/icons";
import { useContext } from "react";
import { useHistory } from "react-router";
import HomeLayout from "../../../components/Layouts/HomeLayout/HomeLayout";
import ProductsCategorySelector from "../../../components/Products/ProductsCategorySelector/ProductsCategorySelector";
import ProductsGrid from "../../../components/Products/ProductsGrid/ProductsGrid";
import EditProductContext from "../../../contexts/edit-product.ctx";
import Product from "../../../models/Product";
import routes from "../../../routes";
import "./ProductsPage.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const ProductsPage: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const editProductCtx = useContext(EditProductContext);

  const navigateToAddProductPageHandler = () => {
    editProductCtx.resetContext();
    history.push(routes.PRODUCT_EDITION_PAGE);
  };

  const navigateToUpdateProductPageHandler = (product: Product) => {
    editProductCtx.resetContext();
    editProductCtx.setAsUpdate(product.id);
    editProductCtx.productCategorySelectionHandler(product.categoryId);
    editProductCtx.productNameInputHandler(product.name);
    editProductCtx.productDescriptionInputHandler(product.description || "");
    editProductCtx.productLongDescInputHandler(product.longDesc || "");
    editProductCtx.productPriceInputHandler((product.price / 100).toString());
    editProductCtx.productHasOptionsInputHandler(product.hasOptions);
    editProductCtx.currentToUpdatePictureUrlSetter(product.thumbnailUrl);
    editProductCtx.productIsUnavailableHandler(product.unavailable || false);
    editProductCtx.productsOptionsIdsPresetHandler(
      product.productsOptionsIds || []
    );
    history.push(routes.PRODUCT_EDITION_PAGE);
  };

  return (
    <HomeLayout
      title={i18n.t("AppMenu.menu")}
      button={{ icon: add, action: navigateToAddProductPageHandler }}
      showButton
    >
      <ProductsCategorySelector />
      <ProductsGrid onItemSelect={navigateToUpdateProductPageHandler} />
    </HomeLayout>
  );
};

export default ProductsPage;
