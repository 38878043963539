import BaseButton from "../../@Base/BaseButton/BaseButton";
import "./SettingCard.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  title?: string;
  subTitle?: string;
  onSave?: Function;
  gridId: string;
  isBusy?: boolean;
}

const SettingCard: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="setting-card"
      data-testid="setting-card"
      style={{ gridArea: props.gridId }}
    >
      <div className="setting-card__title-container">
        <span className="setting-card__title">{props.title || " "}</span>
        {props.subTitle ? (
          <span className="setting-card__subtitle">
            {props.subTitle || " "}
          </span>
        ) : null}
      </div>
      <div className="setting-card__content">{props.children}</div>

      <div className="setting-card__control">
        {props.onSave && (
          <BaseButton
            onClick={() => (props.onSave ? props.onSave() : {})}
            isLoading={props.isBusy}
            tight
          >
            {i18n.t("AppSettingsRestaurantInformation.save")}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default SettingCard;
