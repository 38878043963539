import { IonIcon } from "@ionic/react";
import dayjs from "dayjs";
import { chevronBack, chevronForward, navigate, time } from "ionicons/icons";
import { useContext } from "react";
import Order from "../../../models/Order";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import OrderSummaryItem from "../OrderSummaryItem/OrderSummaryItem";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import "./OrderSummary.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  order: Order;
  restaurantDataCtx: any;
  onDelete: Function;
  onPrint: Function;
  asRestaurantDisplay?: boolean;
  isNextAvailable?: boolean;
  onNextOrder?: Function;
  isPreviousAvailable?: boolean;
  onPreviousOrder?: Function;
  isHistory?: boolean;
  isFullScreen?: boolean;
}

const OrderSummary: React.FC<ContainerProps> = (props) => {
  const amount = props.order.total_price
    ? (props.order.total_price / 100).toFixed(2)
    : "0";
  const amountWithoutFees = props.order.total_price
    ? props.order.delivery_fees
      ? ((props.order.total_price - props.order.delivery_fees) / 100).toFixed(2)
      : (props.order.total_price / 100).toFixed(2)
    : 0;
  const deliveryFees = props.order.delivery_fees
    ? (props.order.delivery_fees / 100).toFixed(2)
    : "0";
  return (
    <div className="order-summary" data-testid="order-summary">
      {props.asRestaurantDisplay && (
        <div
          className={
            props.isFullScreen
              ? "order-summary__order-infos order-summary__order-infos-text-large"
              : "order-summary__order-infos order-summary__order-infos-text-small"
          }
        >
          <div
            className="order-summary__order-infos__navigation"
            onClick={() =>
              props.onPreviousOrder
                ? props.onPreviousOrder(props.order)
                : () => {}
            }
          >
            <IonIcon icon={chevronBack} size="large" />
          </div>
          <div className="order-summary__order-infos__infos">
            <span>N°{props.order.number}</span>
            <span>
              {props.order.type == "DELIVERY"
                ? i18n.t("AppSettingsRestaurantInformation.Delivery")
                : i18n.t("AppOrder.On-site")}{" "}
              {" - "}
              {i18n.t("AppOrder.PaymentBy")}{" "}
              {props.order.paymentType === "CASH"
                ? i18n.t("AppOrder.Cash")
                : i18n.t("AppOrder.CB")}
              {" - "}
              {props.order.planningType === "PLANNED"
                ? i18n.t("AppSettingsOrderHistory.planned")
                : i18n.t("AppSettingsOrderHistory.Now")}
            </span>
          </div>
          <div
            className="order-summary__order-infos__navigation"
            onClick={() =>
              props.onNextOrder ? props.onNextOrder(props.order) : () => {}
            }
          >
            <IonIcon icon={chevronForward} size="large" />
          </div>
        </div>
      )}
      <div
        className={
          props.asRestaurantDisplay
            ? "order-summary__cart"
            : "order-summary__cart order-summary__cart--as-delivery"
        }
      >
        <div className="order-summary__products">
          {props.order.products.map((product, index) => {
            return (
              <OrderSummaryItem
                key={`summary-item-${index}-${product.id}`}
                product={product}
                isFullScreen={props.isFullScreen}
              />
            );
          })}
          <div className="order-summary__products__spacer" />
        </div>
        <div className="order-summary__total">
          <span>{i18n.t("AppSettingsSalesSummary.total")}</span>

          {props.order.type == "DELIVERY" ? (
            <span>
              {amount +
                `${constants.CURRENCY} (` +
                amountWithoutFees +
                `${constants.CURRENCY} + ` +
                deliveryFees +
                `${constants.CURRENCY} ${i18n.t(
                  "AppSettingsDeliverySettings.Fees"
                )})`}
            </span>
          ) : (
            <span>{amount + ` ${constants.CURRENCY}`}</span>
          )}
        </div>
      </div>
      <div
        className={
          props.isFullScreen
            ? "order-summary__mini-infos order-summary__mini-infos__text-large"
            : "order-summary__mini-infos order-summary__mini-infos__text-small"
        }
      >
        <div
          className={
            props.isFullScreen
              ? "order-summary__mini-infos__distance order-summary__mini-infos__text-large"
              : "order-summary__mini-infos__distance order-summary__mini-infos__text-small"
          }
        >
          <IonIcon icon={navigate} />
          <span>
            {
              "-"
              // TODO implement real calculation
            }
          </span>
        </div>
        <div
          className={
            props.isFullScreen
              ? "order-summary__mini-infos__hour order-summary__mini-infos__text-large"
              : "order-summary__mini-infos__hour order-summary__mini-infos__text-small"
          }
        >
          <IonIcon icon={time} />
          <span>{dayjs(props.order.createdAt).format("HH:mm")}</span>
        </div>
        {props.order.planningType == "PLANNED" ? (
          <div
            className={
              props.isFullScreen
                ? "order-summary__mini-infos__hour__planned-time order-summary__mini-infos__text-large"
                : "order-summary__mini-infos__hour__planned-time order-summary__mini-infos__text-small"
            }
          >
            <IonIcon icon={time} />
            <span>{dayjs(props.order.plannedTime).format("HH:mm")}</span>
          </div>
        ) : null}
      </div>
      {props.asRestaurantDisplay && (
        <>
          {props.isHistory ? (
            <BaseButton
              tight
              expand
              margins={"16px 0 0 0"}
              onClick={props.onPrint}
            >
              {i18n.t("AppSettingsDeliverySettings.PrintReceipt")}
            </BaseButton>
          ) : (
            <>
              <BaseButton
                tight
                expand
                margins={"0px 0 0 0"}
                outline
                onClick={props.onDelete}
              >
                {i18n.t("AppOrder.CancelOrder")}
              </BaseButton>
              <BaseButton
                tight
                expand
                margins={"16px 0 0 0"}
                onClick={props.onPrint}
              >
                {i18n.t("AppSettingsDeliverySettings.PrintReceipt")}
              </BaseButton>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderSummary;
