import Resizer from "react-image-file-resizer";

const resizeFile = (fileToResize: File) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      fileToResize,
      300,
      300,
      "JPEG",
      60,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
};

export default resizeFile;
