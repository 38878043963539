import { IonImg } from "@ionic/react";
import { useContext } from "react";
import NewOrderContext from "../../../contexts/new-order.ctx";
import Product from "../../../models/Product";
import OrdersCartItem from "../OrdersCartItem/OrdersCartItem";
import "./OrdersFullCart.css";
import { constants } from "../../../utils/constants";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  externalContext?: {
    cart: Product[];
    removeProductFromCart: Function;
  };
  onItemSelect?: Function;
}

const OrdersFullCart: React.FC<ContainerProps> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const orderContext = props.externalContext
    ? props.externalContext
    : newOrderCtx;

  const selectGridItemHandler = (product: Product, index: number) => {
    if (props.onItemSelect) {
      props.onItemSelect(product, index);
    }
  };

  const getTotalPrice = () => {
    return (
      orderContext.cart.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  return (
    <div className="orders-full-cart" data-testid="orders-full-cart">
      <div className="orders-full-cart__cart">
        <div className="orders-full-cart__products">
          {orderContext.cart.map((product, index) => {
            return (
              <OrdersCartItem
                key={`full-cart-item-${index}-${product.id}`}
                product={product}
                actionName={i18n.t("AppOrder.DeleteProduct")}
                action={() => orderContext.removeProductFromCart(index)}
                onClick={() => selectGridItemHandler(product, index)}
                index={index}
              />
            );
          })}
          <div className="orders-full-cart__products__spacer" />
        </div>
        <div className="orders-full-cart__total">
          <span>{i18n.t("AppSettingsSalesSummary.total")}</span>
          <span>
            {getTotalPrice().toFixed(2)} {constants.CURRENCY}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrdersFullCart;
