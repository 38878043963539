import { useIonAlert } from "@ionic/react";
import { useContext, useState } from "react";
import AuthContext from "../../../contexts/auth.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import authServices from "../../../services/apis/authServices";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import SettingCard from "../SettingCard/SettingCard";
import SettingLabelAndInfo from "../SettingLabelAndInfo/SettingLabelAndInfo";
import "./RestaurantAccountEditor.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const RestaurantAccountEditor: React.FC<ContainerProps> = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const authCtx = useContext(AuthContext);
  const restaurantCtx = useContext(RestaurantDataContext);
  const [present] = useIonAlert();

  const updatePasswordHandler = () => {
    if (!currentPassword || !newPassword || !confirmationPassword) {
      return;
    }
    if (newPassword !== confirmationPassword) {
      present({
        header: i18n.t("AddProductProductPage.Error"),
        message: i18n.t("AppSettingsAccount.WrongPassword"),
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => setIsBusy(false),
      });
    }
    setIsBusy(true);
    authServices
      .updatePassword(authCtx.userEmail, currentPassword, newPassword)
      .then((success) => {
        present({
          header: success.header,
          message: success.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      })
      .catch((error) => {
        present({
          header: error.header,
          message: error.detail,
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => setIsBusy(false),
        });
      });
  };

  return (
    <div className="restaurant-account-editor">
      <SettingCard
        title={i18n.t("AppSettingsAccount.changePasswordHeader")}
        onSave={() => updatePasswordHandler()}
        gridId="password-update"
        isBusy={isBusy}
      >
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.currentPassword")}
          controller={{
            value: currentPassword,
            onChange: (event: any) => {
              setCurrentPassword(event.target.value);
            },
          }}
        />
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.newPassword")}
          controller={{
            value: newPassword,
            onChange: (event: any) => {
              setNewPassword(event.target.value);
            },
          }}
        />
        <BaseTextInput
          type="password"
          label={i18n.t("AppSettingsAccount.confirmation")}
          controller={{
            value: confirmationPassword,
            onChange: (event: any) => {
              setConfirmationPassword(event.target.value);
            },
          }}
        />
      </SettingCard>
      <SettingCard
        title={i18n.t("AppSettingsAccount.myInformationPopupHeader")}
        gridId="my-infos"
      >
        <SettingLabelAndInfo
          label={i18n.t("AppSettingsAccount.emailAddress")}
          info={authCtx.userEmail}
        />
      </SettingCard>
      <SettingCard gridId="logout-btn">
        <BaseButton expand tight color="danger" onClick={authCtx.signOut}>
          {i18n.t("AppSettingsAccount.logoutButton")}
        </BaseButton>
      </SettingCard>
    </div>
  );
};

export default RestaurantAccountEditor;
