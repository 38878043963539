import { useContext } from "react";
import OrdersPageContext from "../../../contexts/orders-page.ctx";
import RestaurantDataContext from "../../../contexts/restaurant-data.ctx";
import { OrdersStatus } from "../../../types";
import OrderItem from "../OrderItem/OrderItem";
import OrdersNoStates from "../OrdersNoStates/OrdersNoStates";
import OrdersTabs from "../OrdersTabs/OrdersTabs";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import "./OrdersTabsLayout.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  onOrderClick: Function;
  productionLayout?: boolean;
}

const OrdersTabsLayout: React.FC<ContainerProps> = (props) => {
  const ordersPageCtx = useContext(OrdersPageContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);

  const orderToShowLength = props.productionLayout
    ? [...restaurantDataCtx.ordersList].filter((order) => {
        return order.status === "NOT_IN_PRODUCTION";
      })
    : [...restaurantDataCtx.ordersList].filter((order) => {
        if (ordersPageCtx.currentOrdersSegment == "OUT") {
          return order.status == "OUT" || order.status == "DELIVERED";
        } else {
          return order.status == ordersPageCtx.currentOrdersSegment;
        }
      });

  const plannedOrderToShowLength = props.productionLayout
    ? [...restaurantDataCtx.plannedOrdersList].filter((order) => {
        return order.status === "NOT_IN_PRODUCTION";
      })
    : [...restaurantDataCtx.plannedOrdersList].filter((order) => {
        if (ordersPageCtx.currentOrdersSegment == "OUT") {
          return order.status == "OUT" || order.status == "DELIVERED";
        } else {
          return order.status == ordersPageCtx.currentOrdersSegment;
        }
      });

  return (
    <div className="orders-tabs-layout" data-testid="orders-tabs-layout">
      {props.productionLayout ? (
        <div className="orders-tabs" data-testid="orders-tabs">
          <IonSegment mode="ios" value={"NOT_IN_PRODUCTION"}>
            <IonSegmentButton value={"NOT_IN_PRODUCTION"}>
              <IonLabel>
                {i18n.t("AppSettingsDeliverySettings.NotInProduction")}
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
      ) : (
        <OrdersTabs />
      )}

      {orderToShowLength.length
        ? orderToShowLength.map((order) => {
            return (
              <OrderItem
                key={order.id}
                status={order.status}
                productsList={order.products}
                customerInfos={order.customer}
                orderNumber={order.number}
                delivered={order.status == "DELIVERED"}
                date={order.createdAt}
                order={order}
                onClick={() => props.onOrderClick(order)}
                courierInfos={order.courier}
              />
            );
          })
        : null}

      {plannedOrderToShowLength.length
        ? plannedOrderToShowLength.map((order) => {
            return (
              <OrderItem
                key={order.id}
                status={order.status}
                productsList={order.products}
                customerInfos={order.customer}
                orderNumber={order.number}
                delivered={order.status == "DELIVERED"}
                date={order.createdAt}
                order={order}
                onClick={() => props.onOrderClick(order)}
                courierInfos={order.courier}
              />
            );
          })
        : null}

      {!orderToShowLength && !plannedOrderToShowLength ? (
        <div className="orders-tabs-layout__no-state-container">
          <OrdersNoStates
            type={ordersPageCtx.currentOrdersSegment as OrdersStatus}
          />
        </div>
      ) : null}
    </div>
  );
};

export default OrdersTabsLayout;
