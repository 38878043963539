import { BluetoothSerial } from "@awesome-cordova-plugins/bluetooth-serial/index";
import dayjs from "dayjs";
import EscPosEncoder from "esc-pos-encoder-ionic";
import Order from "../../models/Order";
import Product from "../../models/Product";
import { constants } from "../../utils/constants";

let isBusy = false;

const printOrderTicket = async (
  order: Order,
  posMacAddress: string,
  deliveryFees?: number | null
) => {
  if (isBusy) {
    console.log("Printer is busy");
    return;
  }

  isBusy = true;

  await BluetoothSerial.disconnect();

  const encoder = new EscPosEncoder();
  const result = encoder.initialize();

  result
    .codepage("cp865")
    .align("center")
    .newline()
    .bold(true)
    .line(`Commande n°${order.number}`)
    .line(`${order.type == "DELIVERY" ? "Livraison" : "Sur Place"}`)
    .newline()
    .line(
      `Règlement en ${
        order.paymentType == "STRIPE"
          ? "WEB CB"
          : order.paymentType == "CASH"
          ? "Espèces"
          : "CB"
      }`
    )
    .align("left")
    .newline()
    .bold(false)
    .line(`Date : ${dayjs(order.createdAt).format("DD/MM/YYYY - HH:mm")}`)
    .newline();

  if (order.type == "DELIVERY") {
    result
      .bold(true)
      .line("Client :")
      .bold(false)
      .line("------------------------------------------------")
      .bold(true)
      .line(order.customer?.firstname + " " + order.customer?.lastname)
      .bold(false)
      .line(order.customer?.addressFirstLine)
      .line(
        order.customer?.addressSecondLine
          ? order.customer?.addressSecondLine
          : "--"
      )
      .line(order.customer?.addressZipcode + " " + order.customer?.addressCity)
      .line(order.customer?.phone)
      .line("------------------------------------------------");
  }

  result
    .bold(true)
    .line("Produits :")
    .bold(false)
    .line("------------------------------------------------")
    .newline();

  order.products.forEach((product: Product) => {
    result.align("left").bold(false).line(product.name);

    if (product.optionsPreset?.length != 0) {
      product.optionsPreset?.forEach((option) => {
        result
          .align("left")
          .codepage("cp858")
          .line(
            `■ ${option.name} (+ ${(option.price / 100).toFixed(2)} ${
              constants.CURRENCY
            })`
          )
          .codepage("cp865");
      });
    }
    result
      .align("right")
      .bold(true)
      .codepage("cp858")
      .line(`Quantité : x${product.quantity}`)
      .codepage("cp865");

    result
      .align("right")
      .bold(true)
      .codepage("cp858")
      .line(
        `.......................................${
          product.price / 100 < 10 ? " " : ""
        } ${(product.price / 100).toFixed(2)} ${constants.CURRENCY}`
      )
      .codepage("cp865")
      .newline();

    // Test of barcode is OK .barcode("978020137962", "ean13", 20)
    // .newline();
  });

  if (deliveryFees || order.delivery_fees) {
    result
      .newline()
      .align("left")
      .bold(true)
      .line("------------------------------------------------")
      .line("Frais de livraison :")
      .align("right")
      .codepage("cp858")
      .line(
        order.delivery_fees
          ? (order.delivery_fees / 100).toFixed(2) + ` ${constants.CURRENCY}`
          : deliveryFees
          ? (deliveryFees / 100).toFixed(2) + ` ${constants.CURRENCY}`
          : `0.00 ${constants.CURRENCY}`
      )
      .codepage("cp865")
      .align("left")
      .line("------------------------------------------------")
      .newline();
  }
  const getTotalPrice = () => {
    if (deliveryFees) {
      return (
        order.products.reduce((prev, product) => {
          return prev + product.price;
        }, 0) /
          100 +
        deliveryFees / 100
      );
    }
    return (
      order.products.reduce((prev, product) => {
        return prev + product.price;
      }, 0) / 100
    );
  };

  result
    .newline()
    .align("left")
    .bold(true)
    .line("------------------------------------------------")
    .line("TOTAL :")
    .align("right")
    .codepage("cp858")
    .line(
      order.total_price
        ? (order.total_price / 100).toFixed(2) + ` ${constants.CURRENCY}`
        : getTotalPrice().toFixed(2) + ` ${constants.CURRENCY}`
    )
    .codepage("cp865")
    .align("left")
    .line("------------------------------------------------")
    .newline()
    .newline()
    .align("center")
    .bold(true)
    .line("---")
    .newline()
    .newline()
    .line(" ")
    .cut();

  const resultByte = result.encode();

  if (posMacAddress) {
    BluetoothSerial.isConnected().then(
      () => {
        BluetoothSerial.write(resultByte)
          .then(() => {
            console.log("success");
            isBusy = false;
          })
          .catch((err) => {
            console.error(err);
            isBusy = false;
          });
      },
      () => {
        BluetoothSerial.connect(posMacAddress).subscribe(() => {
          BluetoothSerial.write(resultByte)
            .then(() => {
              console.log("success");
              isBusy = false;
            })
            .catch((err) => {
              console.error(err);
              isBusy = false;
            });
        });
      }
    );
  }
};

export default { printOrderTicket };
