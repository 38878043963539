import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import "./NewCustomerModal.css";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import AddressFetcher from "../../@Shared/AddressFetcher/AddressFetcher";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import i18n from "../../../translations/i18n";

interface ContainerProps {
  newCustomerCtx: {
    phone: string;
    phoneInputHandler: Function;
    addressComplement: string;
    addressComplementInputHandler: Function;
    firstname: string;
    firstnameInputHandler: Function;
    lastname: string;
    lastnameInputHandler: Function;
    selectedSuggestion: Object;
    suggestionSelectionHandler: Function;
    formSubmitHandler: Function;
    isBusy: boolean;
  };
  onDismiss: Function;
}

const NewCustomerModal: React.FC<ContainerProps> = (props) => {
  const isSaveButtonDisabled = () => {
    if (
      props.newCustomerCtx.phone &&
      props.newCustomerCtx.firstname &&
      props.newCustomerCtx.lastname &&
      Object.keys(props.newCustomerCtx.selectedSuggestion).length !== 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="new-customer-modal" data-testid="new-customer-modal">
      <BaseModalHeader onDismiss={props.onDismiss}>
        {i18n.t("NewDeliveryClient.newClient")}
      </BaseModalHeader>
      <div className="new-customer-modal__address-searcher">
        <AddressFetcher
          onSelect={props.newCustomerCtx.suggestionSelectionHandler}
          value={props.newCustomerCtx.selectedSuggestion}
        />
      </div>
      <div className="new-customer-modal__form">
        <BaseTextInput
          label={i18n.t("NewDeliveryClient.additionalInfo")}
          controller={{
            value: props.newCustomerCtx.addressComplement,
            onChange: (event: any) => {
              props.newCustomerCtx.addressComplementInputHandler(
                event.target.value
              );
            },
          }}
        />
        <div className="new-customer-modal__form__names">
          <BaseTextInput
            label={i18n.t("NewDeliveryClient.firstName")}
            controller={{
              value: props.newCustomerCtx.firstname,
              onChange: (event: any) => {
                props.newCustomerCtx.firstnameInputHandler(event.target.value);
              },
            }}
          />
          <div className="new-customer-modal__form__names__spacer" />
          <BaseTextInput
            label={i18n.t("NewDeliveryClient.lastName")}
            controller={{
              value: props.newCustomerCtx.lastname,
              onChange: (event: any) => {
                props.newCustomerCtx.lastnameInputHandler(event.target.value);
              },
            }}
          />
        </div>
      </div>
      <div className="new-customer-modal__save-button">
        <BaseButton
          disabled={isSaveButtonDisabled()}
          tight
          expand
          onClick={() => props.newCustomerCtx.formSubmitHandler()}
          isLoading={props.newCustomerCtx.isBusy}
        >
          {i18n.t("AddProductProductPage.save")}
        </BaseButton>
      </div>
    </div>
  );
};

export default NewCustomerModal;
