// TODO implement real distance calc from coordinates
import { IonIcon } from "@ionic/react";
import { navigateOutline } from "ionicons/icons";
import CustomerInfos from "../../../models/CustomerInfos";
import "./OrdersCustomerItem.css";

interface ContainerProps {
  customer: CustomerInfos;
  onSelect: Function;
  isSelected: boolean;
}

const OrdersCustomerItem: React.FC<ContainerProps> = (props) => {
  const getClasses = () => {
    const base = ["orders-customer-item"];
    if (props.isSelected) {
      base.push("orders-customer-item--selected");
    }
    return base.join(" ");
  };

  return (
    <div
      className={getClasses()}
      data-testid="orders-customer-item"
      onClick={() => props.onSelect()}
    >
      <div className="orders-customer-item__name-header">
        {props.customer.firstname + " " + props.customer.lastname}
      </div>
      <div className="orders-customer-item__address-container">
        <div className="orders-customer-item__address-container__infos">
          <span>{props.customer.addressFirstLine}</span>
          <span>
            {props.customer.addressSecondLine
              ? props.customer.addressSecondLine
              : "-"}
          </span>
          <span>
            {props.customer.addressZipcode + " " + props.customer.addressCity}
          </span>
          <span>{props.customer.phone}</span>
        </div>
        <div className="orders-customer-item__address-container__distance">
          <IonIcon icon={navigateOutline} />
          <span>
            {props.customer.distanceToRestaurant
              ? props.customer.distanceToRestaurant.toFixed(2) + " km"
              : "- km"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrdersCustomerItem;
