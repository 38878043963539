import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { useContext } from "react";
import OrdersPageContext from "../../../contexts/orders-page.ctx";
import { OrdersStatus } from "../../../types";
import "./OrdersTabs.css";
import i18n from "../../../translations/i18n";

interface ContainerProps {}

const OrdersTabs: React.FC<ContainerProps> = (props) => {
  const ordersCtx = useContext(OrdersPageContext);

  return (
    <div className="orders-tabs" data-testid="orders-tabs">
      <IonSegment
        mode="ios"
        value={ordersCtx.currentOrdersSegment}
        onIonChange={(e) =>
          ordersCtx.segmentSelectionHandler(e.detail.value as OrdersStatus)
        }
      >
        <IonSegmentButton value={"RECORDED"}>
          <IonLabel>{i18n.t("AddProductProductPage.save")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"READY"}>
          <IonLabel>{i18n.t("AppOrder.ready")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"OUT"}>
          <IonLabel>{i18n.t("AppOrder.onTour")}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  );
};

export default OrdersTabs;
