import { IonIcon } from "@ionic/react";
import { restaurant } from "ionicons/icons";
import "./SettingDoor.css";

interface ContainerProps {
  name: string;
  icon: string;
  color: string;
  action: Function;
}

const SettingDoor: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="setting-door"
      data-testid="setting-door"
      onClick={() => props.action()}
      style={{ backgroundColor: props.color }}
    >
      <div className="setting-door__content">
        <span className="setting-door__content__name">{props.name}</span>
        <span className="setting-door__content__icon">
          <IonIcon icon={props.icon} />
        </span>
      </div>
    </div>
  );
};

export default SettingDoor;
